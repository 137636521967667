import { BasicFrame, HeaderFrame, FotterFrame } from "../components/frame";
import Menu from "../components/menu";
import { MenuWarpper } from "./CenterIntro"
import styled from "styled-components";
import { Mobile, PC } from "../script/responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getBeforeDoc, getNextDoc, setNotice } from "../firebase/noticeFirestore";
import { decrypt, getLocalStorage, timeDifference } from "../script/cipher";
import { ContentState, EditorState, convertFromHTML, convertToRaw } from "draft-js";
import { ContentEditor, getDate } from "./CreateNotice";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftjsToHtml from "draftjs-to-html";

const size = {
    xs: '512px', // for small screen mobile
    sm: '640px', // for mobile screen
    md: '900px', // for tablets
    lg: '1280px', // for laptops
    xl: '1480px', // for desktop / monitors
    xxl: '1920px', // for big screens
  }
  
  const device = {
    xs: `(max-width: ${size.xs})`,
    sm: `(max-width: ${size.sm})`,
    md: `(max-width: ${size.md})`,
    lg: `(max-width: ${size.lg})`,
    xl: `(max-width: ${size.xl})`,
    xxl: `(max-width: ${size.xxl})`,
  }
const BodyFrame = styled.div`
    width:100%;
    height: 86%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    color: black;
    max-width: 1920px;
    align-self: center;
`


const TableWrapper = styled.div`
    width: 90%;
    min-height: 650px;

    margin-left: 4rem;
    margin-right: 4rem;

    @media ${device.sm} {
        min-height: 40vh;
    }
`

const NoticeTable = styled.table`

    width: 100%;
    border-top: 0.05rem solid #000;
    @media ${device.sm} {
    }
`

const ContentTr = styled.tr`
        line-height: 3rem;
        border-bottom: 0.05rem solid #ddd;

        ${
            ({bottom}) =>  bottom && `border-bottom: 0.05rem solid ${bottom};`
        }

        color: black;
        word-break: keep-all;
        font-size: 1em;

        @media ${device.sm} {
            font-size: 4.2rem;
            line-height: 34px;
        }

       
    `

    const ContentTitleTd = styled.td`
        background-color: #E2E2E2;
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 2.6rem;
        padding-left: 0.8rem;
        word-break: break-all;
        
        
        
        @media ${device.sm} {
            font-size: 5.4rem;
            line-height: 12rem;
            padding-left: 0.8rem;
        }


        ${
            ({isAdmin}) => isAdmin && `padding-left: 0rem;`
        }
        
    `

    const ContentAuthorTh = styled.th`
        background-color: #E2E2E2;
        font-size: 1rem;
        line-height: 2.6rem;
        padding-left: 0.6rem;
        word-break: break-all;
        width: 14%;

        @media ${device.sm} {
            min-width: 20rem;
            font-size: 5.4rem;
            line-height: 8rem;
            padding-left: 0.8rem;
        }
    `
    const ContentAuthor = styled.td`
        background-color: white;
        font-weight: 500;
        font-size: 1rem;
        line-height: 2.6rem;
        padding-left: 0.6rem;
        word-break: break-all;

        @media ${device.sm} {
            font-size: 5.4rem;
            line-height: 12rem;
            padding-left: 0.8rem;
        }
    `

    const DetailContentTd = styled.td`
        background-color: white;
        font-weight: 500;
        font-size: 1rem;
        line-height: 2.6rem;
        padding-left: 0.6rem;
        word-break: break-all;
        
        @media ${device.sm} {
            padding-left: 2rem;
        }

        ${
            ({width}) => width && `width: ${width};`
        }

        ${
            ({isAdmin}) => isAdmin && `padding-left: 0rem;`
        }
    `

    const DetailContent = styled.div`
        min-height: 500px;

        @media ${device.sm} {
            font-size: 5.4rem;
            line-height: 8rem;
            width: 100%;
            min-height: 40vh;
        }
    `

    const ToListWrapper = styled.div`
        width: 90%;
        display:flex;
        justify-content: end;
        
        padding-bottom: 1.6rem;
        
        ${
            ({isAdmin}) => isAdmin && `padding-top: 1.6rem;`
        }

        @media ${device.sm} {
            padding-bottom: 4rem;
            padding-top: 4rem;
        }
    `

    const Button = styled.button`
        display: inline-block;
        vertical-align: top;
        font-weight: 500;
        font-size: 0.8rem;
        background-color: #FFFFFF;
        line-height: 30px;
        min-width: 3.4rem;
        height: 2rem;
        padding: 0 22px;
        border: 0.1rem solid #ddd;
        border-radius: 4px;
        text-align: center;
        cursor: pointer;

        @media ${device.sm} {
            font-size: 5rem;
            height: 12rem;
            border: 0.6rem solid #ddd;
        }

        ${
            ({isAdmin}) => isAdmin && `margin-left: 0.6rem;`
        }
    `

    const NextDetailWrapper = styled.div`
        width:90%;
        display:flex;
        flex-direction: column;
        
    `
    const NextDetailWrapperContainer = styled.div`
        width:100%;
        display:flex;

        ${
            ({top}) => top && `border-top: 0.05rem solid #d8d8d8;`
        }

        ${
            ({boldTop}) => boldTop && `border-top: 0.05rem solid #000;`
        }
        
        ${
            ({bottom}) => bottom && `border-bottom: 0.05rem solid #d8d8d8;`
        }

        ${
            ({boldBottom}) => boldBottom && `border-bottom: 0.05rem solid #000;`
        }
        `

    const NextDetailP = styled.p`
        padding: 1.2rem 0.8rem;
        width: 20%;
        font-weight: 500;
        vertical-align: middle;
        text-align: center;

        max-width: 120px;

        @media ${device.sm} {
            font-size: 5.4rem;
            line-height: 8rem;

            padding: 2rem 1.4rem;

        }
    `
        
    const NextDetailTitleP = styled.p`
        padding: 1.2rem 0.8rem;
        width: 100%;
        font-weight: 200;
        line-height: 25px;
        word-wrap: break-word;
        box-sizing: border-box;
        vertical-align: middle;
        position:relative;


        &:after{
            content: "";
            display: block;
            position: absolute;
            top: 21px;
            left: 0;
            width: 1px;
            height: calc(100% - 40px);
            background-color: #6e6e6e;
            
            @media ${device.sm} {
                height: calc(100% - 14px);
                top: 8px;
            }
        }

        @media ${device.sm} {
            font-size: 5.4rem;
            line-height: 8rem;
            padding: 2rem 1.4rem;
        }
    `

const ModalInput = styled.input`
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 1px;
    right: 1px;
    bottom: 0;
    z-index: 1;
    resize: none;
    margin: 10px 0;
    padding: 0 18px 0 12px;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 20px;
    color: #191919;
    border: 0 none;
    outline: none;
    background: transparent;
    box-sizing: border-box;

    @media ${device.sm} {
        font-size: 5rem;
    }
`

const InputLabel = styled.label`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
`

const InputWrapper = styled.div`
    width: 100%;
    height: 3rem;
    position: relative;
    box-sizing: border-box;

    ${
        props => props.width && `width: ${props.width};`
    }

    @media ${device.sm} {
        height: 16rem;
        width: 100%;
    }
`
const NoticeDetail = (props) => {
    const {state} = useLocation()
    const navigate = useNavigate()
    const [nextDetail, setNextDetail] = useState(null)
    const [beforeDetail, setBeforeDetail] = useState(null)
    
    const[isAdmin, setIsAdmin] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [title, setTitle] = useState("")
    const [htmlString, setHtmlString] = useState("");

    useEffect( () => {
        // setIsAdmin(timeDifference(decrypt(getLocalStorage())))
        setIsAdmin(decrypt(getLocalStorage()))
    },[])

    const fetchNextDetail= async () => {
        if(state?.el && state?.noticeList){
            const noticeIndex = state?.el.index
            if(noticeIndex !== 0){
                if(state.noticeList[noticeIndex - 1] && state.noticeList[noticeIndex - 1].isShow !== "false"){
                    setNextDetail(state.noticeList[noticeIndex - 1])
                }
            }

            if(noticeIndex + 1 !== state.noticeList.length){
                if(state.noticeList[noticeIndex + 1] && state.noticeList[noticeIndex + 1].isShow){
                    if(state.noticeList[noticeIndex + 1].isShow !== "false"){
                        setNextDetail(state.noticeList[noticeIndex + 1])
                    }
                }
            }
        }
    }

    useEffect( () => {
        fetchNextDetail()
    }, [])

    useEffect(() => {
        if(isAdmin){
            if(state?.el){
                // const contentState = convertFromHTML(state?.el.contents)
                // setEditorState(contentState)

                setEditorState(
                    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(state?.el.contents)))
                )

                setTitle(state?.el.title ?? "")
            }
        }
    }, [isAdmin])

    const updateTextDescription = async (state) => {
        setEditorState(state);
        const html = draftjsToHtml(convertToRaw(editorState.getCurrentContent()));
        setHtmlString(html);
      };

    return (
        <BasicFrame>
            <PC>
            <HeaderFrame state={"40"} isAdmin={isAdmin + ""}>
                <div style={{"height" : "14%"}}>
                    <Menu />
                </div>
                <BodyFrame>
                {
                    !isAdmin && <>
                        <TableWrapper>
                            <NoticeTable>
                                    <ContentTr >
                                        <ContentTitleTd colSpan={"4"}>{state?.el.title ?? ""}</ContentTitleTd>
                                    </ContentTr>
                                    <ContentTr >
                                        <ContentAuthorTh>작성자</ContentAuthorTh>
                                        <ContentAuthor>{state?.el.writer ?? ""}</ContentAuthor>
                                        <ContentAuthorTh>등록일</ContentAuthorTh>
                                        <ContentAuthor>{state?.date ?? ""}</ContentAuthor>
                                    </ContentTr>
                                    <ContentTr>
                                        <DetailContentTd colSpan={"4"}>
                                            <DetailContent dangerouslySetInnerHTML={{__html: state?.el.contents}}>
                                                {/* {state?.el.contents ?? ""} */}
                                            </DetailContent>
                                        </DetailContentTd>
                                    </ContentTr>
                            </NoticeTable>
                        </TableWrapper>
                        <ToListWrapper>
                                <Button onClick={()=>{
                                    navigate(`/announce`)
                                    window.scrollTo(0, 0)
                                }}>목록</Button>
                        </ToListWrapper>
                        <NextDetailWrapper>
                            <NextDetailWrapperContainer boldTop>
                                    <NextDetailP>이전글</NextDetailP>
                                    <NextDetailTitleP>{beforeDetail === null || beforeDetail.index === "0"  ? "이전글이 없습니다." : beforeDetail.title}</NextDetailTitleP>
                            </NextDetailWrapperContainer>
                            <NextDetailWrapperContainer top boldBottom>
                                    <NextDetailP>다음글</NextDetailP>
                                    <NextDetailTitleP>{nextDetail === null ? "다음글이 없습니다." : nextDetail.title}</NextDetailTitleP>
                            </NextDetailWrapperContainer>
                        </NextDetailWrapper>
                    </>
                }
                 {
                    // 로그인시 수정모드 
                    isAdmin && <>
                        <TableWrapper >
                            <NoticeTable>
                                    <ContentTr>
                                        <ContentTitleTd colSpan={"4"} isAdmin={isAdmin + ""}>
                                            <InputWrapper >
                                                    <ModalInput value={title} onChange={({target}) => {setTitle(target.value)}} id="title_input" type="text" placeholder="공지사항 제목" />
                                                    <InputLabel htmlFor="title_input" />
                                            </InputWrapper>
                                        </ContentTitleTd>
                                    </ContentTr>
                                    <ContentTr>
                                        <ContentAuthorTh>작성자</ContentAuthorTh>
                                        <ContentAuthor>{state?.el.writer ?? ""}</ContentAuthor>
                                        <ContentAuthorTh>등록일</ContentAuthorTh>
                                        <ContentAuthor>{state?.date ?? ""}</ContentAuthor>
                                    </ContentTr>
                                    <ContentTr>
                                        <DetailContentTd colSpan={"4"} isAdmin={isAdmin + ""}>
                                            <ContentEditor
                                                editorState={editorState}
                                                updateTextDescription={updateTextDescription}
                                            />
                                        </DetailContentTd>
                                    </ContentTr>
                            </NoticeTable>
                        </TableWrapper>
                        <ToListWrapper isAdmin={isAdmin+""}>
                                <Button onClick={()=>{
                                    if(title !== "" && state.index){
                                        setNotice(title, htmlString, state.index, state.isShow)
                                        navigate(`/announce`)
                                        window.scrollTo(0, 0)
                                        window.location.reload()
                                    }
                                }}>수정</Button>

                                <Button isAdmin={isAdmin+""} onClick={()=>{
                                    navigate(`/announce`)
                                    window.scrollTo(0, 0)
                                }}>목록</Button>
                        </ToListWrapper>
                    </>
                }
                
                </BodyFrame>
            </HeaderFrame>
            </PC>
            <Mobile>
                <MenuWarpper height={"80"} >
                    <Menu/>
                </MenuWarpper>
                <HeaderFrame vh={"6"}>
                </HeaderFrame>
                <HeaderFrame vh={"110"}>
                    <BodyFrame>
                        <TableWrapper>
                            <NoticeTable>
                                    <ContentTr>
                                        <ContentTitleTd colSpan={"4"}>{state?.el.title ?? ""}</ContentTitleTd>
                                    </ContentTr>
                                    <ContentTr>
                                        <ContentAuthorTh>작성자</ContentAuthorTh>
                                        <ContentAuthor>{state?.el.writer ?? ""}</ContentAuthor>
                                        <ContentAuthorTh>등록일</ContentAuthorTh>
                                        <ContentAuthor>{state?.date ?? ""}</ContentAuthor>
                                    </ContentTr>
                                    <ContentTr>
                                        <DetailContentTd colSpan={"4"}>
                                            <DetailContent dangerouslySetInnerHTML={{__html: state?.el.contents}}>
                                                {/* {state?.el.contents ?? ""} */}
                                            </DetailContent>
                                        </DetailContentTd>
                                    </ContentTr>
                            </NoticeTable>
                        </TableWrapper>
                        <ToListWrapper>
                                <Button onClick={()=>{
                                    navigate(`/announce`)
                                    window.scrollTo(0, 0)
                                }}>목록</Button>
                        </ToListWrapper>
                        <NextDetailWrapper>
                            <NextDetailWrapperContainer boldTop>
                                    <NextDetailP>이전글</NextDetailP>
                                    <NextDetailTitleP>{beforeDetail === null || beforeDetail.index === "0"  ? "이전글이 없습니다." : beforeDetail.title}</NextDetailTitleP>
                            </NextDetailWrapperContainer>
                            <NextDetailWrapperContainer top boldBottom>
                                    <NextDetailP>다음글</NextDetailP>
                                    <NextDetailTitleP>{nextDetail === null ? "다음글이 없습니다." : nextDetail.title}</NextDetailTitleP>
                            </NextDetailWrapperContainer>
                        </NextDetailWrapper>
                    </BodyFrame>
                 </HeaderFrame>
            </Mobile>
            
            <FotterFrame></FotterFrame>
        </BasicFrame>
    )
}


export default NoticeDetail;
import styled from "styled-components";
import {Link as ReactRouterLink, useLocation} from "react-router-dom";
import Logo from "./logo";
import { useEffect, useState } from "react";
import { Mobile, PC } from "../script/responsive";
import MobilLogo from "./mobile_menu_logo";
// import { store } from "../App";
// import { useDispatch, useSelector } from "react-redux";
// import menuCounter, { MENU } from "../reducers/menu_reducer";
const size = {
    xs: '512px', // for small screen mobile
    sm: '640px', // for mobile screen
    md: '900px', // for tablets
    lg: '1280px', // for laptops
    xl: '1480px', // for desktop / monitors
    xxl: '1920px', // for big screens
}
  
const device = {
    xs: `(max-width: ${size.xs})`,
    sm: `(max-width: ${size.sm})`,
    md: `(max-width: ${size.md})`,
    lg: `(max-width: ${size.lg})`,
    xl: `(max-width: ${size.xl})`,
    xxl: `(max-width: ${size.xxl})`,
}

// background: rgb(108, 158, 227, 0.8) 0% 0% no-repeat padding-box;
// background-color: #1C1C1C;

const StyledMenu = styled.div`
    position : absolute;
    display: flex; 
    width: 100%;
    height: 44%;
    z-index: 99;
    background-color: white;

    position : fixed;
    min-height: 80px;
    max-height: 80px;
    border-bottom: 0.1rem #E2E2E2 solid;

    @media ${device.sm} {
        position : fixed;
        min-height: 80px;
        max-height: 80px;
        border-bottom: 0.4rem #E2E2E2 solid;
    }


    ${(props) => {
        if(props.active === "true"){
            // return "position: relative; background: white;";
            return "background: white;";
        } else {
            return "height: 6%";
        }
    }}

`
// opacity: 0.2;

const Tile = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;

    @media ${device.sm} {
        height: 50%;
    }
`

const LinkDiv = styled.div`
    text-align: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    

    ${(props) => {
        if (props.main === "true" && props.active === "false") {
            return "color: #FFFFFF;"
        } else if (props.main === "false" && props.active === "false") {
            return "color: #838383;"
        } else {
            return "color: #000000; border-bottom: solid 0.2rem black;"
        }     
    }}

    @media ${device.sm} {
        border-bottom-width: 0.7rem;
    }
`

const Link = ({children, isActive, ...props}) => {
    return <ReactRouterLink {...props}>{children}</ReactRouterLink> 
}


const StyledLink = styled(Link)`
    text-align: center;
    color: ${(props) => (props.active === "true" ? "#62A1FF" : "#FFFFFF")};
    text-decoration: none;
    padding: 4px 8px;
    flex-direction: column;
    justify-content:center;
    font-weight: ${(props) => (props.active === "true" ? "bold" : "normal")};
    opacity: 1;

    @media ${device.sm} {
        font-size: 4.2rem;
        padding: 2px 4px;
    }

    ${(props) => {
        if (props.main === "true" && props.active === "false") {
            return "color: #000000;"
        } else if (props.main === "false" && props.active === "false") {
            return "color: #838383;"
        } else {
            return "color: #000000;"
        }     
    }}
`
// ${(props) => {
//     if (props.main === "true" && props.active === "false") {
//         return "color: #000000;"

const StyledMenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 30%;
    width: 100%;
    min-height: 80px;
`

const StickyHeader = styled.div`
    position : fixed;
    z-index: 99;
    position : absolute;
    display: flex; 
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
`

const Menu = () => {
    const {pathname} = useLocation()
    const [path, setPath] = useState(pathname);

    // const state = useSelector(s => s.menuCounter);
    // const {menu, subMenu} = state;
    // const dispatch = useDispatch()


    const handleClick = (index) => {
        // dispatch({type: MENU, menu : index})
        if(window){
            window.scrollTo(0,0)
        }
    }
    // console.log(pathname)
    // console.log(pathname.includes("/center"))
    useEffect(() => {
        setPath(pathname);
    }, [pathname])
     
    return (
        <>
        <PC>
            <StyledMenu active={(pathname !== "/") + ""}>
                <Tile>
                    <Logo  main= {(path === "/")+ ""} >
                        로고
                    </Logo>

                    <LinkDiv onClick={() => {handleClick(0)}} active={pathname.includes("/center") + ""} main= {(path === "/")+ ""}>
                        <StyledLink to="/center" active={pathname.includes("/center") + ""} main= {(path === "/")+ ""}>
                            센터소개
                        </StyledLink>
                    </LinkDiv>
                    <LinkDiv onClick={() => {handleClick(1)}} active={pathname.includes("/service") + ""} main= {(path === "/")+ ""}>
                        <StyledLink to="/service" active={pathname.includes("/service") + ""} main= {(path === "/")+ ""}>
                            서비스안내
                        </StyledLink>
                    </LinkDiv>
                    <LinkDiv onClick={() => {handleClick(2)}} active={pathname.includes("/use") + ""} main= {(path === "/")+ ""}>
                        <StyledLink to="/use" active={pathname.includes("/use") + ""} main= {(path === "/")+ ""}>
                            이용안내
                        </StyledLink>
                    </LinkDiv>
                    <LinkDiv onClick={() => {handleClick(3)}} active={pathname.includes("/announce") + ""} main= {(path === "/")+ ""}>
                        <StyledLink to="/announce" active={pathname.includes("/announce") + ""} main= {(path === "/")+ ""}>
                            공지사항
                        </StyledLink>
                    </LinkDiv>
                </Tile>
            </StyledMenu>
        </PC>
        <Mobile>
             <StyledMenuWrapper active={(pathname === "/") + ""}>
                <StyledMenu active={(pathname !== "/") + ""}>
                    <StickyHeader >
                        <MobilLogo main= {(path === "/")+ ""} >
                        </MobilLogo>
                        <Tile>
                            <LinkDiv onClick={() => {handleClick(0)}}  active={pathname.includes("/center") + ""} main= {(path === "/")+ ""}>
                                <StyledLink to="/center" active={pathname.includes("/center") + ""} main= {(path === "/")+ ""}>
                                    센터소개
                                </StyledLink>
                            </LinkDiv>
                            <LinkDiv  onClick={() => {handleClick(1)}} active={pathname.includes("/service") + ""} main= {(path === "/")+ ""}>
                                <StyledLink to="/service" active={pathname.includes("/service") + ""} main= {(path === "/")+ ""}>
                                    서비스안내
                                </StyledLink>
                            </LinkDiv>
                            <LinkDiv onClick={() => {handleClick(2)}}  active={pathname.includes("/use") + ""} main= {(path === "/")+ ""}>
                                <StyledLink to="/use" active={pathname.includes("/use") + ""} main= {(path === "/")+ ""}>
                                    이용안내
                                </StyledLink>
                            </LinkDiv>
                            <LinkDiv onClick={() => {handleClick(3)}}  active={pathname.includes("/announce") + ""} main= {(path === "/")+ ""}>
                                <StyledLink to="/announce" active={pathname.includes("/announce") + ""} main= {(path === "/")+ ""}>
                                    공지사항
                                </StyledLink>
                            </LinkDiv>
                        </Tile>
                    </StickyHeader>
                </StyledMenu>
            </StyledMenuWrapper>
        </Mobile>
        
        </>
    )
}

export default Menu;
import styled from "styled-components";
import {WidthBox } from "../pages/CenterIntro"
import { Mobile, PC } from "../script/responsive";


 const size = {
    xs: '512px', // for small screen mobile
    sm: '640px', // for mobile screen
    md: '900px', // for tablets
    lg: '1280px', // for laptops
    xl: '1480px', // for desktop / monitors
    xxl: '1920px', // for big screens
  }
  
  const device = {
    xs: `(max-width: ${size.xs})`,
    sm: `(max-width: ${size.sm})`,
    md: `(max-width: ${size.md})`,
    lg: `(max-width: ${size.lg})`,
    xl: `(max-width: ${size.xl})`,
    xxl: `(max-width: ${size.xxl})`,
  }
  
const Left = styled.div`
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media ${device.xl}  {
        width: 30%;
    }
    
    @media ${device.lg} {
        width: 30%;
    }

  
`


const UseRight = styled.div`
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;

    justify-content: space-around;
    align-items: center;
`

const HrBar = styled.hr`
    width: 100%;
    height: 1px;
    background-color: #6C9EE3;
    border: none;
`

const SubListElement = styled.p`
    text-align: left;
    font-weight: bold;
    margin-bottom: 1rem;
    cursor: pointer;    
    position: relative;
    ${
        props => 
        props.active === "true" && "margin-left: 1.4rem;"
    }
    ${
        props => 
        props.active === "true" && props.index === "0" && "margin-left: 2.6rem;"
    }
`

const RightP = styled.p`
    color: #6C9EE3;
    font-weight: bold;
    font-size: 1.8rem;
`

const NumberCircleWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position:relative;
`

const NumberCircle = styled.div`
    position:absolute;
    width: 2rem;
    height:2rem;
    background-color : #6C9EE3;
    border-radius: 50%;

    right: -3.8rem;
    top: -0.5rem;

    @media ${device.sm} {
        width: 10rem;
        height: 10rem;

        right: -23rem;
        top: -2.5rem;
    }
`

const NumberSpan = styled.span`
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #FFF;
    font-weight: 700;

    @media ${device.sm} {
        font-size: 6rem;
    }

`

export const CircleDivWrapper = (props) => {
    const CircleSvg = styled.div`
        width: 50%;
        height: 50%;
        ${
            props => props.svg && `background: transparent url(${props.svg}) 50% 50% no-repeat padding-box;`
        }
        background-size: cover;
    `
    return <>
      <PC>
        <CircleDiv padding={"0.25rem 0.25rem"} gradiant={" "} border={"double transparent"}>
                <NumberCircleWrapper>
                    <NumberCircle>
                        <NumberSpan>
                            {props.number}
                        </NumberSpan>
                    </NumberCircle>
                </NumberCircleWrapper>
                <CircleDiv>
                    <CircleSvg svg={props.svg} ></CircleSvg>
                </CircleDiv>
            </CircleDiv>
      </PC>
      <Mobile>
      <CircleDiv padding={"2rem 2rem"} gradiant={" "} border={"double transparent"}>
            <NumberCircleWrapper>
                <NumberCircle>
                    <NumberSpan>
                        {props.number}
                    </NumberSpan>
                </NumberCircle>
            </NumberCircleWrapper>
            <CircleDiv>
                <CircleSvg svg={props.svg} ></CircleSvg>
            </CircleDiv>
        </CircleDiv>
      </Mobile>
     
    </>
}
// 진함 rgb(108, 158, 227)
// rgb(239, 249, 254)
const CircleDiv = styled.div`
    width: 7.6rem;
    height: 7.6rem;
    border-radius: 50%;
    display:flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
    box-shadow: 0 8px 14px rgba(0,0,0,0.19), 0 2px 4px rgba(0,0,0,0.20);
    ${
        props => props.svg && `background: transparent url(${props.svg}) 50% 50% no-repeat padding-box;`
    }
    ${
        props => 
        props.width && `width: ${props.width}; height: ${props.width};`
    }

    ${
        props => 
        props.right && `right: ${props.right};`
    }
    ${
        ({padding}) => padding && `padding: ${padding};`
    }
    ${
        ({gradiant}) => gradiant && `background: radial-gradient(rgb(239, 249, 254), rgb(108, 158, 227));`
    }
    ${
        ({gradiant}) => !gradiant && `background: #FFFFFF;`
    }
    ${
        ({border}) => border && `border: ${border};`
    }

    @media ${device.sm} {
        width: 44rem;
        height: 44rem;
        border: solid 0.6rem #6C9EE3;
    }
`

const RightWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    ${
        props => props.column && "flex-direction: column;"
    }
`
const RightPWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 2rem 0 1.4rem 0;
    ${
        props => 
        props.hasMinHeight && "min-height: 2rem;"
    }

    ${
        props => props.direction && "flex-direction: column; align-items: center;"
    }
`
const RightWrapper01 = styled.div`

`
const RightWrapper02 = styled.div`

`

const HrP = styled.p`
    align-self: end;

    ${
        props => props.color && `color: ${props.color};`
    }
`
const subList = [
    "서비스 이용절차",
    "방문간호 이용요금",
    "방문요양 이용요금"
]


const CostContentMin = styled.div`
    width: 100%;
    min-height: 800px;
    display: flex;
    flex-direction: column;

    justify-content: space-around;
    align-items: center;
`

const TitleWrapper = styled.div`

`

export const MonthlyNurseCharge01 = () => {
    const ServiceCostWrapper = styled.div`
        display:flex;
        justify-content:center;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
    `
    const Table = styled.div`
        display: flex;
        justify-content:center;
        flex-direction: column;
        width: 100%;                    

    `
        
    const Row = styled.div`
        height: 100%;
        display: flex;
        width: 100%;                    
    `
    const Cell = styled.div`
        min-width: 5.6rem;
        padding: 0.8rem 0.4rem;
        word-break: keep-all;
        word-wrap: break-word;
        text-align: center;
        display: flex;
        justify-content:center;
        align-items: center;

        width: 100%;                    
                    
        ${
            props => props.width && `min-width: ${props.width}rem;`
        }

        ${
            props => props.top && "border-top: 0.1rem solid #E2E2E2;"
        }
        ${
            props => props.bottom && "border-bottom: 0.1rem solid #E2E2E2;"
        }
        ${
            props => props.left && "border-left: 0.1rem solid #E2E2E2;"
        }
        ${
            props => props.right && "border-right: 0.1rem solid #E2E2E2;"
        }
        ${
            props => props.back && "background-color: #f5f5f5;"
        }
        ${
            props => props.weight && "font-weight: 700;"
        }
    
    @media ${device.sm} {
        font-size: 4.4rem;
        min-height: 10rem;
        ${
            props => props.width && `min-width: ${props.width}rem;`
        }

        ${
            props => props.top && "border-top: 0.3rem solid #E2E2E2;"
        }
        ${
            props => props.bottom && "border-bottom: 0.3rem solid #E2E2E2;"
        }
        ${
            props => props.left && "border-left: 0.3rem solid #E2E2E2;"
        }
        ${
            props => props.right && "border-right: 0.3rem solid #E2E2E2;"
        }
    
    }
    `   


        // 6C9EE3
    return <>
        <PC>
            <ServiceCostWrapper>
            <Table>
                <Row>
                    <Cell width={"5.5"} back top left bottom weight>
                        등급
                    </Cell >
                    <Cell top left bottom>
                        1등급
                    </Cell>
                    <Cell top left bottom>
                        2등급
                    </Cell>
                    <Cell top left bottom>
                        3등급
                    </Cell>
                    <Cell top left bottom>
                        4등급
                    </Cell>
                    <Cell top left bottom>
                        5등급
                    </Cell>
                    <Cell top left bottom right width={"7"}>
                        인지지원<br/>(주간보호)
                    </Cell>
                </Row>
                <Row>
                    <Cell width={"5.5"} weight back left bottom >
                        한도액
                    </Cell>
                    <Cell left bottom >
                        1,885,000 원
                    </Cell>
                    <Cell left bottom >
                        1,690,000 원
                    </Cell>
                    <Cell left bottom >
                        1,417,000 원
                    </Cell>
                    <Cell left bottom >
                        1,306,200 원
                    </Cell>
                    <Cell left bottom >
                        1,121,100 원
                    </Cell>
                    <Cell left bottom right width={"7"}>
                        624,600 원
                    </Cell>
                </Row>
            </Table>
            </ServiceCostWrapper>
        </PC>
        <Mobile>
            <ServiceCostWrapper>
            <Table>
                <Row>
                    <Cell width={"5.5"} back top left  weight>
                        등급
                    </Cell >
                    <Cell width={"5.5"} top weight back left bottom >
                        한도액
                    </Cell>
                </Row>
                <Row>
                    <Cell top left right >
                        1등급
                    </Cell>
                    <Cell right bottom >
                        1,885,000 원
                    </Cell>
                </Row>
                <Row>
                <Cell  top left right bottom>
                        2등급
                    </Cell>
                    
                    <Cell right bottom >
                        1,690,000 원
                    </Cell>
                </Row>
                <Row>
               
                    <Cell  left right bottom>
                        3등급
                    </Cell>
                    
                    <Cell right bottom >
                        1,417,000 원
                    </Cell>
                </Row>
                <Row>
                    <Cell  left right bottom>
                        4등급
                    </Cell>
                    
                    <Cell  right bottom >
                        1,306,200 원
                    </Cell>
                </Row>
                <Row>
                    <Cell  left right bottom>
                        5등급
                    </Cell>
                    <Cell right bottom >
                        1,121,100 원
                    </Cell>
                </Row>
                <Row>
                    <Cell  left bottom right >
                        인지지원<br/>(주간보호)
                    </Cell>
                    <Cell right bottom  >
                        624,600 원
                    </Cell>
                </Row>
            </Table>
            </ServiceCostWrapper>
        </Mobile>
    </>
}


export const MonthlyNurseCharge02 = () => {
    const ServiceCostWrapper = styled.div`
        display:flex;
        justify-content:center;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
    `
    const Table = styled.div`
        display: flex;
        justify-content:center;
        flex-direction: column;
        width:100%;
    `
        
    const Row = styled.div`
        height: 100%;
        display: flex;
        width:100%;
    `
    const Cell = styled.div`
        min-width: 8rem;
        padding: 0.8rem 0.4rem;
        word-break: keep-all;
        word-wrap: break-word;
        text-align: center;
        display: flex;
        justify-content:center;
        align-items: center;
        width:100%;
                    
        ${
            props => props.width && `min-width: ${props.width}rem;`
        }

        ${
            props => props.top && "border-top: 0.1rem solid #E2E2E2;"
        }
        ${
            props => props.bottom && "border-bottom: 0.1rem solid #E2E2E2;"
        }
        ${
            props => props.left && "border-left: 0.1rem solid #E2E2E2;"
        }
        ${
            props => props.right && "border-right: 0.1rem solid #E2E2E2;"
        }
        ${
            props => props.back && "background-color: #f5f5f5; color: black; padding: 1.2rem 0.4rem;"
        }
        ${
            props => props.weight && "font-weight: 700;"
        }

        @media ${device.sm} {
            font-size: 4rem;
            min-height: 10rem;
            ${
                props => props.width && `min-width: ${props.width}rem;`
            }
    
            ${
                props => props.top && "border-top: 0.3rem solid #E2E2E2;"
            }
            ${
                props => props.bottom && "border-bottom: 0.3rem solid #E2E2E2;"
            }
            ${
                props => props.left && "border-left: 0.3rem solid #E2E2E2;"
            }
            ${
                props => props.right && "border-right: 0.3rem solid #E2E2E2;"
            }
        
        }
    `
        // 6C9EE3
    return <>
        <ServiceCostWrapper>
        <Table>
            <Row>
                <Cell back top left bottom weight>
                    방문시간당
                </Cell >
                <Cell back top left bottom weight>
                    급여비용
                </Cell>
                <Cell back top left bottom weight>
                    본인부담금
                </Cell>
                <Cell back top left bottom weight>
                    감경(9%)
                </Cell>
                <Cell back top left bottom weight right>
                    감경(6%)
                </Cell>
            </Row>
            <Row>
                <Cell left bottom >
                    30분 미만
                </Cell>
                <Cell left bottom >
                    40,760 원
                </Cell>
                <Cell left bottom >
                    6,114 원
                </Cell>
                <Cell left bottom >
                    3,668 원
                </Cell>
                <Cell left bottom right >
                    2,446 원
                </Cell>
            </Row>
            <Row>
                <Cell left bottom >
                    30분 이상 ~ <br/>60분 미만
                </Cell>
                <Cell left bottom >
                    51,110 원
                </Cell>
                <Cell left bottom >
                    7,667 원
                </Cell>
                <Cell left bottom >
                    4,600 원
                </Cell>
                <Cell left bottom right >
                    3,067 원
                </Cell>
            </Row>

            <Row>
                <Cell left bottom >
                    60분 이상
                </Cell>
                <Cell left bottom >
                    61,490 원
                </Cell>
                <Cell left bottom >
                    9,224 원
                </Cell>
                <Cell left bottom >
                    5,534 원
                </Cell>
                <Cell left bottom right >
                    3,689 원
                </Cell>
            </Row>
        </Table>
        </ServiceCostWrapper>
    </>
}


export const MonthlyNurseCharge03 = () => {
    const ServiceCostWrapper = styled.div`
        display:flex;
        justify-content:center;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
    `
    const Table = styled.div`
        display: flex;
        justify-content:center;
        flex-direction: column;
        width: 100%;                    

    `
        
    const Row = styled.div`
        height: 100%;
        display: flex;
        width: 100%;                    

    `
    const Cell = styled.div`
        min-width: 7rem;
        padding: 0.8rem 0.4rem;
        word-break: keep-all;
        word-wrap: break-word;
        text-align: center;
        display: flex;
        justify-content:center;
        align-items: center;

        width: 100%;                    
        ${
            props => props.width && `min-width: ${props.width}rem;`
        }

        ${
            props => props.top && "border-top: 0.1rem solid #E2E2E2;"
        }
        ${
            props => props.bottom && "border-bottom: 0.1rem solid #E2E2E2;"
        }
        ${
            props => props.left && "border-left: 0.1rem solid #E2E2E2;"
        }
        ${
            props => props.right && "border-right: 0.1rem solid #E2E2E2;"
        }
        ${
            props => props.back && "background-color: #f5f5f5; padding: 1.2rem 0.4rem;"
        }
        ${
            props => props.weight && "font-weight: 700;"
        }

        @media ${device.sm} {
            font-size: 4rem;
            min-height: 10rem;
            ${
                props => props.title && `font-size: 3.6rem;`
            }
    
            ${
                props => props.width && `min-width: ${props.width}rem;`
            }
    
            ${
                props => props.top && "border-top: 0.3rem solid #E2E2E2;"
            }
            ${
                props => props.bottom && "border-bottom: 0.3rem solid #E2E2E2;"
            }
            ${
                props => props.left && "border-left: 0.3rem solid #E2E2E2;"
            }
            ${
                props => props.right && "border-right: 0.3rem solid #E2E2E2;"
            }
        
        }
    `
        // 6C9EE3
    return <>
        <ServiceCostWrapper>
        <Table>
            <Row>
                <Cell title back top left bottom weight>
                    방문시간당
                </Cell >
                <Cell title back top left bottom weight>
                    급여비용
                </Cell>
                {/* <Cell title back top left bottom weight>
                    공단부담금
                </Cell> */}
                <Cell title back top left bottom weight>
                    본인부담금
                </Cell>
                <Cell title back top left bottom weight>
                    감경(9%)
                </Cell>
                <Cell title back top left bottom weight right>
                    감경(6%)
                </Cell>
            </Row>
            <Row>
                <Cell left bottom >
                    30분
                </Cell>
                <Cell left bottom >
                    16,630 원
                </Cell>
                {/* <Cell left bottom >
                    13,761 원
                </Cell> */}
                <Cell left bottom >
                    2,495 원
                </Cell>
                <Cell left bottom >
                    1,497 원
                </Cell>
                <Cell left bottom right >
                    998 원
                </Cell>
            </Row>
            <Row>
                <Cell left bottom >
                    60분
                </Cell>
                <Cell left bottom >
                    24,120 원
                </Cell>
                {/* <Cell left bottom >
                    19,958 원
                </Cell> */}
                <Cell left bottom >
                    3,618 원
                </Cell>
                <Cell left bottom >
                    2,171 원
                </Cell>
                <Cell left bottom right >
                    1,447 원
                </Cell>
            </Row>
            <Row>
                <Cell left bottom >
                    90분
                </Cell>
                <Cell left bottom >
                    32,510 원
                </Cell>
                {/* <Cell left bottom >
                    26,902 원
                </Cell> */}
                <Cell left bottom >
                    4,877 원
                </Cell>
                <Cell left bottom >
                    2,926 원
                </Cell>
                <Cell left bottom right >
                    1,951 원
                </Cell>
            </Row>
            <Row>
                <Cell left bottom >
                    120분
                </Cell>
                <Cell left bottom >
                    41,380 원
                </Cell>
                {/* <Cell left bottom >
                    34,238 원
                </Cell> */}
                <Cell left bottom >
                    6,207 원
                </Cell>
                <Cell left bottom >
                    3,724 원
                </Cell>
                <Cell left bottom right >
                    2,483 원
                </Cell>
            </Row>
            <Row>
                <Cell left bottom >
                    150분
                </Cell>
                <Cell left bottom >
                    46,970 원
                </Cell>
                {/* <Cell left bottom >
                    39,924 원
                </Cell> */}
                <Cell left bottom >
                    7,046 원
                </Cell>
                <Cell left bottom >
                    4,227 원
                </Cell>
                <Cell left bottom right >
                    2,818 원
                </Cell>
            </Row>
            <Row>
                <Cell left bottom >
                    180분
                </Cell>
                <Cell left bottom >
                    54,320 원
                </Cell>
                {/* <Cell left bottom >
                    44,948 원
                </Cell> */}
                <Cell left bottom >
                    8,148 원
                </Cell>
                <Cell left bottom >
                    4,889 원
                </Cell>
                <Cell left bottom right >
                    3,259 원
                </Cell>
            </Row>
            <Row>
                <Cell left bottom >
                    210분
                </Cell>
                <Cell left bottom >
                    60,530 원
                </Cell>
                {/* <Cell left bottom >
                    50,090 원
                </Cell> */}
                <Cell left bottom >
                    9,080 원
                </Cell>
                <Cell left bottom >
                    5,448 원
                </Cell>
                <Cell left bottom right >
                    3,632 원
                </Cell>
            </Row>
            <Row>
                <Cell left bottom >
                    240분
                </Cell>
                <Cell left bottom >
                    66,770 원
                </Cell>
                {/* <Cell left bottom >
                    55,250 원
                </Cell> */}
                <Cell left bottom >
                    10,016 원
                </Cell>
                <Cell left bottom >
                    6,009 원
                </Cell>
                <Cell left bottom right >
                    4,006 원
                </Cell>
            </Row>
          
        </Table>
        </ServiceCostWrapper>
    </>
}


const Cost = (props) => {

    return <WidthBox width={"100%"}>
        <Left>
            {
                subList.map((el, index) => ( 
                    <div>
                        <SubListElement active={(props.subListState === index) + ""}
                            index={index +""}
                            onClick={ () => props.setSubListState(index)}
                        >
                            {el}
                        </SubListElement>
                        {/* <DotDiv  index={index +""} active={(props.subListState === index) + ""} /> */}
                    </div>
                ))
            }
          
        </Left>
            {/* {
                props.subListState === 0 && <UseRight> 
                        <HrP color={"#FFFFFF"}>|</HrP>
                        <HrBar></HrBar> 
                        <CostContentMin>
                            <TitleWrapper>
                                <StyledP
                                    hexColor={"#6C9EE3"}
                                    weight={"700"}
                                    size={"3.2"}
                                >방문간호&요양 서비스이용 절차</StyledP>
                            </TitleWrapper>
                        <Column>
                            <CircleWrapper>
                                <CircleDivWrapper svg={mask01} />
                                <p>장기요양등급 판정</p>
                            </CircleWrapper>
                            <Arrow></Arrow>
                            <CircleWrapper>
                                <CircleDivWrapper svg={mask04} />
                                <p>센터 서비스 신청</p>
                            </CircleWrapper>
                            <Arrow></Arrow>
                            <CircleWrapper>
                                <CircleDivWrapper svg={mask03} />
                                <p>계약서 작성</p>
                            </CircleWrapper>
                            <Arrow></Arrow>
                            <CircleWrapper>
                                <CircleDivWrapper svg={mask02} />
                                <p>서비스 실시</p>
                            </CircleWrapper>
                        </Column>
                        <P02>*장기요양등급판정?</P02>
                        <P03>장기요양인전신청(공단) &gt; 가정방문조사 &gt; 등급판정위원회 &gt; 장기요양인정서 수령</P03>
                        </CostContentMin>
                    </UseRight>   
            } */}
            {
                props.subListState === 1 && <UseRight> 
                        <HrP>*건강보험공단 고시기준</HrP>
                        <HrBar></HrBar> 
                        <CostContentMin>
                        <RightWrapper column>
                            <RightWrapper01>
                                <RightPWrapper hasMinHeight={"true"}>
                                    <RightP>방문간호 월 한도액</RightP>
                                </RightPWrapper>
                                <MonthlyNurseCharge01 />
                            </RightWrapper01>
                            <RightWrapper02>
                                <RightPWrapper hasMinHeight={"true"} direction>
                                    <RightP>2024년 방문간호 이용요금</RightP>
                                    <RightP>(방문인지학습, 관절운동 등)</RightP>
                                </RightPWrapper>
                                  <MonthlyNurseCharge02 />

                            </RightWrapper02>
                        </RightWrapper>
                        </CostContentMin>
                    </UseRight>    
            }
            {
                props.subListState === 2 &&<UseRight> 
                <HrP>*건강보험공단 고시기준</HrP>
                <HrBar></HrBar>
                <CostContentMin>
                <RightWrapper column>
                    <RightWrapper01>
                        <RightPWrapper hasMinHeight={"true"}>
                            <RightP>방문요양 월 한도액</RightP>
                        </RightPWrapper>
                        <MonthlyNurseCharge01 />
                 
                    </RightWrapper01>
                    <RightWrapper02>
                        <RightPWrapper hasMinHeight={"true"} direction>
                                    <RightP>2024년 방문요양 이용요금</RightP>
                                </RightPWrapper>
                        <MonthlyNurseCharge03 />
                    </RightWrapper02>
                </RightWrapper>
                </CostContentMin> 
            </UseRight>  
            }
    </WidthBox>
}

export default Cost
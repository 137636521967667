import { useEffect, useRef, useState } from "react";
import { BasicFrame, HeaderFrame, FotterFrame, StyledP, MobileCommonFrame } from "../components/frame";
import Menu from "../components/menu";
import SubMenu from "../components/subMenu";
import {Column, Spacer, WidthBox, MenuWarpper, MenuBackEmptySpace, TitleSperatorWrapper } from "./CenterIntro"
import styled from "styled-components";
import { CircleDivWrapper, MonthlyNurseCharge01, MonthlyNurseCharge02, MonthlyNurseCharge03 } from "../components/howToUse";
import svg_01  from "../assets/image/svg_01.svg";
import svg_02  from "../assets/image/svg_02.svg";
import { Mobile, PC, isMac, isMobile } from "../script/responsive";
import mask01  from "../assets/image/marsk01.png";
import mask02  from "../assets/image/marsk02.png";
import mask03  from "../assets/image/marsk03.png";
import mask04  from "../assets/image/marsk04.png";
import mask05  from "../assets/image/submit.png";
import mask06  from "../assets/image/home_visit.png";
import mask07  from "../assets/image/committee.png";
import mask08  from "../assets/image/receipt.png";
import JinsilModal from "../components/modal";
import use_img from '../assets/image/cs02_new.jpg'
import cs_img from '../assets/image/cs_04.jpg'
import { decrypt, getLocalStorage } from "../script/cipher";
import delete_svg  from "../assets/image/delete.svg";
import Modal from "react-modal"
import { XSymbolSvg } from "../components/loginModal";
import { dropConsult, getConsult, setConsult } from "../firebase/consultFireStore";
import { useDispatch, useSelector } from "react-redux";
import { appendConsultList } from "../reducers/ConsultReducer";
import { ToastContainer, toast } from "react-toastify";
import service_target_01 from '../assets/image/service_target_01.png'

const size = {
    xs: '512px', // for small screen mobile
    sm: '640px', // for mobile screen
    md: '900px', // for tablets
    lg: '1280px', // for laptops
    xl: '1480px', // for desktop / monitors
    xxl: '1920px', // for big screens
  }
  
  const device = {
    xs: `(max-width: ${size.xs})`,
    sm: `(max-width: ${size.sm})`,
    md: `(max-width: ${size.md})`,
    lg: `(max-width: ${size.lg})`,
    xl: `(max-width: ${size.xl})`,
    xxl: `(max-width: ${size.xxl})`,
  }
  
const subMenuList_v2 = [
    {
        name : "이용방법 및 비용",
        path : "/use/charge",
    },
    {
        name : "서비스 신청",
        path : "/use/call",
    }
]

const BodyFrame = styled.div`
    width:100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    color: black;
    max-width: 1920px;
    align-self: center;

    ${
        props => props.height && `height: ${props.height};`
    }
    ${
        ({justify}) => justify && `justify-content: ${justify};`
    }
`
const Left = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media ${device.sm} {
        width: 100%;
    }
`
const Right = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    @media ${device.sm} {
        width: 100%;
    }
`

const RightP = styled.p`
    color: #6C9EE3;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    
    @media ${device.sm} {
        font-size: 6.2rem;
        margin-bottom: 4rem;
    }
`

const RightWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    ${
        props => props.column && "flex-direction: column;"
    }
    justify-content: space-evenly;
    height: 100%;
    

    ${
        props => props.minHeight && "min-height: 950px;"
    }

    ${
        props => props.minHeight2 && "min-height: 1150px;"
    }
    @media (max-width: 1000px){
        align-items: start;
    }
    @media ${device.sm} {
        align-items: center;
        ${
            props => props.minHeight && "min-height: 500px;"
        }
    
        ${
            props => props.minHeight2 && "min-height: 650px;"
        }
    }
`
const RightPWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 2rem 0 0.4rem 0;
    ${
        props => 
        props.hasMinHeight && "min-height: 2rem; justify-content:start;"
    }

    ${
        props => props.direction && "flex-direction: column; align-items: flex-start;"
    }
`
const RightWrapper01 = styled.div`
    justify-content:center;
    align-items: center;
    flex-direction: column;
    width: 50%;
    ${
        ({max}) => max && `width: 80%;`
    }

    ${
        ({isBackground}) => isBackground && `height: 100%;`
    }
    @media ${device.sm} {
        width: 100%;
    }
`
const RightWrapper02 = styled.div`
    width: 50%;
    ${
        ({max}) => max && `width: 80%; display:flex; flex-direction: column;`
    }
    @media ${device.sm} {
        width: 100%;
        display:flex;
        justify-content:center;
        align-items: center;
        flex-direction: column;
    }
`

const WrapperRow = styled.div`
    display: flex;
    width: 100%;
    margin: 0.5rem 0 0.5rem 0;
    justify-content:center;
    align-items: center;
    > * {
        &:nth-child(1){
            font-weight: bold;
            font-size: 1.2rem;
            ${
                props  =>  props.min && `min-width: 6rem;`
            }
            text-align:  start;
            @media ${device.sm} {
                font-size: 5rem;
                ${
                    props  =>  props.min && `min-width: 20rem;`
                }
            }
        }
        &:nth-child(2){
            min-width: 50%;
            text-align:  start;
            @media ${device.sm} {
                font-size: 4.6rem;
                min-width: 80%;
                text-align: center;
                margin: 0.5rem 0 0.5rem 0;
            }
        }
        
    }

    @media ${device.sm} {
        justify-content: center;
        align-items: center;
    }
`

const CallBox = styled.div`
    width: 50%;
    height: 18%;
    border: 1px solid black;
    margin-bottom: 1rem;
    word-break: keep-all;
    min-height: 120px;

    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor:pointer;

    @media ${device.sm} {
        font-size: 4.6rem;
        margin-bottom: 4rem;
        min-height: 100px;
        width: 80%;
    }

`

const KakaoBox = styled.div`
    width: 50%;
    height: 18%;
    border: 1px solid black;
    margin-bottom: 1rem;
    min-height: 120px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor:pointer;

    @media ${device.sm} {
        font-size: 4.6rem;
        margin-bottom: 4rem;
        min-height: 100px;
        width: 80%;
    }
`

const BackGroundSvg01 = styled.div`
    height: 100%;
    background: transparent url(${svg_01}) 50% 50% no-repeat padding-box;
    background-size: contain;
    min-width: 2.2rem;
    min-height: 2.2rem;

    @media ${device.sm} {
        min-width: 14rem;
        min-height: 14rem;
    }
`

const BackGroundSvg02 = styled.div`
    height: 100%;
    background: transparent url(${svg_02}) 50% 50% no-repeat padding-box;
    background-size: contain;
    min-width: 2.2rem;
    min-height: 2.2rem;
    
    @media ${device.sm} {
        min-width: 14rem;
        min-height: 14rem;
    }
`

const SvgWrapper = styled.div`
    width: 2rem;
    min-width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    ${
        ({width}) => width && `width: ${width};`
    }
`
const BottomRight = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;

    align-items: center;
`

const MobileColumn = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

`
const MobileVisit01ImageWrapper = styled.div`
    width: 100%;
    height: 100%;

    position: relative;
`

const BackGroundImage06 = styled.div`
    height: 24rem;
    width: 24rem;

    ${
        ({img}) => img && `background: transparent url(${img}) 50% 50% no-repeat padding-box;`
    }

    background-size: cover;
    z-index: 4;
    border-radius: 4% 4%;

    @media ${device.sm} {
        height: 100%;
        width: 100%;
        left: 0%;
        top: 4%;
        background-position: 50% 50%;
        border-radius: 2% 2%;
        position: static;
    }
`

const CallService = () => {
    const [isOpen, toggle] = useState(false)
    const [userType, setUserType] = useState("이용자")
    const openModal = () => {
        toggle(!isOpen)
        document.body.style = 'overflow: hidden;'
    }

    const protectToast = () => toast.info("보안상의 이유로 프로그램을 실행할 수 없습니다.", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    })
    
    const launchKakaoTalkApp = () => {
        if(isMobile()){ 
            // 모바일
            if(isMac()){
                window.open("http://pf.kakao.com/_PCKHxj", "_blank")
            } else {
                window.open("http://pf.kakao.com/_PCKHxj", "_blank")
            } 

        } else {
            // pc
            if(isMac()){
                window.open("http://pf.kakao.com/_PCKHxj", "_blank")
                // protectToast()
            } else {
                // protectToast()
                window.open("http://pf.kakao.com/_PCKHxj", "_blank")
            }
        }        
    }

    return <>
        <WidthBox justify={"center"} width={"100%"}>
        <PC>
            <Left>
                <RightP>이용자</RightP>
                
                <CallBox onClick={ () => 
                    {
                        setUserType("이용자")
                        openModal()
                    }
                }>
                    <SvgWrapper>
                        <BackGroundSvg01></BackGroundSvg01>
                    </SvgWrapper>
                    <p>상담신청하고 연락받기</p>
                </CallBox>
                <KakaoBox onClick={() => {
                    launchKakaoTalkApp()
                }}>
                    <SvgWrapper>
                        <BackGroundSvg02 ></BackGroundSvg02>
                    </SvgWrapper>
                    <p>카카오톡으로 문의하기</p>
                </KakaoBox>
            </Left>
            <Right>
                <RightP>구직자</RightP>
                <CallBox onClick={ () => 
                    {
                        setUserType("구직자")
                        openModal()
                    }
                }>
                    <SvgWrapper>
                        <BackGroundSvg01></BackGroundSvg01>
                    </SvgWrapper>
                    <p>상담신청하고 연락받기</p>
                </CallBox>
                <KakaoBox onClick={() => {
                    launchKakaoTalkApp()
                }}> 
                    <SvgWrapper>
                        <BackGroundSvg02></BackGroundSvg02>
                    </SvgWrapper>
                    <p>카카오톡으로 문의하기</p>
                </KakaoBox>
            </Right>
         </PC>
         <Mobile>
            <MobileCommonFrame vh={"30"} align={" "}>
                <MobileVisit01ImageWrapper>
                    <BackGroundImage06 img={cs_img}></BackGroundImage06>
                </MobileVisit01ImageWrapper>
            </MobileCommonFrame>
            <MobileCommonFrame vh={"6"} align={" "}>
            </MobileCommonFrame>
            <MobileColumn>
               
                <NormalWrapper>
                    <StyledP
                        size={"6"}
                        hexColor={"#6C9EE3"}
                    >
                        진실서비스가 필요하신가요?
                    </StyledP>
                    <StyledP
                        size={"6"}
                        hexColor={"#6C9EE3"}
                        margin={"4rem 0 0 0"}
                    >
                        상담을 신청하시면 진실에서 확인 후
                    </StyledP>
                    <StyledP
                        size={"6"}
                        hexColor={"#6C9EE3"}
                        margin={"4rem 0 0 0"}

                    >
                         즉시 연락드립니다.
                    </StyledP>
                </NormalWrapper>
                <Left>
                <Spacer></Spacer>
                        <RightP>이용자</RightP>
                        <CallBox onClick={ () => openModal()}>
                            <SvgWrapper>
                                <BackGroundSvg01></BackGroundSvg01>
                            </SvgWrapper>
                            <p>상담신청하고 연락받기</p>
                        </CallBox>
                        <KakaoBox onClick={() => {
                            launchKakaoTalkApp()
                        }}>
                            <SvgWrapper>
                                <BackGroundSvg02></BackGroundSvg02>
                            </SvgWrapper>
                            <p>카카오톡으로 문의하기</p>
                        </KakaoBox>
                    </Left>
                    <Right>
                        <RightP>구직자</RightP>
                        <CallBox onClick={ () => openModal()}>
                            <SvgWrapper>
                                <BackGroundSvg01></BackGroundSvg01>
                            </SvgWrapper>
                            <p>상담신청하고 연락받기</p>
                        </CallBox>
                        <KakaoBox onClick={() => {
                            launchKakaoTalkApp()
                        }}>
                            <SvgWrapper>
                                <BackGroundSvg02></BackGroundSvg02>
                            </SvgWrapper>
                            <p>카카오톡으로 문의하기</p>
                        </KakaoBox>
                    </Right>
                </MobileColumn>
            </Mobile>
            <JinsilModal open={isOpen} toggle={toggle} userType={userType}/>
            {/* <ToastContainer style={{fontSize : "14px", width : "440px"}}/> */}
        </WidthBox>
    </>

}

const CircleWrapper = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: black;
    > * {
        &:nth-child(2){
            margin-top: 0.6rem;

            @media ${device.sm} {
                margin-top: 4rem;
            }
        }

    }
`


const Arrow = styled.div`
    width: 20px;
    height: 20px;
    border-top: solid 0.3rem #6C9EE3;
    border-right: solid 0.3rem #6C9EE3;
    transform: rotate(45deg);
    margin-left: 0.5rem;
    margin-right: 0.7rem;

    @media ${device.sm} {
        border-top: solid 2rem #6C9EE3;
        border-right: solid 2rem #6C9EE3;
        min-height: 6rem;
        min-width: 6rem;
        margin-bottom: 8rem;
        transform: rotate(45deg);
        margin-right: 4rem;
    }
`

const NormalWrapper = styled.div`
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    text-align: center;
`
const CircleContainer = styled.div`
    display: flex;
    justify-content:center;
    align-items: center;
    text-align: center;
    width:100%;
    height: 100%
`

const MobileColumnContainer = styled.div`
    display: flex;
    justify-content:center;
    align-items: center;
    text-align: center;
    width:80%;
    height: 100%;
    flex-direction: column;
`

const NomalContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const ServiceTarget = styled.div`
    display: flex;
    width: 100%;
    margin: 0.5rem 0 0.5rem 0;
    justify-content: start;
    align-items: center;
    min-height: 1.6rem;
    > * {
        &:nth-child(2){
            font-weight: bold;
            font-size: 1.4rem;
            ${
                props  =>  props.min && `min-width: 5rem;`
            }
            text-align:  center;
            @media ${device.sm} {
                font-size: 6rem;
                margin: 2rem 0 2rem 0;
            }
        }
        &:nth-child(3){
            margin-left: 0.4rem;
            min-width: 18rem;
            text-align:  start;
            @media ${device.sm} {
                font-size: 4.6rem;
                min-width: 80%;
                text-align: start;
                align-items:end;
            }
        }
    }
    @media ${device.sm} {
        justify-content:flex-start;
    }

    ${
        props  =>  props.last && `color: #838383;`
    }
`



const VhContainer = styled.div`
    width: 100%;
    min-height: 500px;
`

const PcBackGroundImage06 = styled.div`
    height: 100%;
    width: 100%;
    ${
        ({img}) => img && `background: transparent url(${img}) 50% 50% no-repeat padding-box;`
    }

    position: static;
    background-size: contain;
    z-index: 4;

    @media ${device.sm} {
        height: 100%;
        width: 100%;
        left: 0%;
        top: 4%;
        background-position: 50% 50%;
        border-radius: 2% 2%;
    }
`


const UseRight = styled.div`
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 800px;
    max-height: 1100px;
    justify-content: space-around;
    align-items: center;

`


const CostContentMin = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;

    ${
        ({start}) => start && "justify-content: space-between;"
    }
`

const P02 = styled.p`
    margin-bottom: 1rem;
    font-size: 1.1rem;
    color: #838383;
`

const TableWrapper = styled.div`
    width: 90%;
    min-height: 500px;

    margin-left: 4rem;
    margin-right: 4rem;

    @media ${device.sm} {
        height: 100%;
        min-height: 450px;
    }
 
    @media ${device.xxl} {
        min-height: 500px;
    }

    @media ${device.xl} {
        min-height: 400px;
    }
    
    @media ${device.lg} {
        min-height: 300px;
    }
`

const NoticeTable = styled.table`
    width: 100%;
    table-layout: fixed;
`

const NoticeHead = styled.thead`
    border-top: 1px solid black;
    background-color: #E2E2E2;
    border-bottom: 0.05rem solid #ddd;

    @media ${device.sm} {

    }
`

const NoticeBody = styled.tbody`

`

const Td = styled.td`
    color: black;
    word-break: keep-all;
    font-size: 0.8rem;
    text-align: center;
    line-height: 3rem;
    ${
        props => props.width && `width: ${props.width};`
    }

    @media ${device.sm} {
        line-height: 30px;
        font-size: 4.6rem;
    }
`

const ContentCheckBox = styled.input`
    width: 22px;
    height: 22px;
`

const BackGroundDeleteSvg = styled.div`
    height: 100%;
    background: transparent url(${delete_svg}) 50% 50% no-repeat padding-box;
    background-size: contain;
    min-width: 1.2rem;
    min-height: 1.2rem;
    
    cursor: pointer;

    @media ${device.sm} {
        min-width: 14rem;
        min-height: 14rem;
    }
`

const ModalBackgroundContainer = styled.div`
    min-width: 300px;
    height: 260px;

    display: flex;
    flex-diraction: column;
    justify-content: center;
    align-items: center;

    @media ${device.sm} {
        min-width: calc(100vw - 20vw);
        height: 40vh;
    }
`

const ModalContainer = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`

const Top = styled.div`
    word-break: keep-all;
    word-wrap: break-word;
    display: block;
    padding: 1rem 1rem;
    font-weight: 500;
    text-align: left;

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    
    border-bottom: 0.1rem solid black;
    
    @media ${device.sm} {
        padding: 4rem 2rem;
        border-bottom: 0.6rem solid #ddd;
    }
`

const Middle = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`

const Bottom = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
`

const Cancel = styled.button`
    display: inline-block;
    vertical-align: top;
    font-weight: 500;
    font-size: 0.8rem;
    background-color: #FFFFFF;
    line-height: 30px;
    min-width: 3.4rem;
    height: 2rem;
    padding: 0 22px;
    border: 0.1rem solid #ddd;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;

    @media ${device.sm} {
        font-size: 5rem;
        height: 12rem;
        border: 0.6rem solid #ddd;
    }
`

const Submit = styled.button`
    display: inline-block;
    vertical-align: top;
    font-weight: 500;
    font-size: 0.8rem;
    background-color: #fff;
    line-height: 34px;
    min-width: 3.4rem;
    height: 2rem;
    padding: 0 22px;
    border-radius: 4px;
    border: 0.1rem solid #ddd;
    text-align: center;
    margin-left: 0.4rem;
    cursor: pointer;

    @media ${device.sm} {
        font-size: 5rem;
        height: 12rem;
        border: 0.6rem solid #ddd;
        margin-left: 1.4rem;
    }
`


const ConfirmModal = (props) => {
    Modal.setAppElement('#root');
    
    // PC인지 아닌지
    const [isPC, setMediaquery] = useState(window.innerWidth < 641 ? false : true)

    useEffect( ()=> {

        if(window){
            var x = window.matchMedia("(min-width: 641px)")
            x.addEventListener("change", screenChange);
        }

        return () => {
            x.removeEventListener("change", screenChange)
        }
    }, []) 

    const screenChange = (x) => {
        setMediaquery(x.matches)
    }

    const closeModal = () => {
        document.body.style = `overflow: auto;`
        props.toggle(false)
    }
      
    return <>
            <Modal 
                isOpen={props.open} 
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        overflow: 'hidden'
                      },
                      overlay :{
                          zIndex : "999",
                          backgroundColor : "rgba(0,0,0,.5)"
                      }
                }}
                    contentLabel="_modal"
                >
                <ModalBackgroundContainer>
                    <ModalContainer>
                        <Top>
                            <StyledP size={isPC ? "2" : "5.6"} weight={"700"}>
                                확인
                            </StyledP>
                            {/* <StyledP cursor={"pointer"} size={isPC ? "2" : "5.6"} weight={"700"} onClick={() => {
                                closeModal()
                            }}>
                                X
                            </StyledP> */}
                            <XSymbolSvg onClick={() => {
                                closeModal()
                            }}/>
                        </Top>
                        <Middle>
                               {props.index}번 상담내역 삭제할까요?
                        </Middle>
                        <Bottom>
                            <Cancel onClick={ () => {closeModal()}}>취소</Cancel>
                            <Submit onClick={ () => {
                                closeModal()
                                dropConsult(props.index)
                                window.location.reload()
                            }}>삭제</Submit>
                        </Bottom>
                    </ModalContainer>
                </ModalBackgroundContainer>
            </Modal>
    </>
}

const InputWrapper = styled.div`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    box-sizing: border-box;

    position:relative;

    ${
        props => props.width && `width: ${props.width};`
    }
    ${
        props => props.line && `line-height: normal;`
    }
    @media ${device.sm} {
        height: 16rem;
        width: 100%;
    }
`


const Input = styled.input`
    width: 100%;
    padding: 0 12px;
    overflow: hidden;
    resize: none;
    font-size: 1rem;
    line-height: 20px;
    border: 0 none;
    outline: none;
    background: transparent;
    box-sizing: border-box;
    position: inherit;
    @media ${device.sm} {
        font-size: 5rem;
    }

    ${
        ({$index}) => $index && `z-index: ${$index};`
    }
`

const Label = styled.label`
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;

    ${
        ({$index}) => $index && `z-index: ${$index};`
    }
`

const RadioLabel = styled.label`
    left:0;
    right:0;
    top:0;
    bottom:0;

    line-height: 0;

    ${
        ({$index}) => $index && `z-index: ${$index};`
    }
`

const Content = (props) => {
    const [isOpen, toggle] = useState(false)

    const [isCheck, toggleCheckBox] = useState(false)

    const contentRef = useRef(null)
    const [content] = useState(props.contents ? props.contents : "")
    const [canHoverTooltip, toggleHoverTooltip] = useState(false)
    const remarkRef = useRef(null)
    const [remark, setRemark] = useState("1")
    const [remarkDisable, toggleRemarkDisable] = useState(true)


    const consultState = useSelector(state => state.consult)

    // const [radioValue, setRadioValue] = useState("1")

    useEffect( () => {
        setRemark(props.remark === "" ? "1" : props.remark)
        // setRadioValue(props.remark === "" ? "1" : props.remark)
    }, [props.remark])

    useEffect( () => {
        const toggle = (props.isConsult ? props.isConsult === "true" : false)
        toggleCheckBox(toggle)
        // consultState.consultList[+props.el.id].isConsult = toggle
    }, [props.isConsult])

    const openModal = () => {
        toggle(!isOpen)
        document.body.style = 'overflow: hidden;'
    }

    const checkBoxHandler = (target) => {
        toggleCheckBox(target.checked)
        // const toggle = !(props.isConsult ? props.isConsult === "true" : false)
        // console.log(props.isConsult, typeof props.isConsult, toggle)
        // console.log(consultState.consultList[props.mapIndex].isConsult)
        // toggleCheckBox(toggle)
        consultState.consultList[props.mapIndex].isConsult = target.checked + ""
        setConsult(remark, target.checked + "", props.index, content, props.originDate, props.type, props.phoneNum, props.name, props.region)
    }


    useEffect( () => {
        if(contentRef.current){
            if( contentRef.current.scrollWidth >
                contentRef.current.clientWidth){
                toggleHoverTooltip(true)
            }
        }
    }, [])


    // const handleRemarkDisable = () => {
    //     toggleRemarkDisable(false)
    //     remarkRef.current.focus()
    // }

    // const handleRemarkFocusOut = () => {
    //     toggleRemarkDisable(true)
    //     consultState.consultList[props.mapIndex].remark = remark
    //     setConsult(remark, isCheck + "", props.index, content, props.originDate, props.type, props.phoneNum, props.name, props.region)
    // }

    // const handleRemark = (value) => {
    //     setRemark(value)
    // }
    
    const handleRadioButton = (e) => {
        setRemark(e.target.value)
        consultState.consultList[props.mapIndex].remark = e.target.value
        setConsult(e.target.value, isCheck + "", props.index, content, props.originDate, props.type, props.phoneNum, props.name, props.region)
    }

    return <>
        <ContentTr>
                <ContentTd>
                    {props.index}
                </ContentTd>
                <ContentTd>
                    {props.type}
                </ContentTd>
                <ContentTd>
                    {props.name}
                </ContentTd>
                <ContentTd>
                    {props.region}
                </ContentTd>
                <ContentTd  onClick={
                    () => {
                        // navigate(`/announce/${props.index}`, {
                        //     state : props,
                        // })
                    }
                }>
                    {
                        canHoverTooltip ? <>
                           <ToolTip message={content}>
                                <InputWrapper ref={contentRef}>
                                {
                                    content
                                }
                                </InputWrapper>
                            </ToolTip>
                        </> : <>
                            <InputWrapper ref={contentRef}>
                                {
                                    content
                                }
                            </InputWrapper>
                        </>
                    }
                 
                </ContentTd>
                <ContentTd line={" "}>
                    {props.phoneNum}
                </ContentTd>
                <ContentTd>
                    {props.date}
                </ContentTd>
                <ContentTd >
                    <InputWrapper line={" "}>
                        <RadioLabel>
                            <input type="radio" value={"1"} checked={remark === "1"} onChange={handleRadioButton} />
                            접수중
                        </RadioLabel>
                        <RadioLabel>
                            <input type="radio" value={"2"} checked={remark === "2"} onChange={handleRadioButton} />
                            처리중
                        </RadioLabel>
                        <RadioLabel>
                            <input type="radio" value={"3"} checked={remark === "3"} onChange={handleRadioButton} />
                            처리완료
                        </RadioLabel>
                            {/* <Input onBlur={() => {handleRemarkFocusOut()}}  $index={remarkDisable === false ? "10" : "0"} ref={remarkRef} 
                                    placeholder="비고란 입력해주세요."
                                    disabled={remarkDisable ? "disabled" : ""} 
                                    value={remark} onChange={({target}) => {handleRemark(target.value)}} type="text" />
                            <Label $index={remarkDisable === false ? "0" : "10"} onClick={() => {handleRemarkDisable()}} /> */}
                    </InputWrapper>
                </ContentTd>
                <ContentTd>
                    <ContentCheckBox type="checkbox" checked={isCheck} onChange={({target}) => {checkBoxHandler(target)}}/>
                </ContentTd>
                {/* <ContentTd title={" "}>
                    <SvgWrapper width={"100%"}>
                        <BackGroundDeleteSvg onClick={
                            () => {
                                openModal()
                            }
                        } />
                </SvgWrapper>
                </ContentTd> */}
        </ContentTr>
        <ConfirmModal index={props.index} open={isOpen} toggle={toggle}/>
    </>
}

const ToolTipContainer = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;

  &:hover > .tooltip,
  &:active > .tooltip {
    display: block;
  }

  .tooltip {
    white-space: pre-line;
    display: none;
    position: absolute;
    bottom: 30%;
    left: 50%;
    background-color: #E2E2E2;
    border: #000 solid 0.05rem;
    border-radius: 5px;
    color: black;
    font-size: 0.8rem;
    font-weight: 500;
    height: auto;w
    letter-spacing: -0.25px;
    margin-top: 6.8px;
    padding: 5px 11px;
    width: max-content;
    z-index: 100;
    transform: translate(-44%, 110%);

    max-width: 50vw;
    word-wrap: break-word;
    line-height: normal;
  }

`;

const ToolTip = ({children, message}) => {
    return (
        <ToolTipContainer>
            {children}
            <div className="tooltip">
                {message}
            </div>
        </ToolTipContainer>
    )
}

const ContentTr = styled.tr`
    line-height: 3rem;
    border-bottom: 0.05rem solid #ddd;
            
    @media ${device.sm} {
        line-height: 34px;
    }
`

const ContentTd = styled.td`
    color: black;
    word-break: keep-all;
    text-align: center;
    font-size: 1em;
    text-overflow: ellipsis;
    ${
        props => props.title && "cursor: pointer;"
    }
    

    @media ${device.sm} {
        font-size: 4.2rem;
    }

    line-height: 4rem;

    ${
        props => props.line && `line-height: normal;`
    }
`

function getDate(timestamp) {
    const utc = new Date()
    const offset = utc.getTimezoneOffset()
    const today = new Date(+timestamp + offset * 60000)

    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
  
    const dateString = year + '-' + month + '-' + day;
    return dateString;
}


const PageNumberWrapper = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    align-items: center;
`
const PageUl = styled.ul`
    width: 2%;
    max-width: 36px;

    list-style: none;
    border-radius: 2px;
    color: #000;
    padding: 0.4rem;
    height: 30%;
    max-height: 50px;
    
    @media ${device.sm} {
        height: 80%;
        width: 5%;
        max-height: 30px;
    }
`

const PageLi = styled.li`
    display: flex;
    justify-content: center;
    align-items: center;
    
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    height: 100%;
    width: 100%;
    font-size: 1rem;

    @media ${device.sm} {
        font-size: 5rem;
    }

    &:hover {
        background: #1C1C1C;
        color: white;
        transition: 0.5s;
    }

    ${
        props => props.active && `background: #1C1C1C; color: white;`
    }
`

const ServiceTargetSpacer = styled.div`
    width: 6vw;

    @media ${device.sm} {
        width: 0;
    }
`

const ScriptBackgroundImage= styled.div`
    height: 100%;
    width: 100%;
    background: transparent url(${service_target_01}) 50% 50% no-repeat padding-box;
    background-size: contain;
    align-self: center;

    position:relative;
    top: 0.6rem;
`


const Use = (props) => {
    const [menuState, setMenuState] = useState(props.menuIndex ?? 0)
    // const [subListState, setSubListState] = useState(0)

    const[isAdmin, setIsAdmin] = useState(false);

    useEffect( () => {
        // setIsAdmin(timeDifference(decrypt(getLocalStorage())))
        setIsAdmin(decrypt(getLocalStorage()))
    },[])

    const [consultList, setConsultList] = useState([])

    // const fetchData = async () => {
    //     const notices = await getConsult();
    //    for (let index = notices.length; index > 1; index--) {
    //        const element = notices[index - 1];
    //        setConsultList((prev) => [ ...prev, element])
    //    }
    // }

    // useEffect( () => {
    //     if(isAdmin === true) {
    //         fetchData()
    //     }
    // }, [isAdmin])

    const [pageLength, setPageLength] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostPerpage] = useState(5);

    const [currentPost, setCurrentPost] = useState([]);

    const lastIndex = currentPage * postsPerPage;
    const firstIndex = lastIndex - postsPerPage;

    const pageNumbers = [];
    const dispatch = useDispatch()

    const consultState = useSelector(state => state.consult)

    const fetchDataV2 = async () => {
        const consult = await getConsult();
        dispatch(appendConsultList(consult))
    }

    useEffect(() => {
        if(isAdmin === true) {
            fetchDataV2()
        }
    }, [isAdmin])

    useEffect(() => {
        setPageLength(Math.ceil(consultState.consultList.length / postsPerPage));
        setCurrentPost(consultState.consultList.slice(firstIndex, lastIndex));
    },[firstIndex, lastIndex, consultState.consultList, postsPerPage])


    for (let i = 1; i <= pageLength; i++) {
        pageNumbers.push(i);
    }

    const chanagePost = (pageNumber) => {
        setCurrentPage(pageNumber);
    }

    const launchKakaoTalkApp = () => {
        if(isMobile()){ 
            // 모바일
            if(isMac()){
                window.open("http://pf.kakao.com/_PCKHxj", "_blank")
            } else {
                window.open("http://pf.kakao.com/_PCKHxj", "_blank")
            } 
        } else {
            // pc
            if(isMac()){
                window.open("http://pf.kakao.com/_PCKHxj", "_blank")
                // protectToast()
            } else {
                // protectToast()
                window.open("http://pf.kakao.com/_PCKHxj", "_blank")
            }
        }        
    }


    return (
        <>
            <BasicFrame>
            <PC>
                <Menu></Menu>
                <SubMenu menuState={menuState} setMenuState={setMenuState} subMenuList={subMenuList_v2}/>
                {/* <HeaderFrame vh={((subListState === 1) || (subListState === 2)) && "120"}>  */}
                {/* <HeaderFrame vh={menuState === 0 ? "120" : "140"} >  */}
                <HeaderFrame state={"3" + menuState} > 
                    <MenuBackEmptySpace></MenuBackEmptySpace>
                        <BodyFrame height={"100%"} justify={"flex-start"} align={"center"}>
                            {
                               menuState === 1 &&  <>
                                    <VhContainer>
                                        <PcBackGroundImage06  img={cs_img} />
                                    </VhContainer>

                                    {
                                        !isAdmin && <>
                                                <Column align={"normal"}>
                                                    <NomalContainer>
                                                        <Column/>
                                                            <StyledP
                                                                size={"4"}
                                                                hexColor={"#6C9EE3"}
                                                            >
                                                                진실서비스가 필요하신가요?
                                                            </StyledP>
                                                            <Spacer></Spacer>
                                                            <StyledP
                                                                size={"4"}
                                                                hexColor={"#6C9EE3"}
                                                                margin={"1rem 0 0 0"}
                                                            >
                                                                상담을 신청하시면 진실에서 확인 후 즉시 연락드립니다.
                                                            </StyledP>
                                                    </NomalContainer>
                                                </Column>
                                            <CallService/>
                                        </>
                                    }
                                    {
                                        isAdmin && <>
                                            <Column align={"normal"}>
                                                <NomalContainer>
                                                    <Column/>
                                                    <StyledP
                                                        size={"4"}
                                                        hexColor={"#6C9EE3"}
                                                    >
                                                        상담신청 내역
                                                    </StyledP>


                                                </NomalContainer>
                                            </Column>
                                            <Column>
                                                    <TableWrapper>
                                                        <NoticeTable>
                                                            <NoticeHead>
                                                                <Td width={"6%"}>
                                                                    상담번호
                                                                </Td>
                                                                <Td width={"8%"}>
                                                                    구분
                                                                </Td>
                                                                <Td width={"10%"}>
                                                                    신청자
                                                                </Td>
                                                                <Td width={"18%"}>
                                                                    지역
                                                                </Td>
                                                                <Td width={"18%"}>
                                                                    희망서비스
                                                                </Td>
                                                                <Td width={"14%"}>
                                                                    전화번호
                                                                </Td >
                                                                <Td width={"14%"}>
                                                                    날짜
                                                                </Td>
                                                                <Td width={"20%"}>
                                                                    서비스 현황
                                                                </Td>
                                                                <Td width={"6%"}>
                                                                    상담여부
                                                                </Td>
                                                                {/* <Td width={"6%"}>
                                                                    
                                                                </Td> */}
                                                            </NoticeHead>
                                                            <NoticeBody>
                                                                {
                                                                    consultState.consultList.length > 0 && 
                                                                    currentPost.map((el, index) => <>
                                                                        <Content
                                                                            key={index}
                                                                            mapIndex={index}
                                                                            el={el}
                                                                            region={el.region ?? ""}
                                                                            index={el.index}
                                                                            type={el.userType === "1" ? "이용자" : el.userType === "2" ? "구직자" : "간편"}
                                                                            contents={el.contents}
                                                                            phoneNum={el.phoneNum}
                                                                            date={getDate(el.date)}
                                                                            isConsult={el.isConsult}
                                                                            remark={el.remark ?? ""}
                                                                            originDate={el.date}
                                                                            name={el.name}
                                                                        />
                                                                    </>)
                                                                }
                                                            </NoticeBody>
                                                        </NoticeTable>
                                                    </TableWrapper>
                                            </Column>
                                            <Column>
                                                <PageNumberWrapper >
                                                    {
                                                        pageNumbers.map((number, index) => 
                                                            { 
                                                            return (
                                                                <PageUl key={index} >
                                                                    <PageLi active={currentPage === index + 1 ?? " "}  onClick={() => 
                                                                        {
                                                                            chanagePost(number)
                                                                        }
                                                                    }
                                                                >
                                                                    <span>{number}</span>
                                                                    </PageLi>
                                                                </PageUl>
                                                            )}
                                                            )
                                                    }
                                                </PageNumberWrapper>
                                            </Column>
                                        </>
                                    }
                                </>
                            }
                        {
                            menuState === 0 && <>
                                {/* <Cost subListState={subListState} setSubListState={setSubListState}/>    */}
                                <VhContainer>
                                    <PcBackGroundImage06  img={use_img} />
                                </VhContainer>
                                <UseRight> 
                                    {/* <HrP color={"#FFFFFF"}>|</HrP> */}
                                    {/* <HrBar></HrBar>  */}
                                    <CostContentMin>
                                        <TitleSperatorWrapper pb={"0.5rem"} borderBottom={"0.1rem solid #E2E2E2"} >
                                            <StyledP
                                            size={"3.2"}
                                            weight={"700"}
                                            hexColor={"#6C9EE3"}
                                        >|</StyledP>
                                                <StyledP
                                                    hexColor={"#6C9EE3"}
                                                    size={"3.2"}
                                                >방문간호&요양 서비스이용 절차</StyledP>
                                        </TitleSperatorWrapper>
                                    <Column>
                                        <CircleWrapper>
                                            <CircleDivWrapper svg={mask01} number={1} />
                                            <p>장기요양등급 판정</p>
                                        </CircleWrapper>
                                        <Arrow></Arrow>
                                        <CircleWrapper>
                                            <CircleDivWrapper svg={mask04} number={2} />
                                            <p>센터 서비스 신청</p>
                                        </CircleWrapper>
                                        <Arrow></Arrow>
                                        <CircleWrapper>
                                            <CircleDivWrapper svg={mask03} number={3}/>
                                            <p>계약서 작성</p>
                                        </CircleWrapper>
                                        <Arrow></Arrow>
                                        <CircleWrapper>
                                            <CircleDivWrapper svg={mask02} number={4} />
                                            <p>서비스 실시</p>
                                        </CircleWrapper>
                                    </Column>
                                    <div>

                                    <P02>*장기요양 등급판정이란?</P02>
                                      <Column>
                                        <CircleWrapper>
                                            <CircleDivWrapper svg={mask05} number={1} />
                                            <p>장기요양인전신청(공단)</p>
                                        </CircleWrapper>
                                        <Arrow></Arrow>
                                        <CircleWrapper>
                                            <CircleDivWrapper svg={mask06} number={2} />
                                            <p>가정방문조사</p>
                                        </CircleWrapper>
                                        <Arrow></Arrow>
                                        <CircleWrapper>
                                            <CircleDivWrapper svg={mask07} number={3} />
                                            <p>등급판정위원회</p>
                                        </CircleWrapper>
                                        <Arrow></Arrow>
                                        <CircleWrapper>
                                            <CircleDivWrapper svg={mask08} number={4} />
                                            <p>장기요양인정서 수령</p>
                                        </CircleWrapper>
                                    </Column>
                                    </div>
                                    {/* <P03>장기요양인전신청(공단) &gt; 가정방문조사 &gt; 등급판정위원회 &gt; 장기요양인정서 수령</P03> */}
                                </CostContentMin>
                            
                                <BottomRight>
                                    <RightWrapper>
                                        <RightWrapper01 isBackground={" "}>
                                        <ScriptBackgroundImage/>
                                            {/* <RightPWrapper >
                                                <RightP >대상자</RightP>
                                            </RightPWrapper>
                                            <ServiceTarget >
                                                <ServiceTargetSpacer/>
                                                <p>1.</p>
                                                <p>장기요양급여 수급자 (1~5등급)</p>
                                            </ServiceTarget>
                                            <ServiceTarget>
                                                <ServiceTargetSpacer/>
                                                <p>2.</p>
                                                <p>심신 허약하거나 장애가 있는 65세 이상의 자</p>
                                            </ServiceTarget>
                                            <ServiceTarget>
                                                <ServiceTargetSpacer/>
                                                <p>3.</p>
                                                <p>방문요양서비스가 필요한 60세 이상의 자</p>
                                            </ServiceTarget> */}
                                        </RightWrapper01>
                                        <RightWrapper02>
                                            <RightPWrapper >
                                                <RightP >센터 연락처</RightP>
                                            </RightPWrapper>
                                            <WrapperRow min>
                                                <p>대표번호</p>
                                                <p>043-643-6430</p>
                                            </WrapperRow>
                                            <WrapperRow min>
                                                <p>시설장</p>
                                                <p>010-3680-7356</p>
                                            </WrapperRow >
                                            <WrapperRow min>
                                                <p>이메일</p>
                                                <p>jinsilcare@gmail.com</p>
                                            </WrapperRow>
                                            <WrapperRow min>
                                                <p>카카오톡</p>
                                                <p onClick={() => {
                                                    launchKakaoTalkApp()
                                                }}>진실시니어케어</p>
                                            </WrapperRow>
                                        </RightWrapper02>
                                    </RightWrapper>
                                </BottomRight>
                                </UseRight>   
                                <UseRight> 
                                    <Spacer></Spacer>
                                    <TitleSperatorWrapper pb={"0.5rem"} borderBottom={"0.1rem solid #E2E2E2"}>
                                    <StyledP
                                       size={"3.2"}
                                       weight={"700"}
                                       hexColor={"#6C9EE3"}
                                    >|</StyledP>
                                    <StyledP
                                        size={"3.2"}
                                        hexColor={"#6C9EE3"}
                                    >방문간호 이용요금 안내
                                    </StyledP>
                                    </TitleSperatorWrapper>
                                    <CostContentMin start>
                                        <RightWrapper column minHeight>
                                            <RightWrapper01 max>
                                                <RightPWrapper hasMinHeight={"true"}>
                                                    <RightP>장기요양등급별 방문간호 월 한도액</RightP>
                                                </RightPWrapper>
                                                <MonthlyNurseCharge01 />
                                            </RightWrapper01>
                                            <RightWrapper02 max>
                                                <RightPWrapper hasMinHeight={"true"} direction>
                                                    <RightP>2024년도 방문간호 이용요금 (방문인지학습, 관절운동 등)</RightP>
                                                </RightPWrapper>
                                                <MonthlyNurseCharge02 />
                                            </RightWrapper02>
                                        </RightWrapper>
                                    </CostContentMin>
                                </UseRight>    

                                <UseRight> 
                                    <Spacer></Spacer>
                                    <TitleSperatorWrapper pb={"0.5rem"} borderBottom={"0.1rem solid #E2E2E2"}>
                                    <StyledP
                                         size={"3.2"}
                                         weight={"700"}
                                         hexColor={"#6C9EE3"}
                                        >|</StyledP>
                                        <StyledP
                                            size={"3.2"}
                                            hexColor={"#6C9EE3"}
                                        >방문요양 이용요금 안내
                                        </StyledP>
                                    </TitleSperatorWrapper>
                                    {/* <HrBar></HrBar> */}
                                    <CostContentMin start>
                                    <RightWrapper column minHeight2>
                                        <RightWrapper01 max>
                                            <RightPWrapper hasMinHeight={"true"}>
                                                <RightP>장기요양등급별 방문요양 월 한도액</RightP>
                                            </RightPWrapper>
                                            <MonthlyNurseCharge01 />
                                    
                                        </RightWrapper01>
                                        <RightWrapper02 max>
                                            <RightPWrapper hasMinHeight={"true"} direction>
                                                        <RightP>2024년도 방문요양 이용요금</RightP>
                                                    </RightPWrapper>
                                            <MonthlyNurseCharge03 />
                                        </RightWrapper02>
                                    </RightWrapper>
                                    </CostContentMin> 
                                </UseRight>  
                            </>
                        }
                    </BodyFrame>
                </HeaderFrame>
                <FotterFrame />
            </PC>
            </BasicFrame>
            <Mobile>
                <MenuWarpper >
                    <Menu/>
                    <SubMenu menuState={menuState} 
                    setMenuState={setMenuState} subMenuList={subMenuList_v2} />
                </MenuWarpper>
                {
                    menuState === 0 && <>
                       <MobileCommonFrame vh={"30"} align={" "}>
                            <MobileVisit01ImageWrapper>
                                <BackGroundImage06 img={use_img}></BackGroundImage06>
                            </MobileVisit01ImageWrapper>
                        </MobileCommonFrame>
                        <MobileCommonFrame vh={"8"}>
                        <TitleSperatorWrapper pb={"2rem"} borderBottom={"0.6rem solid #E2E2E2"}>
                                <StyledP
                                size={"6"}
                                weight={"700"}
                                hexColor={"#6C9EE3"}
                                >|</StyledP>
                                <StyledP
                                    size={"6"}
                                    hexColor={"#6C9EE3"}
                                >         방문간호 & 요양 서비스 이용절차
                                </StyledP>
                            </TitleSperatorWrapper>
                                {/* <StyledP
                                    size={"6"}
                                    hexColor={"#6C9EE3"}
                                    weight={"700"}
                                >
                           
                                </StyledP> */}
                            </MobileCommonFrame>  
                        <HeaderFrame vh={"36"}>
                            <CircleContainer>
                                <CircleWrapper>
                                    <CircleDivWrapper svg={mask01} number={1}/>
                                    <StyledP
                                        size={"4.2"}
                                        margin={"4rem 0 4rem 0"}
                                    >
                                        장기요양등급 판정
                                    </StyledP>
                                </CircleWrapper>
                                <Arrow />

                                <CircleWrapper>
                                <CircleDivWrapper svg={mask04} number={2}/>
                                    <StyledP
                                        size={"4.2"}
                                        margin={"4rem 0 4rem 0"}
                                    >
                                        센터 서비스 신청
                                    </StyledP>
                                </CircleWrapper>
                            </CircleContainer>

                        </HeaderFrame>
                        {/* <HeaderFrame vh={"4"}>
                            <ArrowWapper>
                                <Arrow></Arrow>
                            </ArrowWapper>
                        </HeaderFrame> */}
                        {/* <HeaderFrame vh={"30"}>
                            <CircleWrapper>
                                <CircleDivWrapper svg={mask04} number={2}/>
                                <StyledP
                                    size={"4.2"}
                                    margin={"4rem 0 4rem 0"}
                                >
                                    센터 서비스 신청
                                </StyledP>
                            </CircleWrapper>
                        </HeaderFrame>
                        <HeaderFrame vh={"4"}>
                            <ArrowWapper>
                                <Arrow></Arrow>
                            </ArrowWapper>
                        </HeaderFrame> */}
                        <HeaderFrame vh={"36"}>
                            <CircleContainer>

                            <CircleWrapper>
                                <CircleDivWrapper svg={mask03} number={3} />
                                <StyledP
                                    size={"4.2"}
                                    margin={"4rem 0 4rem 0"}
                                >
                                    계약서 작성
                                </StyledP>
                            </CircleWrapper>
                                <Arrow></Arrow>
                            <CircleWrapper>
                                <CircleDivWrapper svg={mask02} number={4} />
                                <StyledP
                                    size={"4.2"}
                                    margin={"4rem 0 4rem 0"}
                                >
                                    서비스 실시
                                </StyledP>
                            </CircleWrapper>
                            </CircleContainer>
                        </HeaderFrame>
                        {/* <HeaderFrame vh={"4"}>
                            <ArrowWapper>
                                <Arrow></Arrow>
                            </ArrowWapper>
                        </HeaderFrame> */}
                        {/* <HeaderFrame vh={"30"}>
                            <CircleWrapper>
                                <CircleDivWrapper svg={mask02} number={4} />
                                <StyledP
                                    size={"4.2"}
                                    margin={"4rem 0 4rem 0"}
                                >
                                    서비스 실시
                                </StyledP>
                            </CircleWrapper>
                        </HeaderFrame> */

                        /*
                        
                        <Column>
                                        <CircleWrapper>
                                            <CircleDivWrapper svg={mask05} number={1} />
                                            <p>장기요양인전신청(공단)</p>
                                        </CircleWrapper>
                                        <Arrow></Arrow>
                                        <CircleWrapper>
                                            <CircleDivWrapper svg={mask06} number={2} />
                                            <p>가정방문조사</p>
                                        </CircleWrapper>
                                        <Arrow></Arrow>
                                        <CircleWrapper>
                                            <CircleDivWrapper svg={mask07} number={3} />
                                            <p>등급판정위원회</p>
                                        </CircleWrapper>
                                        <Arrow></Arrow>
                                        <CircleWrapper>
                                            <CircleDivWrapper svg={mask08} number={4} />
                                            <p>장기요양인정서 수령</p>
                                        </CircleWrapper>
                                    </Column>*/
                        }
                        <MobileCommonFrame vh={"4"}>
                            <TitleSperatorWrapper pb={"2rem"} borderBottom={"0.6rem solid #E2E2E2"}>
                                <StyledP
                                size={"6"}
                                weight={"700"}
                                hexColor={"#6C9EE3"}
                                >|</StyledP>
                                <StyledP
                                    size={"6"}
                                    hexColor={"#6C9EE3"}
                                >장기요양 등급판정이란?
                                </StyledP>
                            </TitleSperatorWrapper>
                        </MobileCommonFrame>  
                        <HeaderFrame vh={"36"}>
                            <CircleContainer>
                                <CircleWrapper>
                                    <CircleDivWrapper svg={mask05} number={1}/>
                                    <StyledP
                                        size={"4.2"}
                                        margin={"4rem 0 4rem 0"}
                                    >
                                        장기요양인전신청(공단)
                                    </StyledP>
                                </CircleWrapper>
                                <Arrow />

                                <CircleWrapper>
                                <CircleDivWrapper svg={mask06} number={2}/>
                                    <StyledP
                                        size={"4.2"}
                                        margin={"4rem 0 4rem 0"}
                                    >
                                        가정방문조사
                                    </StyledP>
                                </CircleWrapper>
                            </CircleContainer>
                        </HeaderFrame>
                        <HeaderFrame vh={"36"}>
                            <CircleContainer>
                                <CircleWrapper>
                                    <CircleDivWrapper svg={mask07} number={3}/>
                                    <StyledP
                                        size={"4.2"}
                                        margin={"4rem 0 4rem 0"}
                                    >
                                        등급판정위원회
                                    </StyledP>
                                </CircleWrapper>
                                <Arrow />

                                <CircleWrapper>
                                <CircleDivWrapper svg={mask08} number={4}/>
                                    <StyledP
                                        size={"4.2"}
                                        margin={"4rem 0 4rem 0"}
                                    >
                                        장기요양인정서 수령
                                    </StyledP>
                                </CircleWrapper>
                            </CircleContainer>
                        </HeaderFrame>
                        {/* <MobileCommonFrame vh={"3"}>
                                <StyledP
                                    size={"4.2"}
                                    hexColor={"#838383"}
                                >
                                   장기요양인전신청(공단) &gt; 가정방문조사 &gt;
                                </StyledP>
                        </MobileCommonFrame>  
                        <MobileCommonFrame vh={"3"}>
                                <StyledP
                                    size={"4.2"}
                                    hexColor={"#838383"}
                                >
                                    등급판정위원회 &gt; 장기요양인정서 수령
                                </StyledP>
                        </MobileCommonFrame>   */}
                      
                        <MobileCommonFrame vh={"50"}>
                            <NormalWrapper>
                                <StyledP
                                    size={"6"}
                                    hexColor={"#6C9EE3"}
                                >
                                    대상자
                                </StyledP>
                        <RightWrapper01>
                                    <ServiceTarget>
                                        <ServiceTargetSpacer/>
                                        <p>1.</p>
                                        <p>장기요양급여 수급자 (1~5등급)</p>
                                    </ServiceTarget>
                                    <ServiceTarget>
                                        <ServiceTargetSpacer/>
                                        <p>2.</p>
                                        <p>심신 허약하거나 장애가 있는 65세 이상의 자</p>
                                    </ServiceTarget>
                                    <ServiceTarget>
                                        <ServiceTargetSpacer/>
                                        <p>3.</p>
                                        <p>방문요양서비스가 필요한 60세 이상의 자</p>
                                    </ServiceTarget>
                                </RightWrapper01>
                                <div style={{"height": "5rem"}}></div>
                                <RightWrapper02>
                                    <RightPWrapper >
                                        <RightP >센터 연락처</RightP>
                                    </RightPWrapper>
                                    <WrapperRow min>
                                        <p>대표번호</p>
                                        <p>043-643-6430</p>
                                    </WrapperRow>
                                    <WrapperRow min>
                                        <p>시설장</p>
                                        <p>010-3680-7356</p>
                                    </WrapperRow >
                                    <WrapperRow min>
                                        <p>이메일</p>
                                        <p>jinsilcare@gmail.com</p>
                                    </WrapperRow>
                                    <WrapperRow min>
                                        <p>카카오톡</p>
                                        <p onClick={() => {
                                            launchKakaoTalkApp()
                                        }}>진실시니어케어</p>
                                    </WrapperRow>
                                </RightWrapper02>
                            </NormalWrapper>
                        </MobileCommonFrame>  
                        <MobileCommonFrame vh={"100"} >
                            <MobileColumnContainer>
                            <TitleSperatorWrapper pb={"2rem"} borderBottom={"0.6rem solid #E2E2E2"} width={"100"}>
                                <StyledP
                                size={"6"}
                                weight={"700"}
                                hexColor={"#6C9EE3"}
                                >|</StyledP>
                                <StyledP
                                    size={"6"}
                                    hexColor={"#6C9EE3"}
                                >방문간호 이용요금 안내
                                </StyledP>
                            </TitleSperatorWrapper>

                            <CostContentMin start>
                                <RightWrapper column minHeight>
                                    <RightWrapper01 max>
                                        <RightPWrapper hasMinHeight={"true"}>
                                            <RightP>장기요양등급별 방문간호 월 한도액</RightP>
                                        </RightPWrapper>
                                        <MonthlyNurseCharge01 />
                                    </RightWrapper01>
                                    <RightWrapper02 max>
                                        <RightPWrapper hasMinHeight={"true"} direction>
                                            <RightP>2024년도 방문간호 이용요금<br/> (방문인지학습, 관절운동 등)</RightP>
                                        </RightPWrapper>
                                        <MonthlyNurseCharge02 />
                                    </RightWrapper02>
                                </RightWrapper>
                            </CostContentMin>
                            </MobileColumnContainer>
                        </MobileCommonFrame>

                        <MobileCommonFrame vh={"120"} >
                            <MobileColumnContainer>
                             <TitleSperatorWrapper pb={"2rem"} borderBottom={"0.6rem solid #E2E2E2"} width={"100"}>
                                    <StyledP
                                         size={"6"}
                                         weight={"700"}
                                         hexColor={"#6C9EE3"}
                                        >|</StyledP>
                                        <StyledP
                                            size={"6"}
                                            hexColor={"#6C9EE3"}
                                        >방문요양 이용요금 안내
                                        </StyledP>
                                    </TitleSperatorWrapper>
                                    {/* <HrBar></HrBar> */}
                                    <CostContentMin start>
                                    <RightWrapper column minHeight2>
                                        <RightWrapper01 max>
                                            <RightPWrapper hasMinHeight={"true"}>
                                                <RightP>장기요양등급별 방문요양 월 한도액</RightP>
                                            </RightPWrapper>
                                            <MonthlyNurseCharge01 />
                                        </RightWrapper01>
                                        <RightWrapper02 max>
                                            <RightPWrapper hasMinHeight={"true"} direction>
                                                        <RightP>2024년도 방문요양 이용요금</RightP>
                                                    </RightPWrapper>
                                            <MonthlyNurseCharge03 />
                                        </RightWrapper02>
                                    </RightWrapper>
                                    </CostContentMin> 
                                </MobileColumnContainer>
                        </MobileCommonFrame>
                    </>
                }
                {
                    menuState === 1 && <CallService />
                }
                
                <FotterFrame/>
            </Mobile>
        </>
        
    )
}

export default Use;
import {getDocs, doc, collection, getDoc, setDoc, query, where, deleteDoc } from 'firebase/firestore/lite'
import { db } from '../App'
import { commonApi } from '../api/config';

export async function getNotice(){
    const snap = await commonApi.post('/notice/get-all')
    const {detail} = snap.data
    const list = []

    if(Array.isArray(detail) && detail.length){
        detail.forEach( el => {
            el.index = el.noticeId
            delete el.noticeId
            list.push(el)
        })

        list.sort( (a, b) => {
            if(+a.index < +b.index) return 1;
            if(+a.index === +b.index) return 0;
            if(+a.index > +b.index) return -1;
        })
    }

    return list
}



export async function subNotice(){

    // const unsub = onSnapshot(doc(db, "notice", "SF"), (doc) => {
    //     console.log("Current data: ", doc.data());
    // });


    // const q = query(collection(db, "notice"), where("index", "!=", "0"));
    
    // onSnapshot(q, (querySnapshot) => {
    //     const list = []

    //     if(!querySnapshot.empty){

    //         querySnapshot.forEach((doc) => {
    //             const newObject = {
    //                 ...doc.data(), 
    //                 id : doc.id,
    //             }
    
    //             list.push(newObject)
    //         });
    
    //     }

    //     return list
    // });
}


export async function getAuth(inputId, inputPw){
    try {
        const snap = await commonApi.post('/account/auth', {id : inputId, pw : inputPw})
        if(snap){
            const {detail} = snap.data
            if(detail === 1){
                return true
            }
            return false
        }
    } catch (error) {
        return false
    }
    return false
}

export async function addNotice(title, contents){
    try {
        await commonApi.post('/notice/add-notice', {
            title : title,
            contents : contents,
            date : Date.now() + ""
        })
    } catch (error) {
    }
}

export async function setNotice(title, contents, index, isShow){
    try {
        await commonApi.post('/notice/add-notice', {
            index : index + "",
            isShow : isShow,
            title : title,
            contents : contents,
            date : Date.now() + ""
        })
    } catch (error) {
        console.error(error)
    }

    // await setDoc(doc(db, 'notice', index), {
    //     contents : content,
    //     date : Date.now() + "",
    //     index : index,
    //     title : title,
    //     writer : "관리자",
    //     isShow : isShow,
    // })
}

export async function getNextDoc(index){
    const q = query(collection(db, 'notice'), where('index', "==", +index + 1 + ""))
    const querySnapshot = await getDocs(q)

    if(!querySnapshot.empty){
        return querySnapshot.docs[0].data()
    } 
 
    return null
}

export async function getBeforeDoc(index){
    const q = query(collection(db, 'notice'), where('index', "==", +index - 1 + ""))
    const querySnapshot = await getDocs(q)

    if(!querySnapshot.empty){
        return querySnapshot.docs[0].data()
    } 

    return null
}

export async function dropDoc(index){
    await deleteDoc(doc(db, 'notice', index + ""))
}
import styled from "styled-components"

import img_01  from "../assets/image/main_xl.png";
import img_02  from "../assets/image/main_m.png";

import logo  from "../assets/image/logo_001.png";
import JinsilModal from "./modal";
import { useEffect, useState } from "react";
import LoginModal from "./loginModal";
import { decrypt, encrypt, getLocalStorage, removeLocalStorageKey, setLocalStorage, timeDifference } from "../script/cipher";
import { useNavigate } from "react-router-dom";
import { Mobile, PC, isMobile } from "../script/responsive";
import { toDayCount } from "../firebase/visitFireStore";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { toogleSubmitFlag } from "../reducers/ConsultReducer";

const size = {
    xs: '512px', // for small screen mobile
    sm: '640px', // for mobile screen
    md: '900px', // for tablets
    lg: '1280px', // for laptops
    xl: '1480px', // for desktop / monitors
    xxl: '1920px', // for big screens
  }
  
const device = {
    xs: `(max-width: ${size.xs})`,
    sm: `(max-width: ${size.sm})`,
    md: `(max-width: ${size.md})`,
    lg: `(max-width: ${size.lg})`,
    xl: `(max-width: ${size.xl})`,
    xxl: `(max-width: ${size.xxl})`,
  }
  
const LogoBox = styled.img`
    width: 8em;

    &:hover {
        cursor: pointer;
    }

    @media ${device.sm} {
        font-size: 3rem;
    }
`
export const BasicFrame = styled.div`
    height: 100vh;
    width: 100vw;

    @media ${device.sm} {
        display: flex;
        flex-direction: column;
        height: auto;
    }
`

export const HeaderFrame = styled.div`
    color : White;
    height: 100vh;
    overflow: auto;
    display:flex;
    flex-direction: column;
    ${
        ({justify}) => justify && `justify-content: ${justify};`
    }
    ${
        props => props.state === "11" && "height: 2300px;"
    }
    ${
        props => props.state === "20" && `height: 2800px;  @media ${device.md} {height: 3000px;} @media ${device.lg} {height: 3240px;}`
    }
    ${
        props => props.state === "21" && `height: 2800px; @media ${device.md} {height: 2600px;}`
    }
    ${
        props => props.state === "30" && "height: 4070px;"
    }
    ${
        props => props.state === "31" && "height: 1640px;"
    }

    ${
        props => props.state === "10" && "height: 1340px;"
    }
    ${
        props => props.state === "12" && "height: 1340px;"
    }
    ${
        props => props.state === "40" && "height: 1300px;"
    }

    ${
        props => (props.state === "40" && props.isAdmin) && "height: 1140px;"
    }

    ${
        props => props.state === "50" && "height: 1140px;"
    }

    ${
            ({vh}) => vh && `height: ${vh}vh;`
    }

    @media ${device.sm} {
        height: 50vh;

        ${
            ({vh}) => vh && `height: ${vh}vh;`
        }
    }
`

export const StyledP = styled.p`
    word-break: keep-all;
    ${
        props => 
        props.size && `font-size: ${+props.size / 2}rem;`
    }
    ${
        props => 
        props.cursor && `cursor: ${props.cursor};`
    }
    
    ${
        props => 
        props.borderBottom && `border-bottom: ${props.borderBottom};`
    }

    ${
        props => 
        props.width && `width: ${props.width};`
    }

    ${
        props => 
        props.word && `word: ${props.width};`
    }


    ${
        props => 
        props.align && `text-align: ${props.align};`
    }
    
    ${
        props => props.minWidth && `min-width: ${props.minWidth};`
    }
    @media ${device.sm} {
        ${
            props => 
            props.size && `font-size: ${props.size}rem;`
        }
    }
    ${
        props => 
        props.hexColor && `color: ${props.hexColor};`
    }
    ${
        props => 
        props.weight && `font-weight: ${props.weight};`
    }
    ${
        props => 
        props.margin && `margin: ${props.margin};`
    }
    ${
        props => 
        props.padding && `padding: ${props.padding};`
    }
`

export const FooterFrame = styled.div`
`
export const BackGroundImage02 = styled.div`
    background: #343F44;
    height: 100%;
    width: 100%;
    z-index: 4;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    background: transparent url(${img_02}) 50% 50% no-repeat padding-box;
    background-size: contain;
    opacity: 1;

    @media ${device.sm} {
        height: 50vh;

        background-position: 50% 35%;
    }
`
export const BackGroundImage = styled.div`
    background: #343F44;
    height: 100%;
    width: 100%;
    z-index: 1;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    background: transparent url(${img_01}) 50% 50% no-repeat padding-box;
    background-size: cover;
    opacity: .6;

    @media ${device.sm} {
        height: 50vh;

        background-position: 50% 35%;
    }
`

export const MainScript01 = styled.p`
    position: absolute;
    color: black;
    top: 66%;
    left: 12%;
    text-align: left;
    font-weight: 700;
    font-size: 2.2rem;
    z-index: 5;
    ${
        props => 
        props.top && `top: ${props.top}%;`
    }

    @media ${device.sm} {
        font-size: 5rem;
        top: 22;
    }
`

export const MainScript02 = styled.p`
    position: absolute;
    color: black;
    top: 66%;
    left: 12%;
    text-align: left;
    font-weight: 700;
    font-size: 2.2rem;
    z-index: 5;

    ${
        props => 
        props.top && `top: ${props.top}%;`
    }

    @media ${device.sm} {
        font-size: 5rem;
        top: 32%;
    }
`

export const SubScript = styled.p`
    position: absolute;
    color: black;
    top: 40%;
    left: 12%;
    text-align: left;
    font-size: 1.6rem;
    z-index: 5;

    @media ${device.sm} {
        font-size: 4.2rem;
        top: 42%;
    }
`


const FabStyle = styled.div`
    display: flex;
    position: fixed;
    width: 6rem;
    height: 250px;
    line-height: 1.2rem;
    left: 1em;
    bottom: 1em;
    background-color: rgb(140,198,63);
    border-radius: 4px;
    color: #fff;
    padding: 4px;

    justify-content: center;

    font-weight: 400;
    max-height: 4.4rem;
    cursor: pointer;
    
    z-index: 4;

    &:hover {
        cursor: pointer;
    }

    ${
        props => props.isLogout && "background-color: #ff470e; width: 5rem;"
    }

     @media ${device.sm} {
        width: 18vw;
        max-height: 20rem;
        line-height: normal;
        left: 3em;
        bottom: 6em;
        z-index: 40;
    }
`

export const MobileCommonFrame = styled.div`
    background-color: white;
    width: 100%;
    color : black;
    display : flex;
    justify-content: center;
    align-items: center;

    ${
        props => props.vh && "height: " + props.vh + "vh;"
    }

    ${
        props => props.align && "flex-direction: column;"
    }
`

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
`

export const FloatingActionButton = () => {
    const [isOpen, toggle] = useState(false)

    const openModal = () => {
        toggle(!isOpen)
        document.body.style = 'overflow: hidden;'
    }

    const [isPC, setMediaquery] = useState(window.innerWidth < 641 ? false : true)

    useEffect( ()=> {

        if(window){
            var x = window.matchMedia("(min-width: 641px)")
            x.addEventListener("change", screenChange);
        }

        return () => {
            x.removeEventListener("change", screenChange)
        }
    }, []) 

    const screenChange = (x) => {
        setMediaquery(x.matches)
    }


    return (
      <>
        <FabStyle onClick={ () => {openModal()}}>
            <Container>
            <PC>
                <StyledP>
                    간편상담
                </StyledP>
                <StyledP margin={"0.2rem 0 0 0"}>
                    신청하기
                </StyledP>
            </PC>
            <Mobile>
                <StyledP size={"5"}>
                    간편상담
                </StyledP>
                <StyledP size={"5"} margin={"0.2rem 0 0 0"}>
                    신청하기
                </StyledP>
            </Mobile>
            </Container>
        </FabStyle>
        <JinsilModal open={isOpen} toggle={toggle}/>
      </>
    )
}

export const LogoutActionButton = () => {
    const Container = styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        flex-direction: column;
    `

    return (
      <>
        <FabStyle isLogout onClick={ () => {}}>
            <Container>
                <StyledP>
                    로그아웃
                </StyledP>
            </Container>
        </FabStyle>
      </>
    )
}


const Fotter = styled.div`
height: 32vh;
background-color: #1C1C1C;
color: white;
width: 100%;

display: flex;
justify-content: space-around;
flex-direction: column;
align-items: center;

min-height: 250px;

`

const Top = styled.div`
width: 70%;
display: flex;
justify-content: space-between;

@media ${device.sm} {
    width: 80%;
    align-items: flex-start;
}

@media ${device.xxl} {
    width: 80%;
}
`

const TopChild = styled.div`
display: flex;
justify-content: space-evenly;
color: #838383;
> * {   
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3){
        cursor: pointer;

        @media ${device.sm} {
            font-size: 3rem;
        }
    }

    &:nth-child(2),
    &:nth-child(3)
    {
        margin: 0 0 0 20px;

        @media ${device.sm} {
            margin: 0 0 0 10px;
        }
    }
}
align-items: center;
height: 100%;
`

const Middle = styled.div`
display: flex;
width: 70%;
flex-direction: column;

@media ${device.sm} {
    width: 80%;
    justify-content: center;
    align-items: flex-start;
}

@media ${device.xxl} {
    width: 80%;
}
`

const Middle01 = styled.div`
display: flex;

margin-bottom: 20px;
color: #838383;
> * {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(4){
        font-weight: 800;
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5){
        margin: 0 0 0 12px;

        @media ${device.sm} {
            margin: 0 0 0 1rem;
        }
    }
}

@media ${device.sm} {
    font-size: 4.4rem;
}

@media ${device.xs} {
    font-size: 3rem;
}
`

const Bottom = styled.div`
width: 70%;
font-weigh: 800;
color: #838383;
@media ${device.xxl} {
    width: 80%;
}
@media ${device.sm} {
    font-size: 3rem;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
`

const MiddleScript = styled.div`
display: flex;
color: #838383;
margin: 0.1rem 0;

> * {
    &:nth-child(1){
        font-weight: 800;
    }

    &:nth-child(2){
        margin: 0 0 0 8px;

        @media ${device.sm} {
            margin: 0 0 0 4px;
        }
    }

}

@media ${device.sm} {
    font-size: 3rem;
    margin: 2rem 0;
}
`

export const FotterFrame = () => {
    const [isOpen, toggle] = useState(false)
    const navigate = useNavigate()
    const openModal = () => {
        toggle(!isOpen)
        document.body.style = 'overflow: hidden;'
    }
    const [isAdmin, setIsAdmin] = useState(false);
    const [mobile, setMobile] = useState(false);


    const [isPC, setMediaquery] = useState(window.innerWidth < 641 ? false : true)
    const dispatch = useDispatch()
    const consultState = useSelector(state => state.consult)

    useEffect( ()=> {

        if(window){
            var x = window.matchMedia("(min-width: 641px)")
            x.addEventListener("change", screenChange);
        }

        return () => {
            x.removeEventListener("change", screenChange)
        }
    }, []) 

    const screenChange = (x) => {
        setMediaquery(x.matches)
    }

    useEffect( () => {
        // setIsAdmin(timeDifference(decrypt(getLocalStorage())))
        setIsAdmin(decrypt(getLocalStorage()))
        setMobile(isMobile())
    },[])

    const confirmToast = (text) => toast.success(`${text}`, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    })

    useEffect(() => {
        // console.log("모달 on", consultState.consultSubmitFlag + " ")

        if(consultState.consultSubmitFlag === true){
            confirmToast("상담 신청이 완료되었습니다.")
            dispatch(toogleSubmitFlag())
        }
    }, [
        consultState.consultSubmitFlag
    ])

    useEffect( () => {
        toDayCount()
    }, [])



    return (
        <Fotter>
            <Top>
            <LogoBox src={logo}>
            </LogoBox>
                <TopChild>
                {
                        isAdmin && <>
                            <StyledP cursor={"pointer"}
                                onClick={ () => {
                                    setLocalStorage(encrypt(false + ""))
                                    window.location.reload()
                                }}
                            >로그아웃</StyledP>
                        </>
                }
                {
                    !isAdmin && <>
                        <p onClick={()=>{
                            if(!mobile && !isAdmin){
                                openModal()
                            }
                        }}>로그인</p>
                    </>
                }
                    <p>이용약관</p>
                    <p>개인정보처리방침</p>
                </TopChild>
            </Top>
            <Middle>
                <Middle01>
                    <p>진실시니어케어센터</p>
                    <p>대표자</p>
                    <p>장희진</p>
                    <p>사업자번호</p>
                    <p>487-35-01469</p>
                </Middle01>
                <MiddleScript>
                    <p>충북 제천시 용두천로 78, 대동빌딩 1층 <span
                      
                    >진실시니어케어센터</span></p>
                  
                </MiddleScript>
                <MiddleScript>
                    <p>T</p>
                    <p>043-643-6430</p>
                </MiddleScript>
                <MiddleScript>
                    <p>M</p>
                    <p>jinsilcare@gmail.com</p>
                </MiddleScript>
            </Middle>
            <Bottom>
                <p>© 2023 진실시니어케어센터</p>
            </Bottom>
            <LoginModal open={isOpen} toggle={toggle}/>
            <ToastContainer style={{fontSize : "14px", width : isPC ?"24vw" : "100%"}}/>
        </Fotter>
    )
}

export const CrossBar = () => {
    const Wrapper = styled.div`
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        left: 6%;
        top: 20%;

        @media ${device.sm} {
            left: 8%;
            top: 30%;
        }
    `
    const VerticalBar = styled.div`
        position: absolute;
        background-color: #EFF9FE;
        width: 200px;
        height: 60px;
        padding: 0;
        margin: 0;

        @media ${device.sm} {
            width: 90px;
            height: 30px;
        }
    `

    const HorizontalBar = styled.div`
        position: absolute;
        background-color: #EFF9FE;
        width: 60px;
        height: 200px;
        padding: 0;
        margin: 0;

        @media ${device.sm} {
            width: 30px;
            height: 90px;
        }
    `
    
    return (
        <Wrapper>
            <VerticalBar/>
            <HorizontalBar/>
        </Wrapper>
    )   
}


// hover:bg-blue-700
// hover:drop-shadow-2xl 
// hover:animate-bounce duration-300"
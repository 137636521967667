import React, { useEffect, useState } from "react"
import Modal from "react-modal"
import styled from "styled-components";
import { StyledP } from "./frame";
import { getAuth } from "../firebase/noticeFirestore";
import { useNavigate } from "react-router-dom";
import { encrypt, setLocalStorage } from "../script/cipher";
import x_symbol_svg  from "../assets/image/x_symbol.svg";

const size = {
    xs: '512px', // for small screen mobile
    sm: '640px', // for mobile screen
    md: '900px', // for tablets
    lg: '1280px', // for laptops
    xl: '1480px', // for desktop / monitors
    xxl: '1920px', // for big screens
}
  
const device = {
    xs: `(max-width: ${size.xs})`,
    sm: `(max-width: ${size.sm})`,
    md: `(max-width: ${size.md})`,
    lg: `(max-width: ${size.lg})`,
    xl: `(max-width: ${size.xl})`,
    xxl: `(max-width: ${size.xxl})`,
}
  

const ModalBackgroundContainer = styled.div`
    min-width: 400px;
    height: 400px;

    display: flex;
    flex-diraction: column;
    justify-content: center;
    align-items: center;

    @media ${device.sm} {
        min-width: calc(100vw - 20vw);
    }
`
Modal.setAppElement('#root');

const ModalContainer = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`

const Top = styled.div`
    word-break: keep-all;
    word-wrap: break-word;
    display: block;
    padding: 1rem 1rem;
    font-weight: 500;
    text-align: left;

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    
    border-bottom: 0.1rem solid black;
    
    @media ${device.sm} {
        padding: 4rem 2rem;
        border-bottom: 0.6rem solid #ddd;
    }
`
 
const Middle = styled.div`
    width: 100%;

`

const Bottom = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
`

const InputRadio = styled.input`
`
const RadioLabel = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;

    @media ${device.sm} {
        text-align: center;
    }
`

const RadioContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`


const RadioWrapper = styled.div`
    width: 100%;
    display:flex;
`

const Cancel = styled.button`
    display: inline-block;
    vertical-align: top;
    font-weight: 500;
    font-size: 0.8rem;
    background-color: #FFFFFF;
    line-height: 30px;
    min-width: 3.4rem;
    height: 2rem;
    padding: 0 22px;
    border: 0.1rem solid #ddd;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;

    @media ${device.sm} {
        font-size: 5rem;
        height: 12rem;
        border: 0.6rem solid #ddd;
    }
`

const Submit = styled.button`
    display: inline-block;
    vertical-align: top;
    font-weight: 500;
    font-size: 0.8rem;
    background-color: #fff;
    line-height: 34px;
    min-width: 3.4rem;
    height: 2rem;
    padding: 0 22px;
    border-radius: 4px;
    border: 0.1rem solid #ddd;
    text-align: center;
    margin-left: 0.4rem;
    cursor: pointer;

    @media ${device.sm} {
        font-size: 5rem;
        height: 12rem;
        border: 0.6rem solid #ddd;
        margin-left: 1.4rem;
    }
`

const InputContianer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
`

const RadioContianer = styled.div`
    width: 100%;
    min-width: 4rem;
    display: flex;
    align-items: center;

    @media ${device.sm} {
        width: 70%;
    }
`

const InputWrapper = styled.div`
    width: 8.4rem;
    height: 2rem;
    position: relative;
    box-sizing: border-box;
    margin-left: 1rem;
    margin-right: 1rem;

    ${
        props => props.width && `width: ${props.width};`
    }

    @media ${device.sm} {
        height: 16rem;
        width: 100%;
    }
`

const ModalInput = styled.input`
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 1px;
    right: 1px;
    bottom: 0;
    z-index: 1;
    resize: none;
    margin: 10px 0;
    padding: 0 18px 0 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #191919;
    border: 0 none;
    outline: none;
    background: transparent;
    box-sizing: border-box;
`

const InputLabel = styled.label`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #fff;
    box-sizing: border-box;
`

const PWrapper = styled.div`
    max-width: 150px;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content:center;
    min-height: 3rem;

    @media ${device.sm} {
        min-height: 20rem;
        max-width: 64px;
    }
`

export const XSymbolSvg = styled.div`
    height: 100%;
    background: transparent url(${x_symbol_svg}) 50% 50% no-repeat padding-box;
    background-size: contain;
    min-width: 0.8rem;
    min-height: 0.8rem;
    
    cursor: pointer;

    @media ${device.sm} {
        min-width: 4rem;
        min-height: 4rem;
    }
`
const LoginModal = (props) => {
    const [isPC, setMediaquery] = useState(window.innerWidth < 641 ? false : true)
    const [id, setId] = useState("")
    const [pw, setPw] = useState("")
    const navigate = useNavigate()


    useEffect( ()=> {

        if(window){
            var x = window.matchMedia("(min-width: 641px)")
            x.addEventListener("change", screenChange);
        }

        return () => {
            x.removeEventListener("change", screenChange)
        }
    }, []) 

    const screenChange = (x) => {
        setMediaquery(x.matches)
    }

    const closeModal = () => {
        document.body.style = `overflow: auto;`
        props.toggle(false)
    }
      
    return <>
            <Modal 
                isOpen={props.open} 
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        overflow: 'hidden'
                      },
                      overlay :{
                          zIndex : "999",
                          backgroundColor : "rgba(0,0,0,.5)"
                      }
                }}
                    contentLabel="_modal"
                >
                <ModalBackgroundContainer>
                    <ModalContainer>
                        <Top>
                            <StyledP size={isPC ? "2" : "5.6"} weight={"700"}>
                                로그인
                            </StyledP>
                            {/* <StyledP cursor={"pointer"} size={isPC ? "2" : "5.6"} weight={"700"} onClick={() => {
                                closeModal()
                            }}>
                                X
                            </StyledP> */}

                            <XSymbolSvg onClick={() => {
                                closeModal()
                            }}/>
                        </Top>
                        <Middle>
                                <InputContianer>
                                <PWrapper>
                                    <StyledP size={isPC ? "2" : "5.6"}>
                                        아이디
                                    </StyledP>
                                </PWrapper>
                                    <InputWrapper >
                                        <ModalInput value={id} onChange={({target})=>{setId(target.value)}} id="id_input" type="text" placeholder="" />
                                        <InputLabel for="id_input" />
                                    </InputWrapper>
                                </InputContianer>
                                <InputContianer>
                                    <PWrapper>
                                        <StyledP size={isPC ? "2" : "5.6"}>
                                            비밀번호
                                        </StyledP>
                                    </PWrapper>
                                    <InputWrapper>
                                        <ModalInput value={pw} onChange={({target})=>{setPw(target.value)}} id="pw_input" type="password" placeholder="" />
                                        <InputLabel for="pw_input" />
                                    </InputWrapper>
                                </InputContianer>
                        </Middle>
                        <Bottom>
                            <Cancel onClick={ () => {closeModal()}}>취소</Cancel>
                            <Submit onClick={ async () =>  {

                                const result =  await getAuth(id, pw)
                                
                                if(result){
                                    setLocalStorage(encrypt(true + ""))
                                    closeModal()
                                    window.location.reload()
                                }
                            }}>로그인</Submit>
                        </Bottom>
                    </ModalContainer>
                </ModalBackgroundContainer>
            </Modal>
    </>
}

export default LoginModal
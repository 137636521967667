
// import {getDocs, doc, collection, getDoc, setDoc, query, where, deleteDoc } from 'firebase/firestore/lite'
// import { db } from '../App'
import { encrypt } from '../script/cipher'
import CryptoJS from "crypto-js";
import { commonApi } from '../api/config';

// const toDayDoc = 'todayCount'
// const totalDoc = 'totalCount'
const property = process.env.REACT_APP_PROPERTY


export async function getVisitCount(){
    const dayTimeStamp = new Date()
    dayTimeStamp.setHours(0)
    dayTimeStamp.setMinutes(0)
    dayTimeStamp.setSeconds(0)

    const date = getDate(dayTimeStamp.getTime() + "")
    try{
        const snap = await commonApi.post('/visit/get-day-count', {date : date, agent : window ? window.navigator.userAgent : ""})
        const {detail} = snap.data
    
        if(detail){
            return detail
        } 
    }catch {
        return {
            "today" : 1,
            "total" : 1
        }
    }
}

async function increaseTodayCount(){
    const dayTimeStamp = new Date()
    dayTimeStamp.setHours(0)
    dayTimeStamp.setMinutes(0)
    dayTimeStamp.setSeconds(0)

    const date = getDate(dayTimeStamp.getTime() + "")

    await commonApi.post('/visit/add-day-count', {date : date, agent : window ? window.navigator.userAgent : ""})
}

async function increaseTotalCount(){

    await commonApi.post('/visit/add-total-count', {date : Date.now() + "", agent : window ? window.navigator.userAgent : ""})

    // const totalRef = doc(db, totalDoc, "0")
    // const totalDocSnap = await getDoc(totalRef)

    // if(totalDocSnap.exists){
    //     var count = +totalDocSnap.data().totalCount
    
    //     await setDoc(doc(db, totalDoc, "0"), {
    //          totalCount : ++count  + "",
    //     })
    // }
}

export async function toDayCount(){
    const visit = localStorage.getItem("_jinsil_visit")
    const now = Date.now()

    // 이전에 방문한적이 없는경우
    if(visit === null || visit === undefined){
        // 여기서 방문자 추가, 누적 카운트
        localStorage.setItem("_jinsil_visit", encrypt(now + ""))
        increaseTodayCount()
        increaseTotalCount()

    } else {
        // 이전에 방문한적이 있는경우    
        const beforeVisitEnc = localStorage.getItem("_jinsil_visit")
        const dayTimestamp = dayCountDecrypt(beforeVisitEnc)
        const dayDiff = dayDifference(dayTimestamp)

        // 방문일 차이가 0 이상일 경우에만 토탈, 투데이 카운트
        if(dayDiff){
            localStorage.setItem("_jinsil_visit", encrypt(now + ""))

            increaseTodayCount()
            increaseTotalCount()
        }

    }
}

const dayCountDecrypt = (encrypted) => {
    try {
        const bytes = CryptoJS.AES.decrypt(encrypted, property)
        const origin = bytes.toString(CryptoJS.enc.Utf8)

        return JSON.parse(origin)
    } catch (error) {
        if(!encrypted){
            // const enc = encrypt(new Date(1995, 11, 17).getTime() + "", property)
            const enc = encrypt(false + "", property)
            const bytes = CryptoJS.AES.decrypt(enc, property)
            const origin = bytes.toString(CryptoJS.enc.Utf8)
            return JSON.parse(origin)
        }
    }
}


const getDate = (timestamp) => {
    // const utc = new Date()
    // const offset = utc.getTimezoneOffset()
    // const today = new Date(+timestamp + offset * 60000)
    const today = new Date(+timestamp)
    const year = today.getFullYear() + "";
    const month = (today.getMonth() + 1).toString().padStart(2, '0') + "";
    const day = today.getDate().toString().padStart(2, '0') + ""; 
  
    const dateString = year + month + day;
    return dateString;
}

const dayDifference = (timestamp) => {
    const date1 = new Date(+timestamp)
    const date2 = Date.now()

    var difference = date2 - date1.getTime();

    var daysDifference = Math.floor(difference/1000/60/60/24);
    difference -= daysDifference*1000*60*60*24

    var hoursDifference = Math.floor(difference/1000/60/60);
    difference -= hoursDifference*1000*60*60

    var minutesDifference = Math.floor(difference/1000/60);
    difference -= minutesDifference*1000*60

    if(daysDifference > 0){
        return true
    }
    
    return false
}
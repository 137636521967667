import { BasicFrame, HeaderFrame, MobileCommonFrame, StyledP } from "../components/frame";
import Menu from "../components/menu";
import SubMenu from "../components/subMenu";
import { useState } from "react";
import styled from "styled-components";
import {Column, Spacer, WidthBox, MenuWarpper, MobileBodyFrame, MenuBackEmptySpace } from "./CenterIntro"
// import img_01  from "../assets/image/n_01.png";
// import img_02  from "../assets/image/n_02.png";
// import img_03  from "../assets/image/n_03.png";
// import img_04  from "../assets/image/n_04.png";
// import img_05  from "../assets/image/n_05.png";
// import img_06  from "../assets/image/n_06.png";
import img_08  from "../assets/image/y_01.png";
import img_09  from "../assets/image/y_02.png";
import img_10  from "../assets/image/y_03.png";
import img_11  from "../assets/image/y_04.png";
import service_img from '../assets/image/cs_new.jpg'
import service_01 from '../assets/image/service_01.png'
import service_02 from '../assets/image/service_02.png'
import service_03 from '../assets/image/service_03.png'
import service_04 from '../assets/image/service_04.png'
import service_05 from '../assets/image/service_05.png'
import service_06 from '../assets/image/service_06.png'
import service_07 from '../assets/image/service_07.png'
import service_08 from '../assets/image/service_08.png'
import service_09 from '../assets/image/service_09.png'
import service_10 from '../assets/image/service_10.png'
import service02_img from '../assets/image/cs03_new.jpg'

import {FotterFrame} from "../components/frame";
import { Mobile, PC, Tablet } from "../script/responsive";
import { useMediaQuery } from "react-responsive";

const size = {
    xs: '512px', // for small screen mobile
    sm: '640px', // for mobile screen
    md: '900px', // for tablets
    lg: '1280px', // for laptops
    xl: '1480px', // for desktop / monitors
    xxl: '1920px', // for big screens
}
  
const device = {
    xs: `(max-width: ${size.xs})`,
    sm: `(max-width: ${size.sm})`,
    md: `(max-width: ${size.md})`,
    lg: `(max-width: ${size.lg})`,
    xl: `(max-width: ${size.xl})`,
    xxl: `(max-width: ${size.xxl})`,
}


const subMenuList_v2 = [
    {
        name : "방문간호",
        path : "/service/nurse01",
    },
    {
        name : "방문요양",
        path : "/service/nurse02",
    }
]

const BodyFrame = styled.div`
    width:100%;
    height: 86%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    color: black;
    max-width: 1920px;
    align-self: center;

    ${
        props => props.height && `height: ${props.height};`
    }
    ${
        ({justify}) => justify && `justify-content: ${justify};`
    }
`
// background: transparent url(${service_img}) 50% 50% no-repeat padding-box;

const BackGroundImage06 = styled.div`
    height: 24rem;
    width: 24rem;

    ${
        ({img}) => img && `background: transparent url(${img}) 50% 50% no-repeat padding-box;`
    }

    background-size: cover;
    z-index: 4;
    border-radius: 4% 4%;

    @media ${device.sm} {
        height: 100%;
        width: 100%;
        left: 0%;
        top: 4%;
        background-position: 50% 50%;
        border-radius: 2% 2%;
        position: static;
    }
`

const PcBackGroundImage06 = styled.div`
    height: 100%;
    width: 100%;

    ${
        ({img}) => img && `background: transparent url(${img}) 50% 50% no-repeat padding-box;`
    }

    position: static;
    background-size: contain;
    z-index: 4;

    @media ${device.sm} {
        height: 100%;
        width: 100%;
        left: 0%;
        top: 4%;
        background-position: 50% 50%;
        border-radius: 2% 2%;
    }
`

const BodyBox = styled.div`
    width: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media ${device.sm} {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
`
const ColorBoxWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`

const ColorBox = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    @media ${device.sm} {
        min-height: 20rem;
        width: 100%;
        flex-direction: column;
        justify-content: space-around;
        z-index: 4;
    }
`

const Left = styled.div`
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;

    justify-content: center;
    text-align: left;

    margin: 0 0 0 4%;
    max-width: 850px;
    ${
        props => 
        props.width && `width: ${props.width};`
    }
     
    ${
        props => props.align && `align-items: ${props.align};`
    }

    ${
        props => props.justify && `justify-content: ${props.justify};`
    }
`
const Visit01 = () => {
    return <>
        <PC>
            <Column justify={"center"} minHeight={"20rem"} width={"80%"}>
                <Left width={"100%"} align={"center"}> 
                    <StyledP
                        size={"4"}
                        hexColor={"#6C9EE3"}
                        weight={"700"}
                    >방문간호서비스란</StyledP>
                    <StyledP
                        size={"2"}
                        margin={"1.6rem 0 0 0"}
                    >장기요양등급(1~5등급)을 인정받은 대상자에게</StyledP>
                    <StyledP
                        size={"2"}
                        margin={"0.6rem 0 0 0"}
                    > 의사, 한의사 또는 치과의사의 지시에 따라</StyledP>
                    <StyledP
                        size={"2"}
                        margin={"0.6rem 0 0 0"}
                    >
                        간호사, 간호조무사 또는 치위생사가 가정을 방문하여
                    </StyledP>
                    <StyledP
                         size={"2"}
                         margin={"0.6rem 0 0 0"}
                    >상처관리,재활운동,인지활동 등을 제공하는 전문적인 간호서비스입니다. </StyledP>
                    <StyledP
                         size={"2"}
                         margin={"0.6rem 0 0 0"}
                    >특별히 진실에서는 노인을 더욱 전문적으로 다루어</StyledP>
                    <StyledP
                         size={"2"}
                         margin={"0.6rem 0 0 0"}
                    >노인에게 맞추어진 존엄적인 간호서비스를 제공합니다.</StyledP>
                </Left>
                {/* <Right width={"40%"}>
                </Right> */}
                {/* <CrossBar /> */}
            </Column>
        </PC>
        <Mobile>

        <WidthBox>
            <ColorBoxWrapper>
                <ColorBox>
                <StyledP
                    size={"6"}
                    hexColor={"#6C9EE3"}
                    weight={"700"}
                >
                    방문간호서비스란
                </StyledP>
                    <StyledP
                        size={"4.2"}
                        hexColor={"#000000"}
                    >
                        장기요양등급(1~5등급)을 인정받은 대상자에게
                    </StyledP>
                    <StyledP
                        size={"4.2"}
                        hexColor={"#000000"}
                    >
                        의사, 한의사 또는 치과의사의 지시에 따라
                    </StyledP>
                    <StyledP
                        size={"4.2"}
                        hexColor={"#000000"}
                    >
                        간호사, 간호조무사 또는 치위생사가
                    </StyledP>
                    <StyledP
                        size={"4.2"}
                        hexColor={"#000000"}
                    >
                        가정을 방문하여 상처관리, 재활운동, 인지활동 등을
                    </StyledP>
                    <StyledP
                        size={"4.2"}
                        hexColor={"#000000"}
                    >
                        제공하는 전문적인 간호서비스입니다.
                    </StyledP>
                    <StyledP
                        size={"4.2"}
                        hexColor={"#000000"}
                    >
                        특별히 진실에서는 노인을 더욱 전문적으로 다루어
                    </StyledP>
                    <StyledP
                        size={"4.2"}
                        hexColor={"#000000"}
                    >
                        노인에게 맞추어진 존엄적인 간호서비스를 제공합니다.
                    </StyledP>
                </ColorBox>
            </ColorBoxWrapper>
        </WidthBox>
        </Mobile>
    </>
    
   
}

const Visit02 = () => {
    const BoxWrapper = styled.div`
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: row;

        align-items: center;
        justify-content: center;
        min-height: 400px;

        @media ${device.sm} {
            min-height: 100px;
            height: 80%;
            width: 100%;
            flex-direction: column;
        }
    `
    
    return <>
    <PC>
        <Column justify={"center"} width={"80%"} minHeight={"20rem"}>
            <Left width={"100%"} align={"center"}>
                <StyledP
                    size={"4"}
                    hexColor={"#6C9EE3"}
                    weight={"700"}
                >방문요양서비스</StyledP>
                <StyledP
                    size={"2"}
                    margin={"1.6rem 0 0 0"} 
                >
                    장기요양요원(요양보호사)가 대상자의 가정 등을 방문하여 
                </StyledP>
                <StyledP
                    size={"2"}
                    margin={"0.6rem 0 0 0"} 
                >
                  신체활동 및 가사활동 등을 지원하는 서비스입니다.
                </StyledP>
            </Left>
            {/* <Right width={"40%"}> */}
                {/* <BackGroundImage06  img={service_img} /> */}
            {/* </Right> */}
        </Column>
    </PC>
    <Mobile>
        <WidthBox width={"100%"}>
            <BoxWrapper>
         
                    <StyledP
                        size={"6"}
                        hexColor={"#6C9EE3"}
                        weight={"700"}
                        margin={"4rem 0 2rem 0"}
                    >방문요양서비스란
                    </StyledP>
                <StyledP
                    size={"4.6"}
                    hexColor={"#000000"}
                    margin={"4rem 0 2rem 0"}
                    >
                        장기요양요원(요양보호사)가 대상자의 가정 등을
                    </StyledP>
                    <StyledP
                    size={"4.6"}
                    hexColor={"#000000"}
                    margin={"2rem 0 2rem 0"}
                    >
                         방문하여 신체활동 및 가사활동 등을
                    </StyledP>
                    <StyledP
                    size={"4.6"}
                    hexColor={"#000000"}
                    margin={"2rem 0 2rem 0"}
                    >
                        지원하는 서비스입니다.
                    </StyledP>
            </BoxWrapper>
        </WidthBox>
    </Mobile>
    </>
}

const MobileVisit01ImageWrapper = styled.div`
    width: 100%;
    height: 100%;

    position: relative;
`

const MobileVisit02ImageWrapper = styled.div`
    width: 100%;
    height: 100%;

    position: relative;
`

const PcCommonFrame = styled.div`
    background-color: white;
    width: 100%;
    max-width:1920px;
    color : black;
    display : flex;
    justify-content: space-around;
    align-items: center;
    
    ${
        props => props.vh && "height: " + props.vh + "vh;"
    }
    ${
        props => props.px && "height: " + props.px + "px;"
    }

    ${
        props => props.align && "flex-direction: column;"
    }

    @media ${device.xxl} {
        width: 80%;
    }
    
`



const ServiceContent = (props) => {
    const ServiceBoxWrapper = styled.div`
        width: calc(100vw - 10vw);
        height: 28vh;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 4rem 0 4rem 0;
    `
    
    const ServiceBox = styled.div`
        width: 100%;
        height: 100%;
        border: #000000 1px solid;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    `

    const TextBox = styled.div`
        width: 80%;
        height: 60%;

        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
    `

    const CircleBox = styled.div`
        width: 100%;
        height: 12%;
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;
    `
    // background: transparent url(${img_05}) 50% 50% no-repeat padding-box;

    const Circle = styled.div`
        min-width: 18vw;
        min-height: 18vw;
        
        background: #6C9EE3;
        background-size: contain;

        border-radius: 50%;
        margin-top: 2rem;

        color: FFFFFF;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 6rem;
    `
    const CardNameWrapper = styled.div`
        
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1rem;
        flex-direction: column;
        width: 100%;
    `
    const CardContentWrapper = styled.div`
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 20px;
    `
    return <>
        <ServiceBoxWrapper>
            <CircleBox>
                <Circle>
                    <StyledP
                        size={"6.4"}
                        hexColor={"#FFFFFF"}
                        weight={"700"}
                    >
                        {props.number}
                    </StyledP>
                </Circle>
            </CircleBox>
            <ServiceBox>
                <TextBox>
                {
                    typeof props.name === "string" ?   <StyledP
                    size={"5.2"}
                    weight={"700"}
                    borderBottom={"#000000 1px solid"}
                    width={"100%"}
                    align={"center"}
                    padding={"0 0 4rem 0"}
                    margin={"2rem 0 0 0"}
                >
                    {props.name}
                </StyledP> :   
                <CardNameWrapper>
                    {
                        props.name.map((el, index) => {

                        return props.name.length -1 === index ?       <StyledP
                                size={"5.2"}
                                weight={"700"}
                                borderBottom={"#000000 1px solid"}
                                width={"100%"}
                                align={"center"}
                                padding={"0 0 4rem 0"}
                                margin={"2rem 0 0 0"}
                            >
                                {el}
                            </StyledP>  :       <StyledP
                            size={"5.2"}
                            weight={"700"}
                            width={"100%"}
                            align={"center"}
                            padding={"0 0 4rem 0"}
                            margin={"2rem 0 0 0"}
                        >
                            {el}
                        </StyledP>
                        }
                        )
                    }
                       
                </CardNameWrapper>
            
                }
              
                <CardContentWrapper>
                    <StyledP
                        size={"4.2"}
                        hexColor={"#000000"}
                        width={"100%"}
                        align={"center"}
                        margin={"2.4rem 0 0 0"}
                        >
                        {props.content}
                    </StyledP>
                    </CardContentWrapper>
                </TextBox>
            </ServiceBox>
        </ServiceBoxWrapper>
    </>
}

const ServiceCard = (props) => {
    const CardImageWrapper = styled.div`
        height: 100%;
        display:flex;
        flex-direction: column;
        justify-content: space-around;
        text-align: center;
        word-break: keep-all;

        border: 0.05rem solid #838383;
        margin-bottom: 1rem;
        margin-left: 1rem;
        margin-right: 1rem;

        @media ${device.sm} {
            width: 100%;
        }
        
        > * {
            &:not(:first-child){
                text-align: center;
            }
        }

        padding: 0 0.4rem;

        min-width: 12rem;
        width: 18rem;
        min-height: 12rem;
        @media ${device.lg} {
            height: 80%;
        }

        @media ${device.md} {
            height: 70%;
        }
    `
    const CardBackGroundImage = styled.div`
        height: 14em;
        width: 14em;
        background: transparent url(${props.img}) 50% 50% no-repeat padding-box;
        background-size: contain;

        @media ${device.sm} {
                height: 100%;
                background: transparent url(${props.img}) 0% 0% no-repeat padding-box;
                width: 100%;
                background-position: 50% 50%;
        }

        border-radius:50%;
        align-self: center;
    `


    const CardTileWrapper = styled.div`
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    `

    const CardTitle = styled.div`
            width: 100%;
            display: flex;
            word-break: keep-all;

            flex-direction: column;
            align-items: center;
            min-height: 4rem;
            max-height: 4rem;

            border-bottom: 0.1rem solid #838383;

            justify-content: center;
        @media ${device.md} {
            min-height: 6rem;
        }
    `   


    const CardContentWrapper = styled.div`
        min-height: 8rem;
        display:flex;
        flex-direction: column;
        justify-content: space-around;
        text-align: center;
    `


    return <>
            <CardImageWrapper>
                <CardBackGroundImage />
                <CardTileWrapper>
                    {
                        props.title && 
                            (props.title.length === 1 ? <>
                                <CardTitle>

                                <StyledP
                                    size={"2.0"}
                                    hexColor={"#000000"}
                                    margin={"1rem 0 0 0"}
                                    weight={"700"}
                                    padding={"0 0 1rem 0"}
                                    width={"100%"}
                                    align={"center"}
                                    >{props.title}
                                </StyledP>
                                    </CardTitle>
                            </> : 
                             <CardTitle>
                                {
                                    props.title.map( (el, index) => {
                                    return <>
                                        { index === props.title.length -1 ? <StyledP key={index}
                                            size={"2.0"}
                                            hexColor={"#000000"}
                                            margin={"0.4rem 0 0 0"}
                                            weight={"700"}
                                            padding={"0 0 1rem 0"}
                                            width={"80%"}
                                            align={"center"}
                                        >{el}
                                        </StyledP> :<StyledP key={index}
                                                size={"2.0"}
                                                hexColor={"#000000"}
                                                margin={"0.2rem 0 0 0"}
                                                weight={"700"}
                                                width={"80%"}
                                                align={"center"}
                                            >{el}
                                        </StyledP>}
                                    </>
                                })
                            }
                            </CardTitle>)
                    }
                </CardTileWrapper>
                <CardContentWrapper>
                {
                    props.content && 
                        (
                            props.content.map( (el, index) => (
                                index === 0 ? <StyledP key={index}
                                    size={"1.6"}
                                                margin={"0.4rem 0 0 0"}
                                                hexColor={"#000000"}
                                >{el}</StyledP>
                                    :
                                <StyledP key={index}
                                size={"1.6"}
                                margin={"0.4rem 0 0 0"}
                                hexColor={"#000000"}
                                >{el}</StyledP>
                            ))
                        )
                }
                </CardContentWrapper>
            </CardImageWrapper>
</>
}


const ServiceCardV2 = (props) => {
    const CardImageWrapper = styled.div`
        height: 100%;
        display:flex;
        flex-direction: column;
        justify-content: space-around;
        text-align: center;
        word-break: keep-all;

        border: 0.05rem solid #838383;
        margin-bottom: 1rem;
        margin-left: 1rem;
        margin-right: 1rem;

        @media ${device.sm} {
            width: 100%;
        }
        
        > * {
            &:not(:first-child){
                text-align: center;
            }
        }

        padding: 0 0.4rem;

        min-width: 12rem;
        width: 18rem;
        min-height: 12rem;
        
        @media ${device.xl} {
            height: 90%;
        }
        @media ${device.lg} {
            height: 80%;
        }
        @media ${device.md} {
            height: 70%;
            width: 24rem;
        }
    `

    const CardBackGroundImage = styled.div`
        height: 100%;
        width: 100%;
        background: transparent url(${props.img}) 50% 50% no-repeat padding-box;
        background-size: contain;

        @media ${device.sm} {
                height: 100%;
                background: transparent url(${props.img}) 0% 0% no-repeat padding-box;
                width: 100%;
                background-position: 50% 50%;
        }

        align-self: center;
    `

    

    return <>
        <CardImageWrapper>
            <CardBackGroundImage />
        </CardImageWrapper>
</>
}

const VhContainer = styled.div`
    width: 100%;
    min-height: 500px;
`

const Service = (props) => {
    const [menuState, setMenuState] = useState(props.menuIndex ?? 0)
    const isTablet = useMediaQuery({maxWidth : 1280})
   
    return (
        <BasicFrame>
            <PC>
                <Menu/>
                <SubMenu menuState={menuState} setMenuState={setMenuState} subMenuList={subMenuList_v2} />
            
                <HeaderFrame state={"2" + menuState}>
                    <MenuBackEmptySpace></MenuBackEmptySpace>       
                    <BodyFrame height={"100%"} justify={"flex-start"}>
                        {/* <Column /> */}

                        <VhContainer>
                        {
                            menuState === 0 && <PcBackGroundImage06  img={service02_img} />
                        }
                        {
                            menuState === 1 && <PcBackGroundImage06  img={service_img} />
                        }
                        </VhContainer>

                    {
                        menuState === 0 && <>
                            <Visit01/>
                            <BodyBox>
                            <PcCommonFrame vh={"6"} align={" "} />
                            <PcCommonFrame vh={"6"} align={" "} />

                            <PcCommonFrame px={"100"} align={" "}>
                                <StyledP
                                    size={"4.0"}
                                    hexColor={"#6C9EE3"}
                                    weight={"700"}
                                >서비스 안내</StyledP>  
                                <StyledP
                                    size={"2.2"}
                                    hexColor={"#000000"}
                                    margin={"1rem 0 0 0"}
                                >
                                진실에서는 건강기능을 유지하고 합병증을 예방하도록 돕습니다.
                                </StyledP>
                                </PcCommonFrame>
                        </BodyBox>
                        <BodyBox >
                            {/* <BodyWrapper> */}
                            <PcCommonFrame vh={"6"} align={" "} />
                            {
                                isTablet && <>
                                    <PcCommonFrame px={"600"} > 
                                        <ServiceCardV2 img={service_01} />
                                        <ServiceCardV2 img={service_02} />
                                    </PcCommonFrame>
                                    <PcCommonFrame vh={"2"} align={" "} />
                                    <PcCommonFrame px={"600"} > 
                                        <ServiceCardV2 img={service_03} />
                                        <ServiceCardV2 img={service_04} />
                                    </PcCommonFrame>
                                    <PcCommonFrame vh={"2"} align={" "} />
                                    <PcCommonFrame px={"600"} >
                                        <ServiceCardV2 img={service_05} />
                                        <ServiceCardV2 img={service_06} />
                                    </PcCommonFrame>
                                </>
                            }
                            
                            {
                                !isTablet && <>
                                    <PcCommonFrame px={"600"} > 
                                        <ServiceCardV2 img={service_01} />
                                        <ServiceCardV2 img={service_02} />
                                        <ServiceCardV2 img={service_03} />
                                        
                                            {/* 
                                                <ServiceCard img={img_01} title={["혈압,당뇨관리"]} content={[
                                                        "진실에서는 심뇌혈관질환의 주요 원인인 혈압과 ",
                                                        "당뇨수치를 체계적으로 관리하여 ",
                                                        "건강기능을 유지하고 합병증을",
                                                        "예방하도록 돕습니다.",
                                                ]} />

                                            <ServiceCard img={img_02} title={["상처관리", "(욕창)"]} content={[
                                                        "",
                                                        "진실에서는 멸균법을 유지하여 상처관리를 제공하여",
                                                        "매번 병원을 방문해야 하는 번거로움을 덜어드립니다.",
                                                        "",
                                                    ]} />
                                            <ServiceCard img={img_03} title={["L-tube(콧줄)", "foley catheter(소변줄)", "T-tube(기관지관)관리"]} content={[
                                                "",
                                                "콧줄과 소변줄, 목관 등을 위생적으로 관리하여",
                                                "대상자의 건강기능이 유지될수 있도록 돕습니다. ",
                                                "",
                                            ]} /> */}
                                        
                                    </PcCommonFrame>
                                    <PcCommonFrame vh={"6"} align={" "} />
                                    <PcCommonFrame px={"600"} >
                                        <ServiceCardV2 img={service_04} />
                                        <ServiceCardV2 img={service_05} />
                                        <ServiceCardV2 img={service_06} />
                                    
                                            {/* <ServiceCard img={img_04} title={["방문재활", "(관절가동범위 운동)"]} content={[
                                                    "대부분의 시간을 침상안정으로 보내는 분들을 위하여",
                                                    "저희 진실에서는 근력감소 및 관절구축을",
                                                    "예방하기 위한 재활간호서비스를 제공합니다.",
                                                    "",
                                            ]} /> */}

                                            {/* <ServiceCard img={img_05} title={["통증관리"]} content={[
                                                    "",
                                                    "온요법,냉요법,약물조절 등을 통해 ",
                                                    "통증을 조절하고 관리합니다.",
                                                    "",
                                            ]} />

                                            <ServiceCard img={img_06} title={["배설관리"]} content={[
                                                    "",
                                                    "약물조절, 복부마사지, 식이관리 등을 제공하여",
                                                    "배뇨,배변의 문제를 조절하고 관리합니다.",
                                                    "",
                                            ]} /> */}
                                    </PcCommonFrame>
                                </>
                            }
                            {/* <BodyBackground> */}
                         

                        </BodyBox>

                        </>
                    }
                    {
                        menuState === 1 && <>
                            <Visit02/>
                            <BodyBox>
                            <PcCommonFrame vh={"6"} align={" "} />
                            <PcCommonFrame vh={"6"} align={" "} />
                                <PcCommonFrame px={"100"} align={" "}>
                                    <StyledP
                                        size={"4.0"}
                                        hexColor={"#6C9EE3"}
                                        weight={"700"}
                                    >서비스 안내</StyledP>  
                                            <StyledP
                                                size={"2.2"}
                                                hexColor={"#000000"}
                                                margin={"1rem 0 0 0"}
                                            >
                                            진실에서는 일반적인 요양서비스 이외에 대상자 맞춤형 요양서비스를 제공합니다.
                                            </StyledP>
                                    </PcCommonFrame>
                            </BodyBox>
                            <BodyBox >
                                <PcCommonFrame vh={"6"} align={" "}/>
                                
                                
                                {/* <BodyBackground> */}
                                <PcCommonFrame px={"600"} > 
                                    <ServiceCardV2 img={service_07} />
                                    <ServiceCardV2 img={service_08} />
                                    {/* <ServiceCard img={img_08} title={["간호요양서비스"]} content={[
                                                "건강관리가 필요한 대상자를 위해",
                                                "간호사가 환자의 상태를 감시하고 의료적 지원을",
                                                "제공하는 서비스로 상태 모니터링, 상담",
                                                "간호상담 등이 이에 포함됩니다.",
                                        ]} />
                                    <ServiceCard img={img_09} title={["보조요양서비스"]} content={[
                                            "환자의 일상생활에서 도움이 필요한 경우",
                                            "제공되는 서비스로 식사, 목욕, 화장실 지원",
                                            "신체 운동 지원 등이 해당됩니다.",
                                    ]} /> */}
                                </PcCommonFrame>
                                <PcCommonFrame vh={"6"} align={" "}/>
                                <PcCommonFrame px={"600"} > 
                                    <ServiceCardV2 img={service_09} />
                                    <ServiceCardV2 img={service_10} />
                                    {/* <ServiceCard img={img_10} title={["재활요양서비스"]} content={[
                                        "오랜시간 침상안정을 유지하고 있는",
                                        "대상자를 위해 대상자의 관절운동, 아로마요법 등을",
                                        "제공하여 대상자의 안위를 증진시키는 서비스입니다.",
                                    ]} />
                                    <ServiceCard img={img_11} title={["인지학습서비스"]} content={[
                                            "심리치료사나 사회복지사가 환자의 정서적",
                                            "사회적 지원을 제공하는 서비스로 심리 상담",
                                            "사회적 지원, 가족 지원 등이 이에 해당됩니다.",
                                    ]} /> */}
                                </PcCommonFrame>
                        
                            </BodyBox>
                        </>
                    }
                    <Spacer></Spacer>
                </BodyFrame>
            </HeaderFrame>
            </PC>
            <Mobile>
                <MenuWarpper >
                    <Menu/>
                    <SubMenu menuState={menuState} 
                    setMenuState={setMenuState} subMenuList={subMenuList_v2} />
                </MenuWarpper>
                <HeaderFrame vh={"34"}>
                {/* <Spacer></Spacer> */}
                    {
                        menuState === 0 && <>
                                <MobileVisit01ImageWrapper>
                                    <BackGroundImage06 img={service02_img}></BackGroundImage06>
                                </MobileVisit01ImageWrapper>
                        </>
                    }
                    {
                        menuState === 1 && <>
                                <MobileVisit02ImageWrapper>
                                    <BackGroundImage06 img={service_img}></BackGroundImage06>
                                </MobileVisit02ImageWrapper>
                        </>
                    }
                    <Spacer></Spacer>
                </HeaderFrame>
                {
                    menuState === 0 && <>
                    <MobileCommonFrame vh={"30"} align={" "}>
                            <Visit01/>
                    </MobileCommonFrame>
                    </>
                }
                    <MobileBodyFrame>
                    {
                        menuState === 0 && <>
                            <MobileCommonFrame vh={"6"} />
                            {/* <BodyWrapper> */}
                         
                            <MobileCommonFrame vh={"6"} align={" "}>
                                    <StyledP
                                        size={"6"}
                                        hexColor={"#6C9EE3"}
                                        weight={"700"}
                                    >서비스 안내
                                    </StyledP>
                            </MobileCommonFrame>
                            <MobileCommonFrame vh={"6"} />
                            <MobileCommonFrame vh={"30"} align={" "}>
                                <ServiceContent name={"혈압,당뇨관리"} number={"1"} content={"진실에서는 건강기능을 유지하고 합병증을 예방하도록 돕습니다."}/>
                            </MobileCommonFrame>
                            <MobileCommonFrame vh={"6"} />
                            <MobileCommonFrame vh={"30"} align={" "}>
                                <ServiceContent name={"상처관리(욕창)"} number={"2"} content={"진실에서는 멸균법을 유지하여 상처관리를 제공하여 매번 병원을 방문해야 하는 번거로움을 덜어드립니다."}/>
                            </MobileCommonFrame>
                            <MobileCommonFrame vh={"6"} />
                            <MobileCommonFrame vh={"30"} align={" "}>
                                <ServiceContent name={["L-tube(콧줄)", "foley catheter(소변줄)", "T-tube(기관지관)관리"]} number={"3"} content={"콧줄과 소변줄, 목관 등을 위생적으로 관리하여 대상자의 건강기능이 유지될수 있도록 돕습니다."}/>
                            </MobileCommonFrame>
                            <MobileCommonFrame vh={"6"} />
                            <MobileCommonFrame vh={"30"} align={" "}>
                                <ServiceContent name={"방문재활(관절가동범위 운동)"} number={"4"} content={"대부분의 시간을 침상안정으로 보내는 분들을 위하여 저희 진실에서는 근력감소 및 관절구축을 예방하기 위한 재활간호서비스를 제공합니다. "}/>
                            </MobileCommonFrame>
                            <MobileCommonFrame vh={"6"} />
                            <MobileCommonFrame vh={"30"} align={" "}>
                                <ServiceContent name={"통증관리"} number={"5"} content={"온요법,냉요법,약물조절 등을 통해 통증을 조절하고 관리합니다."}/>
                            </MobileCommonFrame>
                            <MobileCommonFrame vh={"6"} />
                            <MobileCommonFrame vh={"30"} align={" "}>
                                <ServiceContent name={"배설관리"} number={"6"} content={"약물조절, 복부마사지, 식이관리 등을 제공하여 배뇨,배변의 문제를 조절하고 관리합니다."}/>
                            </MobileCommonFrame>
                        </>
                    }
                    {
                        menuState === 1 && <>
                            <MobileCommonFrame vh={"30"} align={" "}>
                                <Visit02/>
                            </MobileCommonFrame>
                        
                            <MobileCommonFrame vh={"6"} align={" "}>
                                <StyledP
                                    size={"6"}
                                    hexColor={"#6C9EE3"}
                                    weight={"700"}
                                >서비스 안내
                                </StyledP>
                            </MobileCommonFrame>
                            <MobileCommonFrame vh={"6"} />
                          
                            <MobileCommonFrame vh={"30"} align={" "}>
                                <ServiceContent name={"간호요양서비스"} number={"1"} content={"건강관리가 필요한 대상자를 위해 간호사가 환자의 상태를 감시하고 의료적 지원을 제공하는 서비스로 상태 모니터링, 상담, 간호상담 등이 이에 포함됩니다"}/>
                            </MobileCommonFrame>
                            <MobileCommonFrame vh={"6"} />

                            <MobileCommonFrame vh={"30"} align={" "}>
                                <ServiceContent name={"보조요양서비스"} number={"2"} content={"환자의 일상생활에서 도움이 필요한 경우 제공되는 서비스로 식사, 목욕, 화장실 지원, 신체 운동 지원 등이 해당됩니다."}/>
                            </MobileCommonFrame>
                            <MobileCommonFrame vh={"6"} />

                            <MobileCommonFrame vh={"30"} align={" "}>
                                <ServiceContent name={"재활요양서비스"} number={"3"} content={"오랜시간 침상안정을 유지하고 있는 대상자를 위해 대상자의 관절운동, 아로마요법 등을 제공하여 대상자의 안위를 증진시키는 서비스입니다."}/>
                            </MobileCommonFrame>
                            <MobileCommonFrame vh={"6"} />

                            <MobileCommonFrame vh={"30"} align={" "}>
                                <ServiceContent name={"인지학습서비스"} number={"4"} content={"심리치료사나 사회복지사가 환자의 정서적, 사회적 지원을 제공하는 서비스로 심리 상담, 사회적 지원, 가족 지원 등이 이에 해당됩니다."}/>
                            </MobileCommonFrame>
                        </>
                    }
                        <Spacer></Spacer>
                    </MobileBodyFrame>
            </Mobile>
            <FotterFrame></FotterFrame>
        </BasicFrame>
    )
}

export default Service;
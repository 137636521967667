import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import logo  from "../assets/image/logo_004.png";

const size = {
    xs: '512px', // for small screen mobile
    sm: '640px', // for mobile screen
    md: '900px', // for tablets
    lg: '1280px', // for laptops
    xl: '1480px', // for desktop / monitors
    xxl: '1920px', // for big screens
}
  
const device = {
    xs: `(max-width: ${size.xs})`,
    sm: `(max-width: ${size.sm})`,
    md: `(max-width: ${size.md})`,
    lg: `(max-width: ${size.lg})`,
    xl: `(max-width: ${size.xl})`,
    xxl: `(max-width: ${size.xxl})`,
}

const LogoBox = styled.img`
    width: 4.4em;

    &:hover {
        cursor: pointer;
    }

    @media ${device.sm} {
        width: 5em;
    }
`

const LogoWrapper = styled.div`
    width: 3em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:hover {
        cursor: pointer;
    }

    @media ${device.sm} {
        width: 4em;
        font-size: 3.4rem;
    }
    ${
        props => {
            if(props.active === "false"){
                return "color: black;"
            }
        }
    }
`

const Logo = (props) => {
    const navigate = useNavigate()
    
    const moveToIndex = () => {
        // console.log("moveToIndex" + props.main)

        return navigate("/")
    }

    return <LogoWrapper active={props.main + ""} onClick={moveToIndex}>
        <LogoBox src={logo} onClick={moveToIndex}>
        
         </LogoBox>
    </LogoWrapper>
}

export default Logo;
import React, { useEffect, useState } from "react"
import Modal from "react-modal"
import styled from "styled-components";
import { StyledP } from "./frame";
import { dropDoc, setNotice } from "../firebase/noticeFirestore";
import { XSymbolSvg } from "./loginModal";

const size = {
    xs: '512px', // for small screen mobile
    sm: '640px', // for mobile screen
    md: '900px', // for tablets
    lg: '1280px', // for laptops
    xl: '1480px', // for desktop / monitors
    xxl: '1920px', // for big screens
}
  
const device = {
    xs: `(max-width: ${size.xs})`,
    sm: `(max-width: ${size.sm})`,
    md: `(max-width: ${size.md})`,
    lg: `(max-width: ${size.lg})`,
    xl: `(max-width: ${size.xl})`,
    xxl: `(max-width: ${size.xxl})`,
}
  

const ModalBackgroundContainer = styled.div`
    min-width: 300px;
    height: 30vh;

    display: flex;
    flex-diraction: column;
    justify-content: center;
    align-items: center;

    @media ${device.sm} {
        min-width: calc(100vw - 20vw);
    }
`
Modal.setAppElement('#root');

const ModalContainer = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`

const Top = styled.div`
    word-break: keep-all;
    word-wrap: break-word;
    display: block;
    padding: 1rem 1rem;
    font-weight: 500;
    text-align: left;

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    
    border-bottom: 0.1rem solid black;
    
    @media ${device.sm} {
        padding: 4rem 2rem;
        border-bottom: 0.6rem solid #ddd;
    }
`
 
const Middle = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`

const Bottom = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
`

const Cancel = styled.button`
    display: inline-block;
    vertical-align: top;
    font-weight: 500;
    font-size: 0.8rem;
    background-color: #FFFFFF;
    line-height: 30px;
    min-width: 3.4rem;
    height: 2rem;
    padding: 0 22px;
    border: 0.1rem solid #ddd;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;

    @media ${device.sm} {
        font-size: 5rem;
        height: 12rem;
        border: 0.6rem solid #ddd;
    }
`

const Submit = styled.button`
    display: inline-block;
    vertical-align: top;
    font-weight: 500;
    font-size: 0.8rem;
    background-color: #fff;
    line-height: 34px;
    min-width: 3.4rem;
    height: 2rem;
    padding: 0 22px;
    border-radius: 4px;
    border: 0.1rem solid #ddd;
    text-align: center;
    margin-left: 0.4rem;
    cursor: pointer;

    @media ${device.sm} {
        font-size: 5rem;
        height: 12rem;
        border: 0.6rem solid #ddd;
        margin-left: 1.4rem;
    }
`

const ConfirmModal = (props) => {
    
    // PC인지 아닌지
    const [isPC, setMediaquery] = useState(window.innerWidth < 641 ? false : true)

    useEffect( ()=> {

        if(window){
            var x = window.matchMedia("(min-width: 641px)")
            x.addEventListener("change", screenChange);
        }

        return () => {
            x.removeEventListener("change", screenChange)
        }
    }, []) 

    const screenChange = (x) => {
        setMediaquery(x.matches)
    }

    const closeModal = () => {
        document.body.style = `overflow: auto;`
        props.toggle(false)
    }
      
    return <>
            <Modal 
                isOpen={props.open} 
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        overflow: 'hidden'
                      },
                      overlay :{
                          zIndex : "999",
                          backgroundColor : "rgba(0,0,0,.5)"
                      }
                }}
                    contentLabel="_modal"
                >
                <ModalBackgroundContainer>
                    <ModalContainer>
                        <Top>
                            <StyledP size={isPC ? "2" : "5.6"} weight={"700"}>
                                확인
                            </StyledP>
                            {/* <StyledP cursor={"pointer"} size={isPC ? "2" : "5.6"} weight={"700"} onClick={() => {
                                closeModal()
                            }}>
                                X
                            </StyledP> */}
                            <XSymbolSvg onClick={() => {
                                closeModal()
                            }}/>
                        </Top>
                        <Middle>
                               {props.state.index}번 공지사항 개시/숨김할까요?
                        </Middle>
                        <Bottom>
                            <Cancel onClick={ () => {closeModal()}}>취소</Cancel>
                            <Submit onClick={ () => {
                                setNotice(props.state.title, props.state.content, props.state.index, props.state.isShow === "false" ? "true" : "false")
                                // dropDoc(props.state.index)
                                closeModal()
                                window.location.reload()
                            }}>확인</Submit>
                        </Bottom>
                    </ModalContainer>
                </ModalBackgroundContainer>
            </Modal>
    </>
}

export default ConfirmModal
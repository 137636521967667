import { useMemo, useState } from "react";
import { BasicFrame, BackGroundImage, HeaderFrame, FloatingActionButton, FotterFrame, LogoutActionButton } from "../components/frame";
import Menu from "../components/menu";
import {Column, TitleBox, MenuTitle, SubTitle, Spacer, MenuWarpper } from "./CenterIntro"
import styled from "styled-components";
import { Mobile, PC } from "../script/responsive";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getNotice, } from "../firebase/noticeFirestore";
import { decrypt, getLocalStorage, } from "../script/cipher";
import delete_svg  from "../assets/image/delete.svg";
import ConfirmModal from "../components/deleteModal";
import show_svg from '../assets/image/show_svg.svg';
import hide_svg from '../assets/image/hide_svg.svg';
import { useDispatch, useSelector } from "react-redux";
import { replaceNoticeList } from "../reducers/NoticeReducer";
import { getVisitCount } from "../firebase/visitFireStore";

const size = {
    xs: '512px', // for small screen mobile
    sm: '640px', // for mobile screen
    md: '900px', // for tablets
    lg: '1280px', // for laptops
    xl: '1480px', // for desktop / monitors
    xxl: '1920px', // for big screens
  }
  
  const device = {
    xs: `(max-width: ${size.xs})`,
    sm: `(max-width: ${size.sm})`,
    md: `(max-width: ${size.md})`,
    lg: `(max-width: ${size.lg})`,
    xl: `(max-width: ${size.xl})`,
    xxl: `(max-width: ${size.xxl})`,
  }
  
const BodyFrame = styled.div`
    width:100%;
    height: 86%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    color: black;
    max-width: 1920px;
    align-self: center;
`


const BlockWrapper = styled.div`
    display: block;

    height: 100%;
    display:flex;
    justify-content: center;
`

const TableWrapper = styled.div`
    width: 90%;
    min-height: 650px;

    margin-left: 4rem;
    margin-right: 4rem;

    @media ${device.sm} {
        height: 100%;
        min-height: 450px;
    }

    @media ${device.xxl} {
        min-height: 500px;
    }

    @media ${device.xl} {
        min-height: 400px;
    }
    
    @media ${device.lg} {
        min-height: 300px;
    }
`

const NoticeTable = styled.table`
    width: 100%;
    table-layout: fixed;
`

const NoticeHead = styled.thead`
    border-top: 1px solid black;
    background-color: #E2E2E2;
    border-bottom: 0.05rem solid #ddd;

    @media ${device.sm} {

    }
`

const NotiveBody = styled.tbody`
    
`

const Tr = styled.tr`
    color: black;
    height: 3rem;
    @media ${device.sm} {
        height: 8rem;
    }
`

const Td = styled.td`
    color: black;
    word-break: keep-all;
    font-size: 1rem;
    text-align: center;
    line-height: 3rem;
    ${
        props => props.width && `width: ${props.width};`
    }

    @media ${device.sm} {
        line-height: 30px;
        font-size: 4.6rem;
    }
`

const ContentTr = styled.tr`
    line-height: 3rem;
    border-bottom: 0.05rem solid #ddd;
            
    @media ${device.sm} {
        line-height: 34px;
    }
`

const ContentTd = styled.td`
    color: black;
    word-break: keep-all;
    text-align: center;
    font-size: 1em;
    text-overflow: ellipsis;
    ${
        props => props.title && "cursor: pointer;"
    }

    @media ${device.sm} {
        font-size: 4.2rem;
    }
`


const BackGroundDeleteSvg = styled.div`
    height: 100%;
    background: transparent url(${delete_svg}) 50% 50% no-repeat padding-box;
    background-size: contain;
    min-width: 1.2rem;
    min-height: 1.2rem;
    
    cursor: pointer;

    @media ${device.sm} {
        min-width: 14rem;
        min-height: 14rem;
    }
`

const SvgIcon = styled.div`
    height: 100%;
    ${
        ({svg}) => svg && `background: transparent url(${svg}) 50% 50% no-repeat padding-box;`
    }
    background-size: contain;
    min-width: 1.2rem;
    min-height: 1.2rem;

    cursor: pointer;

    @media ${device.sm} {
        min-width: 14rem;
        min-height: 14rem;
    }
`

const Content = (props) => {
    const navigate = useNavigate()
    const [isOpen, toggle] = useState(false)
    const openModal = () => {
        toggle(!isOpen)
        document.body.style = 'overflow: hidden;'
    }
    return <>
        <ContentTr>
                <ContentTd>
                    {props.index} 
                </ContentTd>
                <ContentTd title onClick={
                    () => {
                        navigate(`/announce/${props.index}`, {
                            state : props,
                        })
                    }
                }>
                    {props.title}
                </ContentTd>
                <ContentTd>
                    {props.writer}
                </ContentTd>
                <ContentTd>
                    {props.date}
                </ContentTd>
                {
                    props.isAdmin && <>
                        <ContentTd>
                            {
                                props.isShow === "true" ? <>   
                                    <SvgIcon svg={show_svg} onClick={
                                        () => {
                                            openModal()
                                        }}
                                    />
                                </> : 
                                <>
                                    <SvgIcon svg={hide_svg} onClick={
                                        () => {
                                            openModal()
                                        }} 
                                    />
                                </>
                            }
                            
                            {/* <BackGroundDeleteSvg onClick={
                                () => {
                                    openModal()
                                }
                            } /> */}
                        </ContentTd>
                    </>
                }
        </ContentTr>
        <ConfirmModal state={props} open={isOpen} toggle={toggle}/>
    </>
}

function getDate(timestamp) {
    const utc = new Date()
    const offset = utc.getTimezoneOffset()
    const today = new Date(+timestamp + offset * 60000)

    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
  
    const dateString = year + '-' + month + '-' + day;
    return dateString;
}

const PageNumberWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
`
const PageUl = styled.ul`
    list-style: none;
    border-radius: 3px;
    color: #000;
    padding: 0.4rem;
    height: 30%;

    width: 40px;
    height: 40px;
    
    @media ${device.sm} {
        height: 80%;
        width: 30px;
        height: 30px;
        max-height: 30px;
    }
`

const PageLi = styled.li`
    display: flex;
    justify-content: center;
    align-items: center;
    
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    height: 100%;
    width: 100%;
    font-size: 1rem;

    @media ${device.sm} {
        font-size: 5rem;
    }

    &:hover {
        background: #1C1C1C;
        color: white;
        transition: 0.5s;
    }

    ${
        props => props.active && `background: #1C1C1C; color: white;`
    }
`

const StyledOption = styled.option`
    margin-top: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid black;
    font-size: 4rem;
`

const SelectWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    > * {
        &:nth-child(1) {
            min-width: 50px;
            max-width: 50px;
        }
    }
`

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 1rem 0;
`

const Button = styled.button`
    display: inline-block;
    vertical-align: top;
    font-weight: 500;
    font-size: 0.8rem;
    background-color: #FFFFFF;
    line-height: 30px;
    min-width: 3.4rem;
    height: 2rem;
    padding: 0 22px;
    border: 0.1rem solid #ddd;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;

    @media ${device.sm} {
        font-size: 5rem;
        height: 12rem;
        border: 0.6rem solid #ddd;
    }
`

const DayCountWrapper = styled.div`
    margin-right: 2rem;
`

const DayCountSpan = styled.span`
    display: flex;
`

const Announcement = () => {
    // const [active, setActive] = useState(false)
    const navigate = useNavigate()
    const [pageLength, setPageLength] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostPerpage] = useState(7);

    const [currentPost, setCurrentPost] = useState([]);
    const lastIndex = currentPage * postsPerPage;
    const firstIndex = lastIndex - postsPerPage;

    const pageNumbers = [];
    const [noticeList, setNoticeList] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    
    const [toDayCount, setTodayCount] = useState("0")
    const [totalDayCount, setTotalDayCount] = useState("0")

    useEffect( () => {
        // setIsAdmin(timeDifference(decrypt(getLocalStorage())))
        setIsAdmin(decrypt(getLocalStorage()))
    },[])

    useEffect(() => {
        getDayCount()
    }, [])

    const getDayCount = async () => {
        const visit = await getVisitCount()
        setTodayCount(visit.today)
        setTotalDayCount(visit.total)
    }

    // async function fetchData() {
    //    const notices = await getNotice();
    //    for (let index = notices.length; index > 1; index--) {
    //        const element = notices[index - 1];
    //        setNoticeList((prev) => [ ...prev, element])
    //    }
    //  }

    // useEffect( () => {
    //       fetchData()
    // }, [])

    const dispatch = useDispatch()
    const noticeState = useSelector(state => state.notice)

    const fetchDateV2 = async () => {
        const notice = await getNotice()
        dispatch(replaceNoticeList(notice))
    }

    const generateUserDate = () => {
        const filtered = noticeState.noticeList.filter( (el) => el.isShow === "true")
        filtered.slice(0).reverse().map( (el, index) => 
            el.index = index + 1
        )
        setPageLength(Math.ceil(filtered.length / postsPerPage));
        setCurrentPost(filtered.slice(firstIndex, lastIndex));
    }

    useEffect(() => {
        fetchDateV2()
    }, [])
    
    useEffect(() => {
        if(isAdmin){
            setPageLength(Math.ceil(noticeState.noticeList.length / postsPerPage));
            setCurrentPost(noticeState.noticeList.slice(firstIndex, lastIndex));
        } else {
            generateUserDate()
        }
    },[firstIndex, lastIndex, postsPerPage, noticeState.noticeList])
    
    // useEffect(() => {
    //     commonApi.post('/all', {}).then( (res) => {
    //         const {data} = res
    //         setNoticeList(data)
    //         setPageLength(Math.ceil(data.length / postsPerPage));

    //         console.log(res)

    //         setCurrentPost(res.data.slice(firstIndex, lastIndex))
    //         console.log(res.data)
    //     })

    //     // axios({
    //     //     method: 'post',
    //     //     url : '192.168.0.1:8000/all',
    //     //     // data : {}
    //     // }).then( (res) => {
    //     //     console.log(res)
    //     // })
    // },[firstIndex, lastIndex, postsPerPage])

    for (let i = 1; i <= pageLength; i++) {
        pageNumbers.push(i);
    }
    const chanagePost = (pageNumber) => {
        setCurrentPage(pageNumber);
    }

    const showPageCount = [
        1, 2, 3, 4, 5, 7
    ]

    const handlePageCount = (e) => {
        setPostPerpage(e.target.value)
        // setCurrentPost(dummy.slice(firstIndex, lastIndex));
        setCurrentPost(noticeList.slice(firstIndex, lastIndex));
        setCurrentPage(1);
      }

    return (
        <BasicFrame>
            <PC>
            <HeaderFrame state={"40"}>
                <div style={{"height" : "14%"}}>
                    <Menu />
                </div>
                <BodyFrame>
                    <Column>
                    </Column>
                    <Spacer />
                    <TableWrapper>
                        {
                            isAdmin && <>
                                <ButtonWrapper>
                                    <DayCountWrapper>
                                        <DayCountSpan>오늘 방문자수 : {toDayCount} </DayCountSpan>
                                        <DayCountSpan>누적 방문자수 : {totalDayCount} </DayCountSpan>
                                    </DayCountWrapper>
                                    <Button onClick={() => {
                                        navigate(`/announce/notice`)
                                        window.scrollTo(0, 0)
                                    }}>글작성</Button>
                                </ButtonWrapper>
                            </>
                        }
                    <NoticeTable>
                                <NoticeHead>
                                    <Tr>
                                        <Td width={"8%"}>
                                            글번호
                                        </Td>
                                        <Td width={"56%"}>
                                            제목
                                        </Td>
                                        <Td width={"12%"}>
                                            작성자
                                        </Td >
                                        <Td width={"24%"}>
                                            등록일
                                        </Td>
                                        {
                                            isAdmin && <>
                                                 <Td width={"8%"}>
                                                    게시/숨김
                                                </Td>
                                            </>
                                        }
                                    </Tr>
                                </NoticeHead>
                                <NotiveBody>
                                    {   
                                        noticeState.noticeList.length > 0 &&
                                        currentPost.map( (el, index) => 
                                            {
                                                // if(isAdmin === false && el.isShow === "true" ){
                                                //     return  <>
                                                //         <Content 
                                                //             key={index}
                                                //             el={el}
                                                //             index={el.index}
                                                //             title={el.title}
                                                //             writer={el.writer}
                                                //             date={getDate(el.date)}
                                                //             isAdmin={isAdmin}
                                                //             isShow={el.isShow}
                                                //             content={el.contents}
                                                //         />   
                                                //     </>
                                                // } else if (isAdmin === true) {
                                                    return  <>
                                                        <Content 
                                                            key={index}
                                                            el={el}
                                                            index={el.index}
                                                            title={el.title}
                                                            writer={el.writer}
                                                            date={getDate(el.date)}
                                                            isAdmin={isAdmin}
                                                            isShow={el.isShow}
                                                            content={el.contents}
                                                            noticeList={noticeState.noticeList}
                                                        />   
                                                    </>
                                                // }
                                            }
                                        )
                                    }
                                </NotiveBody>
                            </NoticeTable>
                        </TableWrapper>
                            <PageNumberWrapper >
                                {
                                    pageNumbers.map((number, index) => 
                                        { 
                                        return (
                                            <PageUl key={index} >
                                                <PageLi active={currentPage === index + 1 ?? " "} onClick={() => 
                                                    {
                                                        chanagePost(number)
                                                    }
                                                }
                                            >
                                                <span>{number}</span>
                                                </PageLi>
                                            </PageUl>
                                        )}
                                        )
                                    }
                            </PageNumberWrapper>
                </BodyFrame>
            </HeaderFrame>
            </PC>
            <Mobile>
                <MenuWarpper height={"80"} >
                    <Menu/>
                </MenuWarpper>
                <HeaderFrame vh={"6"}>
                </HeaderFrame>
                <HeaderFrame vh={"90"}>
                    <BlockWrapper>
                        <TableWrapper>
                            <NoticeTable>
                                <NoticeHead>
                                    <Tr>
                                        <Td width={"8%"}>
                                            글번호
                                        </Td>
                                        <Td width={"56%"}>
                                            제목
                                        </Td>
                                        <Td width={"12%"}>
                                            작성자
                                        </Td >
                                        <Td width={"24%"}>
                                            등록일
                                        </Td>
                                    </Tr>
                                </NoticeHead>
                                <NotiveBody>
                                {   
                                        noticeState.noticeList.length > 0 &&
                                        currentPost.map( (el, index) => 
                                            {
                                                if(isAdmin === false && el.isShow === "true" ){
                                                    return  <>
                                                        <Content 
                                                            key={index}
                                                            el={el}
                                                            index={el.index}
                                                            title={el.title}
                                                            writer={el.writer}
                                                            date={getDate(el.date)}
                                                            isAdmin={isAdmin}
                                                            isShow={el.isShow}
                                                            content={el.contents}
                                                        />   
                                                    </>
                                                } else if (isAdmin === true) {
                                                    return  <>
                                                        <Content 
                                                            key={index}
                                                            el={el}
                                                            index={el.index}
                                                            title={el.title}
                                                            writer={el.writer}
                                                            date={getDate(el.date)}
                                                            isAdmin={isAdmin}
                                                            isShow={el.isShow}
                                                            content={el.contents}
                                                        />   
                                                    </>
                                                }
                                            }
                                        )
                                    }
                                </NotiveBody>
                            </NoticeTable>
                        </TableWrapper>
                    </BlockWrapper>
                    <HeaderFrame vh={"6"} >
                        <PageNumberWrapper >
                            {
                                pageNumbers.map((number, index) => 
                                    { 
                                    return (
                                        <PageUl key={index} >
                                            <PageLi active={currentPage === index + 1 ?? " "}  onClick={() => chanagePost(number)}>
                                            <span>{number}</span>
                                            </PageLi>
                                        </PageUl>
                                    )}
                                    )
                                }
                        </PageNumberWrapper>
                    </HeaderFrame>
                </HeaderFrame>
                <HeaderFrame vh={"8"} >
                    {/* <SelectWrapper>
                        <select defaultValue={postsPerPage} onChange={handlePageCount}>
                        {
                            showPageCount.map((element, index) => (
                                <StyledOption 
                                    key={index}
                                    value={element}
                                >
                                {element}
                                </StyledOption>)
                            )
                        }
                        </select>
                    </SelectWrapper> */}
                </HeaderFrame>
            </Mobile>
            <FotterFrame />
        </BasicFrame>
    )
}


export default Announcement;
export const SET_NOTICE_LIST = "notice/set_notice_list"

export const replaceNoticeList = (payload) => ({type : SET_NOTICE_LIST, payload})

const initialState = {
    noticeList : []
}

const notice = (state = initialState, action) => {
    switch(action.type){
        case SET_NOTICE_LIST : 
            return {
                ...state,
                noticeList : action.payload
            }

        default : 
            return state;
    }
}

export default notice
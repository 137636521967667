import CryptoJS from "crypto-js";

const property = process.env.REACT_APP_PROPERTY

export const encrypt = (plainText) => {
    const serialize = JSON.stringify(plainText)
    const enc = CryptoJS.AES.encrypt(serialize, property)

    return enc + ""
}

export const decrypt = (encrypted, isDayDec) => {
    try {
        const bytes = CryptoJS.AES.decrypt(encrypted, property)
        const origin = bytes.toString(CryptoJS.enc.Utf8)

        return JSON.parse(origin) === "true"
    } catch (error) {
        if(!encrypted){
            const enc = encrypt(false + "", property)
            const bytes = CryptoJS.AES.decrypt(enc, property)
            const origin = bytes.toString(CryptoJS.enc.Utf8)
            return JSON.parse(origin) === "true"
        }
    }
}

export const timeDifference = (timestamp) => {
    const date1 = new Date(+timestamp)
    const date2 = Date.now()

    var difference = date2 - date1.getTime();

    var daysDifference = Math.floor(difference/1000/60/60/24);
    difference -= daysDifference*1000*60*60*24

    var hoursDifference = Math.floor(difference/1000/60/60);
    difference -= hoursDifference*1000*60*60

    var minutesDifference = Math.floor(difference/1000/60);
    difference -= minutesDifference*1000*60

    if(daysDifference > 0 || hoursDifference > 0 || minutesDifference > 11){
        return false
    }
    
    return true
}

export const setLocalStorage = (value) => {
    localStorage.setItem("_jinsil_", value)
}

export const getLocalStorage = () => {
    return localStorage.getItem("_jinsil_")
}

export const removeLocalStorageKey = () => {
    localStorage.removeItem("_jinsil_")
}
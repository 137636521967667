import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"

const size = {
    xs: '512px', // for small screen mobile
    sm: '640px', // for mobile screen
    md: '900px', // for tablets
    lg: '1280px', // for laptops
    xl: '1480px', // for desktop / monitors
    xxl: '1920px', // for big screens
}
  
const device = {
    xs: `(max-width: ${size.xs})`,
    sm: `(max-width: ${size.sm})`,
    md: `(max-width: ${size.md})`,
    lg: `(max-width: ${size.lg})`,
    xl: `(max-width: ${size.xl})`,
    xxl: `(max-width: ${size.xxl})`,
}

const StyledSubMenu = styled.div`
    background-color: #E2E2E2;
    width: 100%;
    color: #000000;

    display: flex;
    flex-direction: row;
    justify-content: center;
    position:fixed;
    top: 80px;
    z-index:999;
    
    > * {
        & {
            display: flex;
            align-items: center;
            justify-content: space-around;
        }
    }

    @media ${device.sm} {
        position : fixed;
        min-height: 40px;
        max-height: 40px;
        z-index: 99;
        font-size: 4.2rem;
    }
`
// &:not(first-child){
//     margin: 0 0 0 8px
// }

const MenuWraper = styled.div`
    min-height: 60px;
    @media ${device.sm} {
        min-height: 40px;
    }
    > * {
        &:nth-child(2){
            margin: 0 1.4rem 0 1.4rem;
            
        }

        &:nth-child(1){
            min-width: 90px;
            cursor: pointer;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            ${
                (props) =>
                {
                    if(props.active === "true"){
                        return "border-bottom: solid 0.2rem black; color: #6C9EE3; font-weight: 700;"
                    }
                }
                          
            }
            @media ${device.sm} {
                border-bottom-width: 0.7rem;
                min-height: 40px;
            }

            
        } 

    }
`

const SubMenu = (props) => {
    const navigate = useNavigate()

    const navigator = (path) => {
        navigate(path)
    }


    return (
        <StyledSubMenu  >
            {
                props.subMenuList.map((el, index)=>(
                    <MenuWraper key={index} onClick={
                        () => {
                            props.setMenuState(index)
                            navigator(el.path)
                        }
                    } active={(props.menuState === index) + ""}>
                        <div>
                         <p>{el.name}</p>
                        </div>
                        {index < props.subMenuList.length - 1 ? <p>|</p> : <p></p>}
                    </MenuWraper>
                ))
            }
        </StyledSubMenu>
    )
}

export const SubMenu02 = (props) => {
    return (
        <StyledSubMenu  >
            {
                props.subMenuList.map((el, index)=>(
                    <MenuWraper key={index} onClick={
                        () => {
                            props.setMenuState(index)
                        }
                    } active={(props.menuState === index) + ""}>
                        <div>
                         <p>{el}</p>
                        </div>
                        {index < props.subMenuList.length - 1 ? <p>|</p> : <p></p>}
                    </MenuWraper>
                ))
            }
        </StyledSubMenu>
    )
}

export default SubMenu
import { BasicFrame, BackGroundImage, HeaderFrame, MainScript01, MainScript02, SubScript, FotterFrame, StyledP, BackGroundImage02 } from "../components/frame";
import Menu from "../components/menu";
import { Mobile, PC } from "../script/responsive";
import { FloatingActionButton, CrossBar } from "../components/frame";
import styled from "styled-components";
import img_01  from "../assets/image/m_1.png";
import img_01_mobile  from "../assets/image/m_1_mobile.png";
import main_cap_01 from '../assets/image/main_cap_01.png'

const size = {
    xs: '512px', // for small screen mobile
    sm: '640px', // for mobile screen
    md: '900px', // for tablets
    lg: '1280px', // for laptops
    xl: '1480px', // for desktop / monitors
    xxl: '1920px', // for big screens
}
  
const device = {
    xs: `(max-width: ${size.xs})`,
    sm: `(max-width: ${size.sm})`,
    md: `(max-width: ${size.md})`,
    lg: `(max-width: ${size.lg})`,
    xl: `(max-width: ${size.xl})`,
    xxl: `(max-width: ${size.xxl})`,
}
const LogoWrapper = styled.div`
    width: 100%;
    height: 50vh;
    position: relative;
    z-index: 1;
    overflow:hidden;
`

const Top = styled.div`
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #6C9EE3;
    font-weight: 700;
    font-size: 2rem;

    @media ${device.sm} {
        font-size: 6rem;
    }
`

const Bottom = styled.div`
    background-color: white;
    height: 30%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @media ${device.sm} {
        height: 30%;
    }
`
const MiddleWrapper = () => {

    const Middle = styled.div`
        background-color: #FFFFFF;
        height: 30%;
        width: 70%;
        display: flex;
        z-index: 5;
    `

    const MobileMiddle = styled.div`
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    `
    const Script = styled.div`
        width: 100%;

        @media ${device.sm} {
            display:flex;
            justify-content:center;

            font-size: 4.2rem;
            width: 100%;
            font-weight: 700;
        }
    `
    
    const SubScript = styled.div`
        width: 100%;
        @media ${device.sm} {
            display:flex;
            justify-content:center;
            width: 100%;
            margin-bottom: 1.4rem;
        }
    `

    const Left = styled.div`
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #6C9EE3;
    `

    const Right = styled.div`
        width: 50%;
        display: flex;
        background: transparent url(${img_01}) 0% 0% no-repeat padding-box;
        background-size: cover;
    `

    const Spacer = styled.div`
        height: 10%;
    `

    const MobileTop = styled.div`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #6C9EE3;
    `


    const NomalContainer = styled.div`
        display: flex;
        width: 100%;
        justify-content:center;
        align-items: end;
        
        > * {
            &:nth-child(2){
                @media ${device.md}{
                    font-size: 1rem;
                    word-break: unset;
                }
                @media ${device.lg}{
                    font-size: 1rem;
                }
            }
        }
    `

    const MobileNomalContainer = styled.div`
        display: flex;
        width: 100%;
        justify-content:center;
        align-items: end;
    
    > * {
        &:nth-child(2){

        }
    }
`


    return (

        <Middle>
            <PC>
                <Left>
                    <Script>
                        <NomalContainer>
                            <StyledP size={"4.6"} weight={"500"}>
                                진실
                            </StyledP>
                            <StyledP size={"2"} weight={"500"}>
                                은 전문적인 간호, 돌봄서비스를 다룹니다.
                            </StyledP>
                        </NomalContainer>
                    </Script>
                    <Spacer></Spacer>
                    <SubScript>
                        <NomalContainer>
                            <StyledP word weight={"500"}>
                                저희 진실과 함께 부모님과 더 오랜 시간을 함께 하세요.
                            </StyledP>
                        </NomalContainer>
                    </SubScript>
                </Left>
                <Right>
                </Right>
            </PC>
            <Mobile>
                <MobileMiddle>
                    <MobileTop>
                        <MobileNomalContainer>
                            <StyledP size={"7.4"} weight={"500"}>
                                진실
                            </StyledP>
                            <StyledP size={"3.6"} weight={"500"}>
                                은 전문적인 간호, 돌봄서비스를 다룹니다.
                            </StyledP>
                        </MobileNomalContainer>
                        <Spacer></Spacer>
                        <SubScript>
                        <MobileNomalContainer>
                            <StyledP size={"3.6"} weight={"500"}>
                                저희 진실과 함께 부모님과 더 오랜 시간을 함께 하세요.
                            </StyledP>
                        </MobileNomalContainer>
                        </SubScript>
                    </MobileTop>
                </MobileMiddle>
            </Mobile>
        </Middle>

    )
}

const MiddleWrapperImage = () => {

    const Middle = styled.div`
        background-color: #FFFFFF;
        height: 70%;
        width: 70%;
        display: flex;
        z-index: 5;

        @media ${device.sm} {
            min-height: 300px;
            max-height: 300px;
            min-width: 330px;
            max-width: 330px;
        }
    `

    const BottomImage = styled.div`
        width: 100%;
        height: 100%;
        display: flex;
        background: transparent url(${img_01_mobile}) 50% 50% no-repeat padding-box;
        background-size: contain;
    `

    return (

        <Middle>
            <BottomImage/>
        </Middle>

    )
}

const BottomWrapper = () => {

    return (
        <Bottom>
            {/* <StyledP
                  size={"4.6"}
                  hexColor={"#6C9EE3"}
                  weight={"700"}
            >
                    간편상담 신청하기
            </StyledP> */}
        </Bottom>
    )
}

const ImageWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const LogoFrame = () => {
    return (
        <LogoWrapper>
            <ImageWrapper>
                {/* <BackGroundImage/> */}
                <BackGroundImage02/>
            </ImageWrapper>
            {/* <MainScript01 top={"22"}>
                진정한 돌봄은
            </MainScript01>
            <MainScript02 top={"30.5"}>
                진실한 마음에서 시작됩니다.
            </MainScript02>
            <SubScript>
                노인전문간호사가 운영하는 프리미엄 방문간호&요양 서비스
            </SubScript> */}
        </LogoWrapper>
    );
};

const MainCardImage = styled.div`
    height: 100%;
    width: 100%;
    background: transparent url(${main_cap_01}) 50% 50% no-repeat padding-box;
    background-size: contain;

    @media ${device.sm} {
            height: 100%;
            width: 100%;
            background: transparent url(${main_cap_01}) 0% 0% no-repeat padding-box;
            background-position: 50% 50%;
    }

    align-self: center;
    `

const BodyFrame = () => {
    const BodyWrapper = styled.div`
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        
        color: white;
        font-weight: 300;
        width: 100%;
        height: 100vh;
        background-color: #FFFFFF;
        
        overflow:hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
        position:relative;
        @media ${device.sm} {
            height: 70vh;
        }
    `

    
    const IndexBackground = styled.div`
        position: absolute;
        background-color: #EFF9FE;
        height: 30%;
        width: 70%;
        z-index: 1;
        left: 20%;
        top: 24%;

         @media ${device.sm} {
            left: 26%;
            top: 30%;
        }
    `

    const Spacer = styled.div`
        height: 8%;
    `

    const SizedBox = styled.div`
        height: 24%;
    `

    return (
       <BodyWrapper>
            <PC>
                <SizedBox />
            </PC>
            <Top>
                <PC>
                    <StyledP
                    size={"3.6"}
                    hexColor={"#6C9EE3"}
                    weight={"700"}
                    >
                        100% 신뢰를 보장하는
                    </StyledP>
                    <Spacer></Spacer>
                    <StyledP
                    size={"5.4"}
                    hexColor={"#6C9EE3"}
                    weight={"700"}
                    >
                        진실시니어케어센터
                    </StyledP>
                </PC>
                <Mobile>
                    <StyledP
                    size={"5.4"}
                    hexColor={"#6C9EE3"}
                    weight={"700"}
                    >
                        100% 신뢰를 보장하는
                    </StyledP>
                    <Spacer></Spacer>
                    <StyledP
                    size={"9.8"}
                    hexColor={"#6C9EE3"}
                    weight={"700"}
                    >
                        진실시니어케어센터
                    </StyledP>
                </Mobile>
            </Top>
            <PC>
                <MainCardImage />
            </PC>
            <Mobile>
                <MiddleWrapper />
                <BottomWrapper /> 
                {/* <LeftBackgroundImage/> */}
                <CrossBar/>
                <IndexBackground/>
            </Mobile>
       </BodyWrapper>
    );
};

const EmptyContainer = styled.div`
    height: 24vh;
`

const Main = () => {
    return (
        <BasicFrame>
            <PC>
                <Menu />
                <div style={{"height" : "80px"}}></div>
                <HeaderFrame vh={"50"}>
                    <LogoFrame />
                </HeaderFrame>
                <BodyFrame />
                <EmptyContainer />
                <FotterFrame />
                <FloatingActionButton />
            </PC>

            <Mobile>
                <Menu />
                <HeaderFrame vh={"30"}>
                    <LogoFrame />
                </HeaderFrame>
                <BodyFrame />
                <FotterFrame />
                <FloatingActionButton />
            </Mobile>
        </BasicFrame>
    )
}

export default Main;


/**
 * 
  * 
 */
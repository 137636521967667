import { useMediaQuery } from "react-responsive";
import React from "react"

const isMobile = () => {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        return true
    } else{
        return false
    }
}

const isMac = () => {
    if(/(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)){
        return true 
    } else {
        return false
    }
}
const Mobile = ({children}) => {
    const isMobile = useMediaQuery({
        query : "(max-width:640px)"
    });

    return <>{isMobile && children}</>
}

const PC = ({children}) => {
    const isPc = useMediaQuery({
        query : "(min-width:641px)"
    });

    return <>{isPc && children}</>
}

const Tablet = ({children}) => {
    const isTab = useMediaQuery({
        query : "(max-width:1280px)"
    });

    return <>{isTab && children}</>
}

export {Mobile, PC, isMobile, isMac, Tablet}
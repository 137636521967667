
// import {getDocs, doc, collection, getDoc, setDoc, query, where, deleteDoc } from 'firebase/firestore/lite'
// import { db } from '../App'
import { commonApi } from '../api/config';

// const docName = '/consult'

export async function getConsult(){
    const snap = await commonApi.post('/consult/get-all')
    const {detail} = snap.data
    const list = []
    if(Array.isArray(detail) && detail.length){
        detail.forEach( el => {
            el.index = el.consultId
            delete el.consultId
            list.push(el)
        })

        list.sort( (a, b) => {
            if(+a.index < +b.index) return 1;
            if(+a.index === +b.index) return 0;
            if(+a.index > +b.index) return -1;
        })
    }
    
    return list
}



export async function addConsult(name, phoneNum, region, wantedService, type){
        await commonApi.post('/consult/add-consult', {
            name : name,
            phoneNum : phoneNum,
            contents : wantedService,
            date : Date.now() + "",
            region : region,
            userType : type === "이용자" ? "1" : type === "간편" ? "3" : "2",
            isConsult : "false",
            remark : "1"
        })

    // const querySnapshot = await getDocs(collection(db, docName));
    // if(!querySnapshot.empty){
    //     const lastIndex = querySnapshot.docs.length 
    //     await setDoc(doc(db, docName, (lastIndex + 1) + ""), {
    //         name : name,
    //         phoneNum : phoneNum,
    //         contents : wantedService,
    //         date : Date.now() + "",
    //         index: (lastIndex + 1) + "",
    //         region : region,
    //         userType : type === "이용자" ? "1" : type === "간편" ? "3" : "2",
    //     })
    // } else {
    // }
}

export async function setConsult(remark, isConsult, index, contents, date, type, phoneNum, name, region){
    
    await commonApi.post('/consult/add-consult', {
        name : name,
        phoneNum : phoneNum,
        contents : contents,
        date : Date.now() + "",
        region : region,
        index : index,
        userType : type === "이용자" ? "1" : type === "간편" ? "3" : "2",
        isConsult : isConsult,
        remark : remark,
    })

    
    // await setDoc(doc(db, docName, index), {
    //     remark : remark,
    //     isConsult : isConsult,
    //     contents : contents,
    //     index : index,
    //     date : date,
    //     phoneNum : phoneNum,
    //     userType : type === "이용자" ? "1" : type === "간편" ? "3" : "2",
    //     name : name,
    //     region : region,
    // })
}


export async function dropConsult(index){
    // await deleteDoc(doc(db, docName, index + ""))
}
import React, { useEffect, useState } from "react"
import Modal from "react-modal"
import styled from "styled-components";
import { StyledP } from "./frame";
import { Mobile, PC } from "../script/responsive";
import { XSymbolSvg } from "./loginModal";
import Select from "react-select";
import { addConsult } from "../firebase/consultFireStore";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { toogleSubmitFlag } from "../reducers/ConsultReducer";

const size = {
    xs: '512px', // for small screen mobile
    sm: '640px', // for mobile screen
    md: '900px', // for tablets
    lg: '1280px', // for laptops
    xl: '1480px', // for desktop / monitors
    xxl: '1920px', // for big screens
}
  
const device = {
    xs: `(max-width: ${size.xs})`,
    sm: `(max-width: ${size.sm})`,
    md: `(max-width: ${size.md})`,
    lg: `(max-width: ${size.lg})`,
    xl: `(max-width: ${size.xl})`,
    xxl: `(max-width: ${size.xxl})`,
}
  

const ModalBackgroundContainer = styled.div`
    min-width: 800px;
    height: 58vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y : auto;
    overflow-x : hidden;

    @media ${device.sm} {
        min-width: calc(100vw - 20vw);
        height: 68vh;
    }
`
Modal.setAppElement('#root');

const ModalContainer = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`

const Top = styled.div`
    word-break: keep-all;
    word-wrap: break-word;
    display: block;
    padding: 1rem 1rem;
    font-weight: 500;
    text-align: left;

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    
    border-bottom: 0.05rem solid black;
    
    @media ${device.sm} {
        padding: 2.6rem 0;
        border-bottom: 0.5rem solid #ddd;
    }
    `
    
const Middle = styled.div`
    width: 100%;
    @media ${device.sm} {
        padding-top: 2.6rem;
    }
`

const Bottom = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
`

const InputRadio = styled.input`
`
const RadioLabel = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;

    @media ${device.sm} {
        text-align: center;
    }
`

const RadioContainerWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 0.6rem;
`


const RadioWrapper = styled.div`
    width: 100%;
    display:flex;
`

const Cancel = styled.button`
    display: inline-block;
    vertical-align: top;
    font-size: 0.8rem;
    background-color: #FFFFFF;
    line-height: 30px;
    min-width: 3.4rem;
    height: 2rem;
    padding: 0 22px;
    border: 0.05rem solid #ddd;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;

    @media ${device.sm} {
        font-size: 5rem;
        height: 12rem;
        border: 0.6rem solid #ddd;
    }
`

const Submit = styled.button`
    display: inline-block;
    vertical-align: top;
    font-size: 0.8rem;
    background-color: #fff;
    line-height: 34px;
    min-width: 3.4rem;
    height: 2rem;
    padding: 0 22px;
    border-radius: 4px;
    border: 0.05rem solid #ddd;
    text-align: center;
    margin-left: 0.4rem;
    cursor: pointer;

    @media ${device.sm} {
        font-size: 5rem;
        height: 12rem;
        border: 0.6rem solid #ddd;
        margin-left: 1.4rem;
    }
`

const InputContianer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 0.6rem;
`

const MobileCheckContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 0.6rem;
`

const RadioContainer = styled.div`
    width: 100%;
    min-width: 4rem;
    display: flex;
    align-items: center;

    @media ${device.sm} {
        width: 100%;
    }
`

const InputWrapper = styled.div`
    width: 6rem;
    height: 2rem;
    position: relative;
    box-sizing: border-box;
    margin-left: 1rem;
    margin-right: 1rem;

    ${
        props => props.width && `width: ${props.width};`
    }

    @media ${device.sm} {
        height: 12rem;
        width: 100%;
    }
`

const CheckBoxWrapper = styled.div`
    width: 50%;
    height: 2rem;
    position: relative;
    box-sizing: border-box;
    margin-left: 1rem;
    margin-right: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: center;

    ${
        props => props.width && `width: ${props.width};`
    }

    @media ${device.sm} {
        height: 12rem;
        width: 30%;
    }
`

const ModalInput = styled.input`
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 1px;
    right: 1px;
    bottom: 0;
    z-index: 1;
    resize: none;
    margin: 10px 0;
    padding: 0 18px 0 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #191919;
    border: 0 none;
    outline: none;
    background: transparent;
    box-sizing: border-box;
`

const InputLabel = styled.label`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #fff;
    box-sizing: border-box;
`

const PWrapper = styled.div`
    max-width: 150px;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content:center;
    min-height: 3rem;

    @media ${device.sm} {
        min-height: 20rem;
        max-width: 64px;
    }
`

const MobileCheckTextWrapper = styled.div`
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content:center;
    min-height: 3rem;

    @media ${device.sm} {
        min-height: 20rem;
    }
`

const regionSelectOption = [
    { value : "서울특별시", label : "서울특별시"},
    { value : "인천광역시", label : "인천광역시"},
    { value : "대전광역시", label : "대전광역시"},
    { value : "광주광역시", label : "광주광역시"},
    { value : "대구광역시", label : "대구광역시"},
    { value : "울산광역시", label : "울산광역시"},
    { value : "부산광역시", label : "부산광역시"},
    { value : "경기도", label : "경기도"},
    { value : "강원도", label : "강원도"},
    { value : "충청북도", label : "충청북도"},
    { value : "충청남도", label : "충청남도"},
    { value : "전라북도", label : "전라북도"},
    { value : "전라남도", label : "전라남도"},
    { value : "경상북도", label : "경상북도"},
    { value : "경상남도", label : "경상남도"},
    { value : "제주특별자치도", label : "제주특별자치도"},
]

const serviceSelectOption = [
    { value : "방문요양서비스", label : "방문요양서비스"},
    { value : "방문간호서비스", label : "방문간호서비스"},
    { value : "간호간병서비스", label : "간호간병서비스"},
]

const selectBoxStyle = {
    control : (provided) => ({
        ...provided,
        fontSize : "14px",
    }),
    option : (provided) => ({
        ...provided,
        fontSize : "14px",
    }),
    omdocatprSeparator : (provided) => ({
        ...provided,
        display : "none"
    })
}

const CheckBox = styled.input`
   position:relative;

   border-color: hsl(0, 0%, 80%);
   border-radius: 4px;
   border-style: solid;
   border-width: 1px;
   box-sizing: border-box;

margin: 0;
    
   width: 1.2rem;
   height: 1.2rem;
   
   @media ${device.sm} {
       width: 8rem;
       height: 8rem;
   }
`

const CheckBoxLabel = styled.label`
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;

    width: 22px;
    height: 22px;

    position:absolute;
`

const checkRegex = (str) => {
    const regex = /^[0-9]*$/;
    if(regex.test(str)){
        return true
    }
    return false
}

const JinsilModal = (props) => {
    
    // PC인지 아닌지
    const [isPC, setMediaquery] = useState(window.innerWidth < 641 ? false : true)

    useEffect( ()=> {

        if(window){
            var x = window.matchMedia("(min-width: 641px)")
            x.addEventListener("change", screenChange);
        }

        return () => {
            x.removeEventListener("change", screenChange)
        }
    }, []) 

    const screenChange = (x) => {
        setMediaquery(x.matches)
    }

    const closeModal = () => {
        document.body.style = `overflow: auto;`
        props.toggle(false)

    }
    
    const [name, setName] = useState("")
    const [phoneNum1, setPhoneNum1] = useState("")
    const [phoneNum2, setPhoneNum2] = useState("")
    const [phoneNum3, setPhoneNum3] = useState("")
    const [region, setRegion] = useState("")
    const [wantedService, setWantedService] = useState("")
    const [isCheck, toggleCheckBox] = useState(false)
    const [smsCheck, toggleSmsCheckBox] = useState(false)
    
    const handleName = (value) => {
        setName(value)
    }  

    const handlePhoneNum01 = (value) => {
        setPhoneNum1(value)
    }

    const handlePhoneNum02 = (value) => {
        setPhoneNum2(value)
    }
    
    const handlePhoneNum03 = (value) => {
        setPhoneNum3(value)
    }

    const handleRegion = (value) => {
        setRegion(value)
    }


    const handleWantedService = (option) => {
        setWantedService(option)
    }

    const checkBoxHandler = (target) => {
        toggleCheckBox(target.checked)
    }

    const smsCheckBoxHandler = (target) => {
        toggleSmsCheckBox(target.checked)
    }

    const initInputValues = () => {
        setName("")
        setPhoneNum1("")
        setPhoneNum2("")
        setPhoneNum3("")
        toggleCheckBox(false)
        toggleSmsCheckBox(false)
    }

    const policyToast = (text) => toast.info(`${text}`, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    })

    const dispatch = useDispatch()

    return <>
            <Modal 
                isOpen={props.open} 
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        overflow: 'hidden'
                      },
                      overlay :{
                          zIndex : "999",
                          backgroundColor : "rgba(0,0,0,.5)"
                      }
                }}
                    contentLabel="_modal"
                >
                <ModalBackgroundContainer>
                    <ModalContainer>
                        <Top>
                            <StyledP size={isPC ? "2" : "5.6"} >
                                 { props.userType !== undefined ? props.userType + " " : "간편 "}상담 신청
                            </StyledP>
                            <XSymbolSvg onClick={() => {
                                closeModal()
                            }}/>
                        </Top>
                        <Middle>
                            <InputContianer>
                                <PWrapper>
                                    <StyledP size={isPC ? "2" : "5.6"}>
                                        이름
                                    </StyledP>
                                </PWrapper>
                                <InputWrapper >
                                    <ModalInput id="id_input" type="text" maxlength="10" placeholder="홍길동" onChange={({target}) => {handleName(target.value)}}>
                                    </ModalInput>
                                        <InputLabel htmlFor="id_input" />
                                    </InputWrapper>
                            </InputContianer>

                                <InputContianer>
                                    <PWrapper>
                                        <StyledP size={isPC ? "2" : "5.6"}>
                                            연락처
                                        </StyledP>
                                    </PWrapper>
                                <InputWrapper width={"6rem"}>
                                    <ModalInput id="id_telno" type="text" maxlength="3" placeholder="010" value={phoneNum1} onChange={({target})=>{handlePhoneNum01(target.value)}}>
                                    </ModalInput>
                                        <InputLabel htmlFor="id_telno" />
                                </InputWrapper>
                                    <StyledP size={isPC ? "2" : "5.6"}>
                                        -
                                    </StyledP>
                                <InputWrapper width={"6rem"}>
                                    <ModalInput id="id_telno" type="text" maxlength="4" placeholder="0000" value={phoneNum2} onChange={({target})=>{handlePhoneNum02(target.value)}}>
                                    </ModalInput>
                                        <InputLabel htmlFor="id_telno" />
                                </InputWrapper>
                                <StyledP size={isPC ? "2" : "5.6"}>
                                        -
                                    </StyledP>
                                <InputWrapper width={"6rem"}>
                                    <ModalInput id="id_telno" type="text" maxlength="4" placeholder="0000" value={phoneNum3} onChange={({target})=>{handlePhoneNum03(target.value)}}>
                                    </ModalInput>
                                        <InputLabel htmlFor="id_telno" />
                                </InputWrapper>
                                </InputContianer>

                                <InputContianer>
                                    <PWrapper>
                                        <StyledP size={isPC ? "2" : "5.6"}>
                                            지역
                                        </StyledP>
                                    </PWrapper>
                                <InputWrapper width={"12rem"} isSelectWrapper={" "}>
                                    <Select 
                                        defaultValue={{
                                            value : "시/도 선택", label : "시/도 선택"
                                        }}
                                        options={regionSelectOption}
                                        isClearable={false}
                                        isSearchable={false}
                                        styles={selectBoxStyle}
                                        maxMenuHeight={"12vh"}

                                        onChange={({value}) => {
                                            handleRegion(value)
                                        }}
                                    />
                                </InputWrapper>

                                {/* <InputWrapper width={"12rem"}>
                                    <Select 
                                        defaultValue={{
                                            value : "구/군 선택", label : "구/군 선택"
                                        }}
                                        options={regionSelectOption}
                                        isClearable={false}
                                        isSearchable={false}
                                        styles={selectBoxStyle}
                                        maxMenuHeight={"12vh"}

                                        onChange={({value}) => {
                                            handleRegion(value)
                                        }}
                                    />
                                </InputWrapper> */}
                            </InputContianer>
                                
                            <RadioContainerWrapper> 
                                <RadioContainer>
                                    <PWrapper>
                                        <StyledP size={isPC ? "2" : "5.6"}>
                                            희망{!isPC && <br/>}서비스
                                        </StyledP>
                                    </PWrapper>
                                    <InputWrapper width={"12rem"}> 
                                    
                                        <Select 
                                            defaultValue={{
                                                value : "서비스 선택", label : "서비스 선택"
                                            }}
                                            options={serviceSelectOption}
                                            isClearable={false}
                                            isSearchable={false}
                                            styles={selectBoxStyle}
                                            maxMenuHeight={"10vh"}
                                            onChange={({value}) => {
                                                handleWantedService(value)
                                            }}
                                        />
                                    </InputWrapper>
                                </RadioContainer>
                            </RadioContainerWrapper>
                            
                            <PC>
                                <MobileCheckContainer>
                                    <MobileCheckTextWrapper>
                                        <StyledP size={isPC ? "1.8" : "4.4"}>
                                            개인정보 취급방침 동의
                                        </StyledP>
                                    </MobileCheckTextWrapper>
                                    <CheckBoxWrapper >
                                        <CheckBox type="checkbox" in={"check_box"} checked={isCheck} onChange={({target}) => {checkBoxHandler(target)}}/>
                                        {/* <CheckBoxLabel  htmlFor="check_box" onClick={({target}) => {checkBoxHandler(target)}}/> */}
                                        {/* <ModalInput id="id_input" type="text" maxlength="5" placeholder="홍길동" onChange={({target}) => {handleName(target.value)}}>
                                        </ModalInput>
                                    <InputLabel htmlFor="id_input" /> */}
                                    </CheckBoxWrapper>
                                </MobileCheckContainer>
                                <MobileCheckContainer>
                                    <MobileCheckTextWrapper>
                                        <StyledP size={isPC ? "1.8" : "4.4"}>
                                            SMS 수신 동의
                                        </StyledP>
                                    </MobileCheckTextWrapper>
                                    <CheckBoxWrapper >
                                        <CheckBox type="checkbox" in={"check_box"} checked={smsCheck} onChange={({target}) => {smsCheckBoxHandler(target)}}/>
                                        {/* <CheckBoxLabel  htmlFor="check_box" onClick={({target}) => {checkBoxHandler(target)}}/> */}
                                        {/* <ModalInput id="id_input" type="text" maxlength="5" placeholder="홍길동" onChange={({target}) => {handleName(target.value)}}>
                                        </ModalInput>
                                    <InputLabel htmlFor="id_input" /> */}
                                    </CheckBoxWrapper>
                                </MobileCheckContainer>
                            </PC>
                            <Mobile>
                                <MobileCheckContainer>
                                    <MobileCheckTextWrapper>
                                        <StyledP size={isPC ? "1.8" : "5.4"}>
                                            개인정보 취급방침 동의
                                        </StyledP>
                                    </MobileCheckTextWrapper>
                                    <CheckBoxWrapper >
                                        <CheckBox type="checkbox" in={"check_box"} checked={isCheck} onChange={({target}) => {checkBoxHandler(target)}}/>
                                        {/* <CheckBoxLabel  htmlFor="check_box" onClick={({target}) => {checkBoxHandler(target)}}/> */}
                                        {/* <ModalInput id="id_input" type="text" maxlength="5" placeholder="홍길동" onChange={({target}) => {handleName(target.value)}}>
                                        </ModalInput>
                                    <InputLabel htmlFor="id_input" /> */}
                                    </CheckBoxWrapper>
                                </MobileCheckContainer>
                                <MobileCheckContainer>
                                    <MobileCheckTextWrapper>
                                        <StyledP size={isPC ? "1.8" : "5.4"}>
                                        SMS 수신 동의
                                        </StyledP>
                                    </MobileCheckTextWrapper>
                                    <CheckBoxWrapper >
                                        <CheckBox type="checkbox" in={"check_box"} checked={smsCheck} onChange={({target}) => {smsCheckBoxHandler(target)}}/>
                                    </CheckBoxWrapper>
                                </MobileCheckContainer>
                            </Mobile>
                        </Middle>
                        <Bottom>
                            <Cancel onClick={ () => {closeModal()}}>취소</Cancel>
                            <Submit onClick={ () => {
                                if(region === ""){
                                    policyToast("방문서비스 지역은 필수 항목입니다.")
                                } else if(!isCheck){
                                    policyToast("개인정보 취급방침 동의 항목은 필수 항목입니다.")
                                } else if(!smsCheck){
                                    policyToast("SMS 수신 동의 항목은 필수 항목입니다.")
                                } else if (!checkRegex(`${phoneNum1}${phoneNum2}${phoneNum3}`)) {
                                    policyToast("전화번호는 숫자만 입력할 수 있습니다.")
                                }else if(name !== "" && phoneNum1 !== "" && phoneNum2 !== "" && phoneNum3 !== "" && region !== "" && wantedService !== ""){
                                    addConsult(name, `${phoneNum1}-${phoneNum2}-${phoneNum3}`, region, wantedService, props.userType ?? "간편")
                                    closeModal()
                                    initInputValues()
                                    dispatch(toogleSubmitFlag())
                                }
                            }}>신청하기</Submit>
                        </Bottom>
                    </ModalContainer>
                </ModalBackgroundContainer>
                {/* <ToastContainer style={{fontSize : "14px", width : isPC ?"24vw" : "100%"}}/> */}
            </Modal>
    </>
}

export default JinsilModal
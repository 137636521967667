import { BasicFrame, HeaderFrame, FotterFrame } from "../components/frame";
import Menu from "../components/menu";
import { MenuWarpper } from "./CenterIntro"
import styled from "styled-components";
import { Mobile, PC } from "../script/responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftjsToHtml from "draftjs-to-html";
import { useEffect, useState } from "react";
import { addNotice } from "../firebase/noticeFirestore";
import { decrypt, getLocalStorage, timeDifference } from "../script/cipher";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const size = {
    xs: '512px', // for small screen mobile
    sm: '640px', // for mobile screen
    md: '900px', // for tablets
    lg: '1280px', // for laptops
    xl: '1480px', // for desktop / monitors
    xxl: '1920px', // for big screens
  }
  
  const device = {
    xs: `(max-width: ${size.xs})`,
    sm: `(max-width: ${size.sm})`,
    md: `(max-width: ${size.md})`,
    lg: `(max-width: ${size.lg})`,
    xl: `(max-width: ${size.xl})`,
    xxl: `(max-width: ${size.xxl})`,
  }
const BodyFrame = styled.div`
    width:100%;
    height: 86%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    color: black;
    max-width: 1920px;
    align-self: center;

    ${
        props => props.height && `height: ${props.height};`
    }
`


const TableWrapper = styled.div`
    width: 90%;
    min-height: 650px;
    margin-left: 4rem;
    margin-right: 4rem;

    @media ${device.sm} {
        min-height: 700px;
    }
`

const NoticeTable = styled.table`
    width: 100%;
    border-top: 0.05rem solid #000;
`


const ContentTr = styled.tr`
    line-height: 3rem;
    border-bottom: 0.05rem solid #ddd;
            
    @media ${device.sm} {
        line-height: 34px;
    }

    color: black;
    word-break: keep-all;
    font-size: 1em;


    @media ${device.sm} {
        font-size: 4.2rem;
    }
`

const ContentTitleTd = styled.td`
    background-color: #E2E2E2;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 2.6rem;
    word-break: break-all;

    @media ${device.sm} {
        font-size: 5.4rem;
        line-height: 8rem;
    }
`

const ContentAuthorTh = styled.th`
    background-color: #E2E2E2;
    font-size: 1rem;
    line-height: 2.6rem;
    padding-left: 0.6rem;
    word-break: break-all;
    width: 14%;

    @media ${device.sm} {
        font-size: 5.4rem;
        line-height: 8rem;
        width: 24%;
    }
`
const ContentAuthor = styled.td`
    background-color: white;
    font-weight: 500;
    font-size: 1rem;
    line-height: 2.6rem;
    padding-left: 0.6rem;
    word-break: break-all;

    @media ${device.sm} {
        font-size: 5.4rem;
        line-height: 8rem;
        padding-left: 2rem;
    }
`

const DetailContentTd = styled.td`
    background-color: white;
    font-weight: 500;
    font-size: 1rem;
    line-height: 2.6rem;
    word-break: break-all;

    @media ${device.sm} {

    }

    ${
        ({width}) => width && `width: ${width};`
    }
`

const DetailContent = styled.div`
    min-height: 400px;
    line-height: normal;
    
    @media ${device.sm} {
        font-size: 5.4rem;
        line-height: 8rem;
        width: 100%;
        min-height: unset;
    }
`

const ToListWrapper = styled.div`
    width: 90%;
    display:flex;
    justify-content: end;

    padding-bottom: 1.6rem;
    padding-top: 1.6rem;

    @media ${device.sm} {
        padding-bottom: 4rem;
        padding-top: 4rem;
    }
`

const Button = styled.button`
    display: inline-block;
    vertical-align: top;
    font-weight: 500;
    font-size: 0.8rem;
    background-color: #FFFFFF;
    line-height: 30px;
    min-width: 3.4rem;
    height: 2rem;
    padding: 0 22px;
    border: 0.1rem solid #ddd;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;

    @media ${device.sm} {
        font-size: 5rem;
        height: 12rem;
        border: 0.6rem solid #ddd;
    }
`


const InputWrapper = styled.div`
    width: 100%;
    height: 3rem;
    position: relative;
    box-sizing: border-box;

    ${
        props => props.width && `width: ${props.width};`
    }

    @media ${device.sm} {
        height: 16rem;
        width: 100%;
    }
`


const ModalInput = styled.input`
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 1px;
    right: 1px;
    bottom: 0;
    z-index: 1;
    resize: none;
    margin: 10px 0;
    padding: 0 18px 0 12px;
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    color: #191919;
    border: 0 none;
    outline: none;
    background: transparent;
    box-sizing: border-box;

    @media ${device.sm} {
        font-size: 5rem;
    }
`

const InputLabel = styled.label`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
`


export const ContentEditor = ({editorState, updateTextDescription, uploadCallback}) => {
    const [isPC, setMediaquery] = useState(window.innerWidth < 641 ? false : true)
    
    useEffect( ()=> {

        if(window){
            var x = window.matchMedia("(min-width: 641px)")
            x.addEventListener("change", screenChange);
        }

        return () => {
            x.removeEventListener("change", screenChange)
        }
    }, []) 
    
    const screenChange = (x) => {
        setMediaquery(x.matches)
    }

    return (
        <>
              <Editor
                    placeholder=""
                    editorState={editorState}
                    onEditorStateChange={updateTextDescription}
                    toolbar={{
                        options: ['inline', 'fontSize', 'list', 'textAlign', 'history'],
                        inline: { inDropdown: true },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                    }}
                    localization={{ locale: "ko" }}
                    editorStyle={{
                        height: isPC ? "400px" : "60vh",
                        width: "100%",
                        border: "0.05rem solid lightgray",
                        padding: "10px",
                    }}
                />
        </>
    )
}


export function getDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    const dateString = year + '-' + month + '-' + day;
    return dateString;
}


const CreateNotice = (props) => {
    const navigate = useNavigate()
    const [title, setTitle] = useState("")
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [htmlString, setHtmlString] = useState("");
    const dDay = getDate()

    useEffect(() => {
        // if(!timeDifference(decrypt(getLocalStorage()))){
        if(!decrypt(getLocalStorage())){
            navigate("/")
            window.scrollTo(0, 0)
        }
    }, [])

    const updateTextDescription = async (state) => {
        setEditorState(state);
        const html = draftjsToHtml(convertToRaw(editorState.getCurrentContent()));
        setHtmlString(html);
      };
    
      const uploadCallback = () => {
      };

      const titleNotify = () => toast.info("공지사항 제목은 필수값입니다.", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        })


    return (
        <BasicFrame>
            <PC>
            <HeaderFrame state={"50"}>
                <div style={{"height" : "14%"}}>
                    <Menu />
                </div>
                <BodyFrame>
                    <TableWrapper>
                        <NoticeTable>
                                <ContentTr>
                                    <ContentTitleTd colSpan={"4"}>
                                        <InputWrapper >
                                            <ModalInput value={title} 
                                                        onChange={({target}) => {setTitle(target.value)}} 
                                                        id="title_input" 
                                                        type="text" 
                                                        placeholder="공지사항 제목을 입력해주세요." />
                                            {/* <InputLabel for="title_input" /> */}
                                        </InputWrapper>
                                    </ContentTitleTd>
                                </ContentTr>
                                <ContentTr>
                                    <ContentAuthorTh>작성자</ContentAuthorTh>
                                    <ContentAuthor>관리자</ContentAuthor>
                                    <ContentAuthorTh>등록일</ContentAuthorTh>
                                    <ContentAuthor>{dDay}</ContentAuthor>
                                </ContentTr>
                                <ContentTr>
                                    <DetailContentTd colSpan={"4"}>
                                        <DetailContent>
                                            <ContentEditor 
                                                editorState={editorState}
                                                updateTextDescription={updateTextDescription}
                                                uploadCallback={uploadCallback}
                                            />
                                        </DetailContent>
                                    </DetailContentTd>
                                </ContentTr>
                        </NoticeTable>
                    </TableWrapper>
                    <ToListWrapper>
                            <Button onClick={()=>{
                                if(title !== "" ){
                                    addNotice(title, htmlString)
                                    navigate(`/announce`)
                                    // window.location.reload()
                                    // window.scrollTo(0, 0)
                                } else {
                                    titleNotify()
                                }
                            }}>게시</Button>
                    </ToListWrapper>
                </BodyFrame>
            </HeaderFrame>
            </PC>
            <Mobile>
                <MenuWarpper height={"80"} >
                    <Menu/>
                </MenuWarpper>
                <HeaderFrame vh={"6"}>
                </HeaderFrame>
                <HeaderFrame vh={"120"}>
                    <BodyFrame height={"100%"}>
                    <TableWrapper>
                        <NoticeTable>
                                <ContentTr>
                                    <ContentTitleTd colSpan={"4"}>
                                        <InputWrapper >
                                            <ModalInput value={title} onChange={({target}) => {setTitle(target.value)}} id="title_input" type="text" placeholder="공지사항 제목" />
                                            <InputLabel for="title_input" />
                                        </InputWrapper>
                                    </ContentTitleTd>
                                </ContentTr>
                                <ContentTr>
                                    <ContentAuthorTh>작성자</ContentAuthorTh>
                                    <ContentAuthor>관리자</ContentAuthor>
                                    <ContentAuthorTh>등록일</ContentAuthorTh>
                                    <ContentAuthor>{dDay}</ContentAuthor>
                                </ContentTr>
                                <ContentTr>
                                    <DetailContentTd colSpan={"4"}>
                                        <DetailContent>
                                            <ContentEditor 
                                                editorState={editorState}
                                                updateTextDescription={updateTextDescription}
                                                uploadCallback={uploadCallback}
                                            />
                                        </DetailContent>
                                    </DetailContentTd>
                                </ContentTr>
                        </NoticeTable>
                    </TableWrapper>
                    <ToListWrapper>
                            <Button onClick={()=>{
                                if(title !== "" ){
                                    addNotice(title, htmlString)
                                    navigate(`/`)
                                    window.scrollTo(0, 0)
                                }
                            }}>게시</Button>
                    </ToListWrapper>
                    </BodyFrame>
                 </HeaderFrame>
            </Mobile>
            
            <FotterFrame />
            <ToastContainer style={{fontSize : "14px"}}/>
        </BasicFrame>
    )
}


export default CreateNotice;
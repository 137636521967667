export const SET_CONSERT_LIST = "consult/set_consult_list"
export const TOOGLE_SUBMIT_FLAG = "consult/toogle_submit_flag"

export const appendConsultList = (payload) => ({type : SET_CONSERT_LIST, payload})
export const toogleSubmitFlag = (payload) => ({type : TOOGLE_SUBMIT_FLAG, payload})

const initialState = {
    consultList : [],
    consultSubmitFlag : false,
}

const consult = (state = initialState, action) => {
    switch(action.type){
        case SET_CONSERT_LIST : 
            return {
                ...state,
                consultList : action.payload
            }
        case TOOGLE_SUBMIT_FLAG : 
            return {
                ...state,
                consultSubmitFlag : !state.consultSubmitFlag
            }

        default : 
            return state;
    }
}

export default consult
import styled from "styled-components";
import { BasicFrame, BackGroundImage, HeaderFrame, FloatingActionButton, FotterFrame, MobileCommonFrame, StyledP } from "../components/frame";
import Menu from "../components/menu";
import SubMenu from "../components/subMenu";

import img_14  from "../assets/image/m_14.png";
import img_13  from "../assets/image/m_13.png";
import img_08  from "../assets/image/m_01.png";
import img_07  from "../assets/image/m_02.png";
import img_06  from "../assets/image/m_03.png";
import v_01  from "../assets/image/v_01.png";
import v_02  from "../assets/image/v_02.png";
import v_03  from "../assets/image/v_03.png";
import jinsil  from "../assets/image/jinsil.jpg";

import svg_01  from "../assets/image/m_9.svg";
import svg_02  from "../assets/image/m_10.svg";
import svg_03  from "../assets/image/m_11.svg";
import svg_04  from "../assets/image/m_12.svg";
import logo  from "../assets/image/logo_004.png";
import vision  from "../assets/image/vision.png";
import center_01 from '../assets/image/center_01.png'


import { useEffect, useState } from "react";
import Maps from "../components/googleMap";
import { Mobile, PC } from "../script/responsive";
import { useNavigate } from "react-router-dom";

const size = {
    xs: '512px', // for small screen mobile
    sm: '640px', // for mobile screen
    md: '900px', // for tablets
    lg: '1280px', // for laptops
    xl: '1480px', // for desktop / monitors
    xxl: '1920px', // for big screens
}
  
const device = {
    xs: `(max-width: ${size.xs})`,
    sm: `(max-width: ${size.sm})`,
    md: `(max-width: ${size.md})`,
    lg: `(max-width: ${size.lg})`,
    xl: `(max-width: ${size.xl})`,
    xxl: `(max-width: ${size.xxl})`,
}


const BackGroundImageJinsil = styled.div`
    height: 100%;
    width: 100%;
    background: transparent url(${jinsil}) 50% 50% no-repeat padding-box;
    background-size: contain;

    position: absolute;
    z-index: 3;
    left: 10%;
    top: -25%;

    @media ${device.sm} {
        height: 40%;
        left: 0%;
        top: 24%;
    }
    
`

const BackGroundVision = styled.div`
    height: 100%;
    width: 100%;
    background: transparent url(${vision}) 50% 50% no-repeat padding-box;
    background-size: contain;

    position: absolute;
    z-index: 4;

    @media ${device.sm} {
        height: 100%;
        left: 0%;
        top: 0%;
    }
`
const BackGroundImage05 = styled.div`
    height: 12em;
    width: 12em;

    ${
        props => 
        props.img && `background: transparent url(${ props.img }) 50% 50% no-repeat padding-box;`
    }

    background-size: contain;


    @media ${device.sm} {
        height: 100%;
        width: 100%;

        background-position: 50% 50%;
    }
`

const SvgBackgroundWrapper = styled.div`
    width: 100%;
    height: 100%;

    display:flex;
    flex-direction: column;
`

const BackGroundSvg04 = styled.div`
    height: 14em;
    width: 100%;
    background: transparent url(${svg_01}) 50% 50% no-repeat padding-box;
    background-size: contain;
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    
    color: black;
    font-size : 20px;
    
    
    @media ${device.sm} {
        width: 80%;
        background-size: contain;
        justify-content: space-around;
        height: 50%;
    }
`

const BodyWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    color: #6C9EE3;
    font-weight: 300;
    
    width: 100%;
    height: 70vh;
    background-color: white;
    max-width: 1920px;


    @media ${device.sm} {
        height: 50vh;
        ${
            props => props.height && `height: ${props.height}vh;`
        }
    }


    ${
        props => props.height && `height: ${props.height}vh;`
    }

    ${
        ({unset}) => unset && "height: unset;"
    }

    overflow:hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }


    > * {
        &:nth-child(1),
        &:nth-child(2){

                @media ${device.sm} {
                    font-size: 4.6rem;
                }
        }

        &:nth-child(2){
            margin-top: 2.6rem;
        }

    }
`
const ImageWrapper = styled.div`
    height: 100%;

    display:flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-weight: 400;

    @media ${device.sm} {
        min-width: 100px;
    }
`

export const Spacer = styled.div`
    height: 16%;
`

const BodyFrame = styled.div`
    width:100%;
    height: 86%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    color: black;
    max-width: 1920px;
    align-self: center;
`
export const MobileBodyFrame = styled.div`
    width:100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    color: black;
    max-width: 1920px;
    align-self: center;

    @media ${device.sm} {
        height: 100%;
    }
`
export const Column = styled.div`
    background-color: white;
    width: 100%;
    color : black;
    display : flex;
    justify-content: center;
    align-items: center;
    min-height: 10%;

    ${
        props => props.minHeight && `min-height: ${props.minHeight};`
    }

    ${
        props => props.maxHeight && `max-height: ${props.maxHeight};`
    }
    
    ${
        props => props.align && `align-items: ${props.align};`
    }
    ${
        props => props.col && `flex-direction: column;`
    }
    ${
        props => props.justify && `justify-content: ${props.justify};`
    }

    ${
        props => props.width && `width: ${props.width};`
    }
`

export const WidthBox = styled.div`
    width: 80%;
    height: 100%;
    display: flex;
    ${
        props => props.width && `width: ${props.width};`
    }

    ${
        props => props.justify && `justify-content: ${props.justify};`
    }

    ${
        props => props.align && `align-items: ${props.align};`
    }
    @media ${device.sm} {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        font-size: 3.2rem;
        
        align-self: center;
        > * {   
            &:not(:first-child){
                margin-bottom: 12px;
            }
        }

        ${
            props => props.width && `width: ${props.width};`
        }
        
        ${
            props => props.justify && `justify-content: ${props.justify};`
        }

        ${
            props => props.align && `align-items: ${props.align};`
        }
    }
`

const HalfWidthBox = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    ${
        props => props.width && `width: ${props.width};`
    }
    > * {
        ${
            props => props.size && `font-size: 1.8rem;`
        }
       
    }

    ${
        props => props.justify && `justify-content: ${props.justify};`
    }

    ${
        props => props.align && `align-items: ${props.align};`
    }
`
//  &:nth-child(3){
//             margin-top: 1rem;
//         }

//         &:not(:first-child){
//             margin-bottom: 12px;
//         }
const HalfImageBox = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;

    @media ${device.sm} {
        width: 100%;
    }
`  

const HalfCeoBox = styled.div`
    display: flex;
    align-self: end;
    justify-content: center;
    
    margin-top: 4rem;
    margin-left: 4rem;
    > * {
        &:nth-child(1){
            text-align: bottom;
            align-self: flex-end;
        }
        &:nth-child(2){
            font-weight: bold;
            margin-left: 1rem;
            font-size: 1.4rem;
        }
    }

    @media ${device.sm} {
        > * {
            &:nth-child(2){
                font-weight: bold;
                margin-left: 2rem;
                font-size: 5.2rem;
            }
            &:nth-child(1){
                font-size: 4.2rem;
            }
        }
    }
`


export const VerticalBar = styled.div`
    width: 0.1rem;
    background-color: black;
    height: 3rem;
    
    @media ${device.sm} {
        width: 0.5rem;
        height: 5.6rem;
    }
`  

export const MenuTitle = styled.p`
    font-weight: 700;
    font-size: 3rem;

    @media ${device.sm} {
        font-size: 6rem;
    }
`
export const SubTitle = styled.div`
    display: flex;
    margin-top: 1.4rem;
    > * {
        &:not(:first-child){
            margin-left: 1.6rem;
        }
        
        &:last-child{
            color: #6C9EE3;
        }
    }
    
    @media ${device.sm} {
        font-size: 4.2rem;
        width: 100%;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
`

export const SubTitleWrapper = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    > * {
        &:nth-child(1){
            min-width: 90px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &:nth-child(2){
            margin: 0 1.4rem 0 1.4rem;
        }
    }
`

const BodyBox = styled.div`
    width: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
`
// "비전&미션",

const subMenuList = [
    "대표인사말",
    "비전과 미션",
    "오시는길"
]

const subMenuList_v2 = [
    {
        name : "대표인사말",
        path : "/center/intro",
    },
    {
        name : "비전과 미션",
        path : "/center/vision",
    },
    {
        name : "오시는길",
        path : "/center/path",
    }
]

export const TitleBox = styled.div`
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: column;

    align-items: center;
`

export const MenuWarpper = styled.div`
    min-height: 120px;

    @media ${device.sm} {
        ${
            props => props.height && `min-height: ${props.height}px;`
        }
    }
`

const IntroScriptWrapper = styled.div`
    display:flex;
    > * {
        &:nth-child(1){
            width: 100%;
        }
    }
`

export const MenuBackEmptySpace = styled.div`
    min-height: 140px;
`
export const TitleSperatorWrapper = styled.div`
        width: 100%;
        display: flex;
        padding-bottom: 1rem;

        ${
            props => props.width && `width: ${props.width}%;`
        }
        ${
            ({pb}) => pb && `padding-bottom: ${pb};`
        }
        ${
            ({borderBottom}) => borderBottom && `border-bottom: ${borderBottom};`
        }

        @media ${device.sm} {
            width: 80%;
            ${
                props => props.width && `width: ${props.width}%;`
            }
        }
    `

const CenterIntro = (props) => {
    const [menuState, setMenuState] = useState(props.menuIndex ?? 0)


    const CenterIntroComponent = () => {
        return <>
            <PC>
                <Column minHeight={"30rem"} width={"80%"} >
                    <WidthBox width={"100%"} jusify={"space-between"}>
                        <HalfWidthBox justify={"space-between"}>
                            <TitleSperatorWrapper>
                                <StyledP
                                    size={"2.8"}
                                    weight={"900"}
                                    hexColor={"#6C9EE3"}
                                    margin={"0 0.4rem 0 0"}
                                    >|</StyledP>
                                <StyledP
                                    size={"3.2"}
                                    hexColor={"#6C9EE3"}
                                    weight={"700"}
                                > 대표인사말
                                </StyledP>
                            </TitleSperatorWrapper>
                            <div>
                            <StyledP
                                size={"2"}
                                hexColor={"#000000"}
                            margin={"1rem 0 0 0"}
                            >안녕하세요. 시니어케어센터입니다.
                            </StyledP>
                            <StyledP
                                size={"2"}
                                hexColor={"#000000"}
                                margin={"1rem 0 0 0"}
                            >
                                진실시니어케어센터는 언제든지 신뢰할 수 있는 양질의 
                            </StyledP>
                            <StyledP
                                size={"2"}
                                hexColor={"#000000"}
                                margin={"0.4rem 0 0 0"}
                                >
                               돌봄서비스를 제공받으실 수 있도록 지속적으로 연구합니다. 
                            </StyledP>
                            <StyledP
                                size={"2"}
                                margin={"0.4rem 0 0 0"}
                                hexColor={"#000000"}
                                >
                                진정한 돌봄은 진실된 마음에서 시작됩니다.
                            </StyledP>
                            <StyledP
                                size={"2"}
                                hexColor={"#000000"}
                                margin={"0.4rem 0 0 0"}
                                >
                                진실된 마음으로 어르신을 살피고 늘 보호자분들과 
                            </StyledP>

                            <StyledP
                                margin={"0.4rem 0 0 0"}
                                size={"2"}
                            >
                                소통하겠습니다. 감사합니다.
                            </StyledP>
                            <HalfCeoBox>
                                <p>대표 노인전문간호사</p>
                                <p>장희진</p>
                            </HalfCeoBox>
                            </div>

                
                            <HalfCeoBox>
                            </HalfCeoBox>
                        </HalfWidthBox>
                        <HalfWidthBox>
                            <HalfImageBox>
                                <BackGroundImageJinsil />
                                {/* <BackGroundImage01></BackGroundImage01> */}
                                {/* <BackGroundImage02></BackGroundImage02> */}
                            </HalfImageBox>
                        </HalfWidthBox>
                    </WidthBox>
                </Column> 
            </PC>
            <Mobile>
                    <WidthBox width={"100%"} align={"start"}>
                             <TitleSperatorWrapper width={"100"}>
                                <StyledP
                                    size={"4.2"}
                                    weight={"900"}
                                    hexColor={"#6C9EE3"}
                                    margin={"0 0.4rem 0 0"}
                                    >|</StyledP>
                                <StyledP
                                    size={"4.2"}
                                    hexColor={"#6C9EE3"}
                                    weight={"700"}
                                >대표인사말
                                </StyledP>
                            </TitleSperatorWrapper>
                            
                            
                            <IntroScriptWrapper>
                                <div>
                                    <StyledP
                                        size={"3.6"}
                                        margin={"0"}
                                    >
                                        안녕하세요. 시니어케어센터입니다.
                                    </StyledP>
                                    <StyledP
                                        size={"3.6"}
                                        margin={"4rem 0"}
                                    >진실시니어케어센터는 언제든지 신뢰할 수 있는 양질의 
                                    </StyledP>
                                    <StyledP
                                        size={"3.6"}
                                        margin={"4rem 0 0 0"}
                                    >
                                        돌봄서비스를 제공받으실 수 있도록 지속적으로 연구합니다. 
                                    </StyledP>

                                    <StyledP
                                        size={"3.6"}
                                        margin={"4rem 0 4rem 0"}
                                    >
                                        진실된 마음으로 어르신을 살피고 늘 보호자분들과
                                    </StyledP>
                                    <StyledP
                                        size={"3.6"}
                                        margin={"4rem 0 4rem 0"}
                                    >
                                        소통하겠습니다.
                                    </StyledP>
                                    <StyledP
                                        size={"3.6"}
                                    >
                                        감사합니다.
                                    </StyledP>
                                </div>
                            </IntroScriptWrapper>

                            <HalfCeoBox>
                                    <p>대표 노인전문간호사</p>
                                    <p>장희진</p>
                            </HalfCeoBox>
                    </WidthBox>
            </Mobile>
        </>
    }

    const ScriptWrapper= styled.div`
        width: 100%;
        height: 100%
        display: flex;
        flex-direction: column;
    `

    const ScriptBackgroundImage= styled.div`
        height: 100%;
        width: 100%;
        background: transparent url(${center_01}) 50% 50% no-repeat padding-box;
        background-size: contain;
        align-self: center;

        position:relative;
        right: 1.8rem;
    `

    const MissionAndVision = () => {
        return <>
            <PC>
                <Column minHeight={"30rem"} width={"80%"} justify={"space-between"}>
                    <WidthBox width={"100%"} justify={"space-between"}>
                        <HalfWidthBox width={"60%"} justify={"space-around"}>
                            <ScriptBackgroundImage />
                            {/* <ScriptWrapper>
                            <TitleSperatorWrapper>
                                <StyledP
                                    size={"2.8"}
                                    weight={"900"}
                                    hexColor={"#6C9EE3"}
                                    margin={"1.4rem 0.4rem 0 0"}
                                    >|</StyledP>
                                <StyledP
                                    size={"3.2"}
                                    hexColor={"#6C9EE3"}
                                    weight={"700"}
                                    margin={"1.4rem 0 0 0"}
                                >비전: 전문적인 통합 재가서비스로 커뮤니티케어 실현
                                </StyledP>
                            </TitleSperatorWrapper>

                             < StyledP     
                                    size={"2"}
                                    hexColor={"#000000"}
                                    margin={"1rem 0 0 0"}
                                    >고령자들에게 특화된 종합적이고 혁신적인 건강 관리 서비스를 제공하여  </StyledP>
                             < StyledP     
                                    size={"2"}
                                    hexColor={"#000000"}
                                    margin={"0.4rem 0 0 0"}
                                    >노인들의 삶의 모든 측면에서 건강하고 활기찬 노후를 돕고</StyledP>
                             < StyledP     
                                    size={"2"}
                                    hexColor={"#000000"}
                                    margin={"0.4rem 0 0 0"}
                                    >사회적 연결성을 강화하여 노인에게 의미 있는 삶을 제공할 수 있도록 힘쓰겠습니다.</StyledP>
                            </ScriptWrapper> */}
                                    
                        </HalfWidthBox>
                        <HalfWidthBox size={"2"}>
                            <HalfImageBox>
                                <BackGroundVision/>
                            </HalfImageBox>
                        </HalfWidthBox>
                    </WidthBox>
                </Column> 
            </PC>
           <Mobile>
            <WidthBox >
                <HalfImageBox>
                    <BackGroundVision/>
{/* 
                    <BackGroundImage03/>
                    <BackGroundImage04/>
                    <BackGroundSvg01/>
                    <BackGroundSvg02/>
                    <BackGroundSvg03/>
                    <CucleDiv width={"14px"} top={"35%"} right={"92%"}/>
                    <CucleDiv width={"10px"} top={"34%"} right={"88%"}/>
                    <CucleDiv width={"18px"} top={"80%"} right={"50%"}/>
                    <CucleDiv width={"14px"} top={"6%"} right={"-2%"}/> */}
                </HalfImageBox>
            </WidthBox>
           </Mobile>
        </>
    }


    const StyledMenuWrapper = styled.div`
        display: flex;
        flex-direction: column;
        width: 80%;
        height: 100%;
        align-items: center;
        justify-content: space-around;
    `
        
    const CoreValueWrapper = styled.div`
        width:100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    `

    const ValueWrapper = styled.div`
        width:100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        align-items: center;

        ${
            props => props.direction && `flex-direction: ${props.direction};`
        }

        ${
            props => props.margin && `margin: ${props.margin};`
        }

    `

    return (
        <BasicFrame>
            <PC>
                <Menu/>
                <SubMenu menuState={menuState} setMenuState={setMenuState} subMenuList={subMenuList_v2} menuIndex={props.menuIndex}/>

                <HeaderFrame state={"1"+menuState}>
                    <MenuBackEmptySpace />

                    <BodyFrame>
                        <Column>
                        </Column>
                        <Spacer></Spacer>
                    {
                        menuState === 0 && <>
                            <Column/>
                            <CenterIntroComponent/>
                        </> 
                    }
                    {
                        menuState === 1 && <>
                            <MissionAndVision/>
                            <BodyBox>
                        <BodyWrapper  unset>
                            <CoreValueWrapper>
                            <HalfWidthBox width={"80%"}>
                            <TitleSperatorWrapper>
                                <StyledP
                                    size={"2.8"}
                                    weight={"900"}
                                    hexColor={"#6C9EE3"}
                                    margin={"1.4rem 0.4rem 0 0"}
                                    >|</StyledP>
                                <StyledP
                                    size={"3.2"}
                                    hexColor={"#6C9EE3"}
                                    weight={"700"}
                                    margin={"1.4rem 0 0 0"}
                                >핵심가치
                                </StyledP>
                            </TitleSperatorWrapper>
                            <SvgBackgroundWrapper>
                                <BackGroundSvg04 >
                                    <ImageWrapper> 
                                        <BackGroundImage05 img={v_01}>
                                        </BackGroundImage05>
                                            {/* <p>기본건강관리</p> */}
                                    </ImageWrapper>
                                    <ImageWrapper>
                                        <BackGroundImage05 img={v_02}>
                                        </BackGroundImage05>
                                        {/* <p>전문간호간병</p> */}
                                    </ImageWrapper>
                                    <ImageWrapper>
                                        <BackGroundImage05 img={v_03}>
                                        </BackGroundImage05>
                                        {/* <p>맞춤요양</p> */}
                                    </ImageWrapper>
                                </BackGroundSvg04>
                                </SvgBackgroundWrapper>
                                <ValueWrapper>
                                    <CoreValueWrapper>

                                    < StyledP     
                                        size={"2.4"}
                                        hexColor={"#000000"}
                                        >고객 중심</StyledP>
                                    < StyledP     
                                        size={"2.4"}
                                        hexColor={"#000000"}
                                        margin={"0.6rem 0 0 0"}
                                        >(Customer-Centricity)</StyledP>
                                    </CoreValueWrapper>
                                    <CoreValueWrapper>
                                        < StyledP     
                                            size={"2.4"}
                                            hexColor={"#000000"}
                                            >고품질 서비스</StyledP>
                                        < StyledP     
                                            size={"2.4"}
                                            hexColor={"#000000"}
                                            margin={"0.6rem 0 0 0"}
                                            >(Quality Priority)</StyledP>
                                    </CoreValueWrapper>
                                        <CoreValueWrapper>
                                        < StyledP     
                                        size={"2.4"}
                                        hexColor={"#000000"}
                                        >존중과 배려</StyledP>
                                        < StyledP     
                                        size={"2.4"}
                                        hexColor={"#000000"}
                                        margin={"0.6rem 0 0 0"}
                                        >(Respect and Compassion)</StyledP>
                                        </CoreValueWrapper>
                                </ValueWrapper>
                            </HalfWidthBox>

                            </CoreValueWrapper>
                            <HalfWidthBox width={"80%"}>
                            <TitleSperatorWrapper>
                                <StyledP
                                    size={"2.8"}
                                    weight={"900"}
                                    hexColor={"#6C9EE3"}
                                    margin={"1.4rem 0.4rem 0 0"}
                                    >|</StyledP>
                                <StyledP
                                    size={"3.2"}
                                    hexColor={"#6C9EE3"}
                                    weight={"700"}
                                    margin={"1.4rem 0 0 0"}
                                >미션
                                </StyledP>
                            </TitleSperatorWrapper>

                            <ValueWrapper direction={"column"} 
                                    margin={"1.4rem 0 0 0"}
                            >
                                <SvgBackgroundWrapper>
                                <BackGroundSvg04 >
                                    <ImageWrapper> 
                                        <BackGroundImage05 img={img_08}>
                                        </BackGroundImage05>
                                            {/* <p>기본건강관리</p> */}
                                    </ImageWrapper>
                                    <ImageWrapper>
                                        <BackGroundImage05 img={img_06}>
                                        </BackGroundImage05>
                                        {/* <p>전문간호간병</p> */}
                                    </ImageWrapper>
                                    <ImageWrapper>
                                        <BackGroundImage05 img={img_07}>
                                        </BackGroundImage05>
                                        {/* <p>맞춤요양</p> */}
                                    </ImageWrapper>
                                </BackGroundSvg04>
                                </SvgBackgroundWrapper>
                            
                                <ValueWrapper>
                                    < StyledP     
                                        size={"2.4"}
                                        hexColor={"#000000"}
                                        minWidth={"200px"}
                                        align={"center"}
                                        >기본건강관리</StyledP>
                                    < StyledP     
                                        size={"2.4"}
                                        hexColor={"#000000"}
                                        minWidth={"200px"}
                                        align={"center"}
                                        >전문간호간병</StyledP>
                                        < StyledP     
                                        size={"2.4"}
                                        hexColor={"#000000"}
                                        minWidth={"200px"}
                                        align={"center"}
                                        >맞춤요양</StyledP>
                                </ValueWrapper>
                            </ValueWrapper>
                            </HalfWidthBox>
                           
                        </BodyWrapper> 

                    </BodyBox>
                    <Column/>
                        </>
                    }
                    {
                        menuState === 2 && <Maps/>
                    }
                        <Spacer></Spacer>
                    </BodyFrame>
                </HeaderFrame>
                
            </PC>
            <Mobile>
                <MenuWarpper >
                    <Menu/>
                    <SubMenu menuState={menuState} setMenuState={setMenuState} subMenuList={subMenuList_v2} menuIndex={props.menuIndex}/>
                </MenuWarpper>
                <HeaderFrame vh={menuState === 2 && "70"}>
                    <MobileBodyFrame>
                        <Spacer></Spacer>
                    {
                        menuState === 0 && <>
                            <BackGroundImageJinsil />
                        </>
                    }
                    {
                        menuState === 1 && <MissionAndVision/>
                    }
                    {
                        menuState === 2 && <Maps/>
                    
                    }
                        <Spacer></Spacer>
                    </MobileBodyFrame>
                </HeaderFrame>
              
                    {
                        menuState === 0 && <>
                        
                          <HeaderFrame>
                                <MobileBodyFrame>
                                    <WidthBox >
                                        <CenterIntroComponent/>
                                    </WidthBox>
                                    <Spacer></Spacer>
                                </MobileBodyFrame>
                            </HeaderFrame>
                        </>
                    }
                      
                {
                    menuState === 1 && 
                    <>
                     <BodyBox>
                     {/* <MobileCommonFrame vh={"30"} align={" "}>
                            <TitleSperatorWrapper>
                                    <StyledP
                                        size={"5.8"}
                                        weight={"900"}
                                        hexColor={"#6C9EE3"}
                                        margin={"0 0.4rem 0 0"}
                                        >|</StyledP>
                                    <StyledP
                                        size={"6"}
                                        hexColor={"#6C9EE3"}
                                        weight={"700"}
                                    >비전
                                    </StyledP>
                                </TitleSperatorWrapper>
                            <StyledMenuWrapper>
                                <StyledP
                                    size={"4.2"}
                                    margin={"4rem 0 2rem 0"}
                                >
                                        진실은 고령자들에게 최상의 건강과 복지를 제공하여
                                </StyledP>
                                <StyledP
                                    size={"4.2"}
                                    margin={"2rem 0 2rem 0"}
                                >
                                        삶의 질을 향상시키는 것입니다. 
                                </StyledP>
                                <StyledP
                                    size={"4.2"}
                                    margin={"2rem 0 2rem 0"}

                                >
                                        노인들의 특별한 요구에 맞춰 전문적이고 따뜻한 
                                </StyledP>
                                <StyledP
                                    size={"4.2"}
                                    margin={"2rem 0 2rem 0"}

                                >
                                      방문간호 서비스를 제공하여
                                </StyledP>
                                <StyledP
                                    size={"4.2"}
                                    margin={"2rem 0 2rem 0"}
                                >
                                    그들이 가정에서 안심하고 행복하게 노후를
                                </StyledP>
                                <StyledP
                                    size={"4.2"}
                                    margin={"2rem 0 2rem 0"}
                                >
                                    맞이할 수 있도록 지원합니다.
                                </StyledP>
                               
                            </StyledMenuWrapper>
                    </MobileCommonFrame>  */}
                    </BodyBox>
                    {/* <MobileCommonFrame vh={"8"} align={" "}>
                    </MobileCommonFrame> */}
                    <BodyBox>
                     <MobileCommonFrame vh={"30"} align={" "}>
                            <TitleSperatorWrapper>
                                    <StyledP
                                        size={"5.8"}
                                        weight={"900"}
                                        hexColor={"#6C9EE3"}
                                        margin={"0 0.4rem 0 0"}
                                        >|</StyledP>
                                    <StyledP
                                        size={"6"}
                                        hexColor={"#6C9EE3"}
                                        weight={"700"}
                                    >비전: 전문적인 통합 재가서비스로 
                                    </StyledP>
                                </TitleSperatorWrapper>
                            <TitleSperatorWrapper>
                                    <StyledP
                                        size={"6"}
                                        hexColor={"#6C9EE3"}
                                        weight={"700"}
                                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;커뮤니티케어 실현
                                    </StyledP>
                                </TitleSperatorWrapper>
                            <StyledMenuWrapper>
                                <StyledP
                                    size={"4.2"}
                                    margin={"4rem 0 2rem 0"}
                                >
                                        고령자들에게 특화된 종합적이고 혁신적인 건강 관리 
                                </StyledP>
                                <StyledP
                                    size={"4.2"}
                                    margin={"2rem 0 2rem 0"}
                                >
                                       서비스를 제공하여 노인들의 삶의 모든 측면에서 
                                </StyledP>
                                <StyledP
                                    size={"4.2"}
                                    margin={"2rem 0 2rem 0"}

                                >   
                                     건강하고 활기찬 노후를 돕고
                                </StyledP>
                                <StyledP
                                    size={"4.2"}
                                    margin={"2rem 0 2rem 0"}

                                >
                                    사회적 연결성을 강화하여 노인에게 의미 있는
                                </StyledP>
                                <StyledP
                                    size={"4.2"}
                                    margin={"2rem 0 2rem 0"}

                                >
                                    삶을 제공할 수 있도록 힘쓰겠습니다.
                                </StyledP>
                            </StyledMenuWrapper>
                    </MobileCommonFrame> 
                    </BodyBox>
                    <BodyBox>
                        <BodyWrapper height={"30"}>
                        <TitleSperatorWrapper>
                                <StyledP
                                    size={"5.8"}
                                    weight={"900"}
                                    hexColor={"#6C9EE3"}
                                    margin={"1.4rem 0.4rem 0 0"}
                                    >|</StyledP>
                                <StyledP
                                    size={"6"}
                                    hexColor={"#6C9EE3"}
                                    weight={"700"}
                                    margin={"1.4rem 0 0 0"}
                                >핵심가치
                                </StyledP>
                            </TitleSperatorWrapper>
                            <BackGroundSvg04 >
                                <ImageWrapper>
                                    <BackGroundImage05 img={v_01}>
                                    </BackGroundImage05>
                                    <p>고객 중심</p>
                                </ImageWrapper>
                                <ImageWrapper>
                                    <BackGroundImage05 img={v_02}>
                                    </BackGroundImage05>
                                    <p>고품질 서비스</p>
                                </ImageWrapper>
                                <ImageWrapper>
                                    <BackGroundImage05 img={v_03}>
                                    </BackGroundImage05>
                                    <p>존중과 배려</p>
                                </ImageWrapper>
                            </BackGroundSvg04>
                        </BodyWrapper> 
                    </BodyBox>
                    <BodyBox>
                        <BodyWrapper height={"30"}>
                        <TitleSperatorWrapper>
                                <StyledP
                                    size={"5.8"}
                                    weight={"900"}
                                    hexColor={"#6C9EE3"}
                                    margin={"1.4rem 0.4rem 0 0"}
                                    >|</StyledP>
                                <StyledP
                                    size={"6"}
                                    hexColor={"#6C9EE3"}
                                    weight={"700"}
                                    margin={"1.4rem 0 0 0"}
                                >미션
                                </StyledP>
                            </TitleSperatorWrapper>
                            <BackGroundSvg04 >
                                <ImageWrapper>
                                    <BackGroundImage05 img={img_08}>
                                    </BackGroundImage05>
                                        <p>존엄간호</p>
                                </ImageWrapper>
                                <ImageWrapper>
                                    <BackGroundImage05 img={img_06}>
                                    </BackGroundImage05>
                                    <p>전문간호간병</p>
                                </ImageWrapper>
                                <ImageWrapper>
                                    <BackGroundImage05 img={img_07}>
                                    </BackGroundImage05>
                                    <p>맞춤요양</p>
                                </ImageWrapper>
                            </BackGroundSvg04>
                        </BodyWrapper> 
                    </BodyBox>
                    </>
                }
            </Mobile>
            <FotterFrame/>
        </BasicFrame>
    )
}

export default CenterIntro;
import {combineReducers} from 'redux';
import consult from './ConsultReducer'
import notice from './NoticeReducer'

const rootReducer = combineReducers({
    consult,
    notice,
})

export default rootReducer

// import menuCounter from './menu_reducer'
// import storage from 'redux-persist/lib/storage';
// import { persistReducer } from 'redux-persist';

// const persistConfig = {
//     key : "root",
//     storage,
// }

// export const rootReducer = combineReducers({
//     menuCounter
// });

// export const persistRootReducer = persistReducer(persistConfig, rootReducer);
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom"
import Main from './pages/Main';
import Announcement from "./pages/Announcement";
import Service from "./pages/Service";
import Use from "./pages/Use";
import CenterIntro from "./pages/CenterIntro"
import { createGlobalStyle } from "styled-components";
import NoticeDetail from "./pages/NoticeDetail";
import {createStore} from "redux"
// import { persistStore } from 'redux-persist';
import {Provider} from 'react-redux';
// import { PersistGate } from "redux-persist/integration/react";
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore/lite'
import CreateNotice from "./pages/CreateNotice";
import rootReducer from "./reducers";

// const px2vw = (size, width = 1440) => `${(size / width) * 100}vw`;


const size = {
  xs: '512px', // for small screen mobile
  sm: '640px', // for mobile screen
  md: '900px', // for tablets
  lg: '1280px', // for laptops
  xl: '1480px', // for desktop / monitors
  xxl: '1920px', // for big screens
}

export const device = {
  xs: `(max-width: ${size.xs})`,
  sm: `(max-width: ${size.sm})`,
  md: `(max-width: ${size.md})`,
  lg: `(max-width: ${size.lg})`,
  xl: `(max-width: ${size.xl})`,
  xxl: `(max-width: ${size.xxl})`,
}

const GlobalStyle = createGlobalStyle`

html {
	font-size: 10px;
}

:root {
    font-size: 1.4rem;
    font-family: var(--font-roboto);
  
    @media ${device.xxl} {
      font-size: 1.2rem;
    }

    @media ${device.xl}  {
        font-size: 1rem;
    }

    @media ${device.lg} {
        font-size: 1rem;
    }

    @media ${device.md} {
        font-size: 0.8rem;
    }

    @media ${device.sm} {
      font-size: 0.2rem;
    }

    @media ${device.xs} {
      font-size: 0.2rem;
    }

}
`

const AppRouter = () => {
  return (
      <HashRouter>
      <GlobalStyle/>
        <Routes>
          <Route path="/" element={<Main />}/>
          <Route path="/center" element={<CenterIntro menuIndex={0}/>}/>
          <Route path="/center/intro" element={<CenterIntro menuIndex={0} />}/>
          <Route path="/center/vision" element={<CenterIntro menuIndex={1} />}/>
          <Route path="/center/path" element={<CenterIntro menuIndex={2} />}/>
          <Route path="/service" element={<Service menuIndex={0}/>}/>
          <Route path="/service/nurse01" element={<Service menuIndex={0}/>}/>
          <Route path="/service/nurse02" element={<Service menuIndex={1}/>}/>
          <Route path="/use" element={<Use menuIndex={0}/>}/>
          <Route path="/use/charge" element={<Use menuIndex={0}/>}/>
          <Route path="/use/call" element={<Use menuIndex={1}/>}/>
          <Route path="/announce" element={<Announcement />}/>
          <Route path="/announce/notice" element={<CreateNotice />}/>
          <Route path="/announce/*" element={<NoticeDetail />}/>
          <Route path="/*" element={<Main />}/>
        </Routes>
      </HashRouter>
  )
}


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_PROPERTY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PID,
  storageBucket: process.env.REACT_APP_FIREBASE_STOREAGE,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

const app = initializeApp(firebaseConfig)

export const db = getFirestore(app)


const App = () => {

  const store = createStore(rootReducer);

  // const store 
  // const persistor = persistStore(store);
  // store.subscribe( () => console.log(store.getState()))
  return (
    <>
      <Provider store={store}>
        {/* <PersistGate persistor={persistor} loading={null}> */}
          <AppRouter />
        {/* </PersistGate> */}
      </Provider>
    </>
  )
}

export default App;
import axios from "axios";

const commonApi = axios.create({
    baseURL : process.env.REACT_APP_STAGE_BASE_URL,
    timeout : 10000,
    headers : {
        "_h" : process.env.REACT_APP_DEFAULT_HEADER
    }
})

export {commonApi}
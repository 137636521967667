import { GoogleMap, LoadScriptNext, MarkerF, useJsApiLoader } from "@react-google-maps/api"
import styled from "styled-components";
import { Mobile, PC } from "../script/responsive";
import { StyledP } from "./frame";
import { TitleSperatorWrapper } from "../pages/CenterIntro";

const size = {
    xs: '512px', // for small screen mobile
    sm: '640px', // for mobile screen
    md: '900px', // for tablets
    lg: '1280px', // for laptops
    xl: '1480px', // for desktop / monitors
    xxl: '1920px', // for big screens
}
  
const device = {
    xs: `(max-width: ${size.xs})`,
    sm: `(max-width: ${size.sm})`,
    md: `(max-width: ${size.md})`,
    lg: `(max-width: ${size.lg})`,
    xl: `(max-width: ${size.xl})`,
    xxl: `(max-width: ${size.xxl})`,
}
const Wrapper = styled.div`
    width: 100%;
    height: 100%;

    display:flex;
    justify-content: center;
    align-items: center;

    min-height: 34rem;
    max-height: 34rem;

    @media ${device.sm} {
        min-height: 80%;
        max-height: 80%;
    }
`

const Maps = () => {
    const center = {
        lat: 37.13499,
        lng: 128.2105
    };
    
    const TitleWrapper = styled.div`
    
        width: 80%;
    `

    return (
        <>
        <PC>
            <TitleWrapper>

             <TitleSperatorWrapper>
                <StyledP
                    size={"2.8"}
                    weight={"900"}
                    hexColor={"#6C9EE3"}
                    margin={"1.4rem 0.4rem 0 0"}
                    >|</StyledP>
                <StyledP
                    size={"3.2"}
                    hexColor={"#6C9EE3"}
                    weight={"700"}
                    margin={"1.4rem 0 1.4rem 0"}
                    >오시는길
                </StyledP>
            </TitleSperatorWrapper>
            </TitleWrapper>
            <Wrapper>
                <LoadScriptNext googleMapsApiKey="AIzaSyC04Zu90zed6OfjcDGYxPu6U1wQjzUgTww">
                    <GoogleMap  
                        center={center} zoom={18}  heading={30}
                        tilt={45}
                        id="google-map-test" mapContainerStyle={GoogleMapStyle}
                        mapContainerClassName="map-container"
                        >
                        <MarkerF
                            position={{ lat: 37.13499, lng: 128.2105 }} title="marker title1" 
                            />
                    </GoogleMap>
                </LoadScriptNext>
            </Wrapper>
        </PC>
        <Mobile>
        <TitleWrapper>
            <TitleSperatorWrapper>
            <StyledP
                size={"5.8"}
                weight={"900"}
                hexColor={"#6C9EE3"}
                margin={"1.4rem 0.4rem 0 0"}
                >|</StyledP>
            <StyledP
                size={"6"}
                hexColor={"#6C9EE3"}
                weight={"700"}
                margin={"1.4rem 0 1.4rem 0"}
                >오시는길
            </StyledP>
            </TitleSperatorWrapper>
        </TitleWrapper>
            <div style={{"height": "10%"}}>

            </div>
            <Wrapper>
                <LoadScriptNext googleMapsApiKey="AIzaSyC04Zu90zed6OfjcDGYxPu6U1wQjzUgTww">
                    <GoogleMap  
                        center={center} zoom={18}  heading={30}
                        tilt={45}
                        id="google-map-test" mapContainerStyle={GoogleMapStyle}
                        mapContainerClassName="map-container"
                        >
                        <MarkerF
                            position={{ lat: 37.13499, lng: 128.2105 }} title="marker title1" 
                            />
                    </GoogleMap>
                </LoadScriptNext>
            </Wrapper>
        </Mobile>
        </>
    )
}

const GoogleMapStyle = {
    'height': '100%',
    'width': '80%'
  };
  

export default Maps